import { FAILED_TO_FETCH } from './messages';

export const translateError = (err: unknown) => {
  const DEFAULT_MESSAGE = 'Ocurrió un error.';
  if (err instanceof Error) {
    switch (err.message) {
      case 'Failed to fetch':
        return FAILED_TO_FETCH;
      default:
        return err.message;
    }
  }
  if (typeof err === 'string') {
    return err;
  }
  return DEFAULT_MESSAGE;
}