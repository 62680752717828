import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../common/components/main.layout';
import useAllSchools from '../../common/hooks/use-all-schools';
import { translateError } from '../../utils/handle-error';
import { MultiSelect, Option } from 'react-multi-select-component';
import { addToMySchools } from '../../services/user.service';
import { AlertMessage } from '../../common/components/alert-message'

const VinculationScreen = () => {
  const history = useHistory();
  const [loadAllSchools, loading, error, schools, options] = useAllSchools();
  const init:any[] = [];
  const [selectedOptions, setOptions] = useState(init);
  const [selectedSchools, setSchools] = useState(init);

  function onChange (values: any[]) {
    setOptions(values);
    setSchools(values.map(v => v.value));
  }

  async function addMySchools(){
    try {
      const updated = await addToMySchools(selectedSchools);
      if (updated)  history.push('/start');
    } catch (error) {
      if (error instanceof Error) {
        if(error.message === 'Network error' || 'No hay conexión a internet.'){
          alert('No hay conexión a internet.');
        }
      } else {
        alert(error);
      }
    }
  }

  useEffect(() => {
    loadAllSchools();
    if (error) {
      if (error instanceof Error) {
        if(error.message === 'Network error' || 'No hay conexión a internet.'){
          alert('No hay conexión a internet.');
        }
      }
      else {
        translateError(error);
        alert(error);
      }
    }
  }, [loadAllSchools, error]);

  const filterOptions = (options:Option[], filter:string) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, 'i');
    return options.filter(({ label }) => label.match(re));
  };

  return (
    <>
      <PageHeader screenTitle="Vincular escuela" />
      {!loading && (
        <div className="screen-container">
          <AlertMessage
            title=""
            containerStyle={{ marginBottom: '1rem' }}
            description={
              `Es muy probable que el equipo Nutrento o algún otro docente 
            ya haya registrado alguna de tus escuelas. Puedes realizar una 
            búsqueda y seleccionar las escuelas a las que perteneces o 
            solicitar el registro de una nueva escuela.`
            }
            link={{
              display: 'Añadir una escuela que no esta en la lista',
              route: '/new-school'
            }}
          />
          { options &&
          <MultiSelect
              key={'schools'}
              options={options || []}
              value={selectedOptions || []}
              onChange={(values: Option[]) => onChange(values)}
              labelledBy="Filtro"
              valueRenderer={
                (selected, allOptions) =>
                  selected.length > 0 ?
                    `${selected.length} seleccionadas` :
                    ''
              }
              filterOptions={filterOptions}
              disableSearch={options.length < 5}
              hasSelectAll={false}
              overrideStrings={{
                allItemsAreSelected: 'Todos seleccionados',
                clearSearch: 'Limpiar',
                noOptions: 'No hay opciones',
                search: `Buscar escuela`,
                selectAll: 'Seleccionar todo',
                selectAllFiltered: 'Seleccionar elementos filtrados',
                selectSomeItems: `Buscar escuelas`,
              }}
          />
          }
          {selectedSchools.length > 0 && (
            <input
              type="button"
              value="Vincular escuelas seleccionadas"
              className="primary-button"
              onClick={addMySchools}
            />
          )}
        </div>
      )}
    </>
  );
};

export default VinculationScreen;
