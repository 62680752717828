import React, { FC, InputHTMLAttributes, TextareaHTMLAttributes, useState } from 'react';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fieldName: string;
  inputProps?: any;
};

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  fieldName: string;
  textareaProps?: any;
};

const defaultInputProps: InputHTMLAttributes<HTMLInputElement> = {
  type: 'text',
  maxLength: 256,
};

const TextInput: FC<TextInputProps> = ({
  label,
  fieldName,
  ...inputProps
}) => {
  const [name, setName] = useState('eye');
  const [show, setShow] = useState(false);
  const [type, setType] = useState(inputProps.type);
  function showPassword () {
    if (show) {
      setName('eye')
      setShow(false)
      setType('password')
    } else {
      setName('eye_hide')
      setShow(true)
      setType('text')
    }
  }

  return (
    <div className="form-control" style={{position: inputProps.type === 'password' ? 'relative' : 'inherit'}}>
      <label htmlFor={fieldName} className="input-label">{label}</label>
      <input name={fieldName} className="text-input w-input" {...defaultInputProps} {...inputProps} type={type}/>
      {inputProps.type === 'password' && <img style={{cursor:'pointer'}} src={`/images/${name}.png`} className="eye-icon" alt='eye-icon' onClick={showPassword}/>}
    </div>
  );
}

const TextAreaInput: FC<TextAreaProps> = ({
  label,
  fieldName,
  ...textareaProps
}) => {
  return (
    <div className="form-control">
      <label htmlFor={fieldName} className="input-label">{label}</label>
      <textarea style={{height: '120px', resize:'none'}} name={fieldName} className="text-input w-input" {...textareaProps} />
    </div>
  );
}

export {
  TextInput,
  TextAreaInput
};
