import { CSSProperties } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import './alert-message.css'

interface AlertMessageProps {
  title: string
  description: string
  containerStyle?: CSSProperties
  link?: {
    display: string
    route: LinkProps['to']
  }
  webLink?: {
    display: string
    route: LinkProps['to']
  }
}

const AlertMessage = ({
  title,
  description,
  containerStyle,
  link,
  webLink
}: AlertMessageProps) => (
  <div className="alert-message-container" style={containerStyle}>
    { title && <h6 className="alert-message-heading">{title}</h6> }
    <p className="alert-message-description">{description}</p>
    { link && <Link className="alert-message-link" to={link.route}>{link.display}</Link> }
    { webLink && <a className="alert-message-link" href={`${webLink.route}`} target="_blank" rel="noreferrer">{webLink.display}</a> }
  </div>
)

export { AlertMessage }
