import { configureStore } from '@reduxjs/toolkit';
import schoolsReducer from './slices/schools';
import onlineReducer from './slices/online';

export const store = configureStore({
  reducer: {
    schools: schoolsReducer,
    online: onlineReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;