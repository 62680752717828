import { getData } from '../services/local-storage.service';
import { API_URL, AUTH_USER_TOKEN_KEY } from '../utils/constants';
import { ServerResponse, Answers } from '../utils/models'

async function getEvaluations (id: string): Promise<Answers> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${API_URL}/answer/my-evaluations/${id}`, fetchOpts);
  const response: ServerResponse<Answers> = await fetchResponse.json();
  return response.data;
}

export {
  getEvaluations
}