import { useRef } from 'react'

type CollapsibleProps = {
  setIsOpen: any
  isOpen: boolean
  initialHeight?: number
  children: JSX.Element
}

const Collapsible = ({
   setIsOpen,
   isOpen,
   initialHeight = 0,
   children,
 }: CollapsibleProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={containerRef}
      className="collapsible-container"
      style={{ height: isOpen ? `${containerRef?.current?.scrollHeight}px` : `${initialHeight}px`, marginBottom:'14px' }}
    >
      {isOpen ? null : <div className="collapsible-window"/>}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="collapsible-toggle"
        style={{ height: initialHeight, cursor: 'pointer' }}
      />
      {children}
    </div>
  )
}

export { Collapsible }
