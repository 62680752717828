import { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../common/components/main.layout";
import { AlertMessage } from "../../common/components/alert-message";
import { validateCheck } from "../../utils/validation";
import { updateUser } from "../../services/user.service";
import { SERVER_URL } from "../../utils/constants";

const TermsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const history = useHistory();

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>): void {
    setAcceptTerms(e.target.checked);
  }

  async function handleContinue(): Promise<void> {
    try {
      setLoading(true);
      validateCheck(acceptTerms, "el consentimiento");
      setErrors(null);
      const update = await updateUser({}, acceptTerms);

      if (update) {
        history.push('/start');
      } else {
        setErrors('No se pudo actualizar tu perfil, revisa tu conexión');
        setLoading(false);
      }
    } catch (err: any) {
      setErrors(err?.message);
      setLoading(false);
    }
  }

  return (
    <>
      <PageHeader screenTitle="Consentimiento informado" />
      <div className="screen-container">
        <AlertMessage
          title=""
          containerStyle={{ marginBottom: "1rem" }}
          description={`Al participar en NUTRENTO usted está colaborando en el Proyecto de Investigación con fines académicos. Como proyecto garantizamos que su participación será anónima. Además, toda la información que se obtenga es confidencial. Su participación es totalmente voluntaria. Al participar usted puede obtener beneficios en materia de información y capacitación.`}
          webLink={{
            display: 'Para conocer más dar click aqui',
            route: `${SERVER_URL}/consentimiento-informado`
          }}
        />
        <div style={{display:"flex", flexDirection:"column", margin: "12px"}}>
        <div className="privacy-box">
          <input type="checkbox"
           id="privacy" 
           name="privacy" 
           className='input-privacy' 
           onChange={handleCheck}
          />
          <label htmlFor="privacy" className='label-privacy'>He leído y acepto</label>
        </div>
        <input
          disabled={loading}
          type="button"
          value={loading ? 'Procesando...' : 'Continuar'}
          className="form-submit-button w-button"
          onClick={() => handleContinue()}
        />
         <p style={{margin: '8px 0', color: 'red'}}>
          {errors}
        </p>
        </div>
      </div>
    </>
  );
};

export default TermsScreen;
