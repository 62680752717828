import { useCallback, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/schools';
import { getEvaluations } from '../../services/evaluations.service';
import { Answers } from '../../utils/models';

type UseEvaluationsHook = () => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  Answers
];

const useEvaluations: UseEvaluationsHook = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [evaluations, setEvaluations] = useState<Answers>();
  const selectedSchool = useAppSelector(selectSlice);

  const loadEvaluations = useCallback(
    async () => {
      try {
        setError(undefined);
        setEvaluations(undefined);
        setLoading(true);
        if (selectedSchool.selected?._id){
          const evaluationsList = await getEvaluations(selectedSchool.selected._id);
          if (evaluationsList) {
            setEvaluations(evaluationsList);
          }
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false);
      }
    },
    [
      selectedSchool.selected,
    ]
  );

  return [
    loadEvaluations,
    loading,
    error,
    evaluations,
  ]

}

export default useEvaluations;