export const shedules = [
  {
    distinctId: "matutino",
    display: {
      es: "Matutino",
      default: "Morning",
    },
  },
  {
    distinctId: "vespertino",
    display: {
      es: "Vespertino",
      default: "Evening",
    },
  },
  {
    distinctId: "tiempo_completo",
    display: {
      es: "Tiempo completo",
      default: "Full time",
    },
  },
  {
    distinctId: "otro",
    display: {
      es: "Otro",
      default: "Other",
    },
  },
];

export const scopes = [
  {
    distinctId: "rural",
    display: {
      es: "Rural",
      default: "Rural",
    },
  },
  {
    distinctId: "urbano",
    display: {
      es: "Urbano",
      default: "Urban",
    },
  },
];

export const categories = [
  {
    distinctId: "Docentes en aulas y/o escuelas",
    display: {
      es: "Docentes en aulas y/o escuelas",
      default: "Docentes en aulas y/o escuelas",
    },
  },
  {
    distinctId: "Proyectos de investigaciones",
    display: {
      es: "Proyectos de investigaciones",
      default: "Proyectos de investigaciones",
    },
  },
  {
    distinctId: "Instituciones de gobierno y civiles",
    display: {
      es: "Instituciones de gobierno y civiles",
      default: "Instituciones de gobierno y civiles",
    },
  },
];

export const countries = [
  {
    name: "Chile",
    distinctId: "Chile",
    regions: [
      {
        name: "Arica y Parinacota",
        distinctId: "Arica-y-Parinacota",
        subregions: [
          { name: "Arica", distinctId: "arica" },
          { name: "Camarones", distinctId: "camarones" },
          { name: "General Lagos", distinctId: "general-lagos" },
          { name: "Putre", distinctId: "putre" },
        ],
      },
      {
        name: "Tarapacá",
        distinctId: "Tarapaca",
        subregions: [
          { name: "Alto Hospicio", distinctId: "alto-hospicio" },
          { name: "Camiña", distinctId: "camina" },
          { name: "Colchane", distinctId: "colchane" },
          { name: "Huara", distinctId: "huara" },
          { name: "Iquique", distinctId: "iquique" },
          { name: "Pica", distinctId: "pica" },
          { name: "Pozo Almonte", distinctId: "pozo-almonte" },
        ],
      },
      {
        name: "Antofagasta",
        distinctId: "Antofagasta",
        subregions: [
          { name: "Antofagasta", distinctId: "antofagasta" },
          { name: "Calama", distinctId: "calama" },
          { name: "María Elena", distinctId: "maria-elena" },
          { name: "Mejillones", distinctId: "mejillones" },
          { name: "Ollagüe", distinctId: "ollague" },
          { name: "San Pedro de Atacama", distinctId: "san-pedro-de-atacama" },
          { name: "Sierra Gorda", distinctId: "sierra-gorda" },
          { name: "Taltal", distinctId: "taltal" },
          { name: "Tocopilla", distinctId: "tocopilla" },
        ],
      },
      {
        name: "Atacama",
        distinctId: "Atacama",
        subregions: [
          { name: "Alto del Carmen", distinctId: "alto-del-carmen" },
          { name: "Caldera", distinctId: "caldera" },
          { name: "Chañaral", distinctId: "chanaral" },
          { name: "Copiapó", distinctId: "copiapo" },
          { name: "Diego de Almagro", distinctId: "diego-de-almagro" },
          { name: "Freirina", distinctId: "freirina" },
          { name: "Huasco", distinctId: "huasco" },
          { name: "Tierra Amarilla", distinctId: "tierra-amarilla" },
          { name: "Vallenar", distinctId: "vallenar" },
        ],
      },
      {
        name: "Coquimbo",
        distinctId: "Coquimbo",
        subregions: [
          { name: "Andacollo", distinctId: "andacollo" },
          { name: "Canela", distinctId: "canela" },
          { name: "Combarbalá", distinctId: "combarbala" },
          { name: "Coquimbo", distinctId: "coquimbo" },
          { name: "Illapel", distinctId: "illapel" },
          { name: "La Higuera", distinctId: "la-higuera" },
          { name: "La Serena", distinctId: "la-serena" },
          { name: "Los Vilos", distinctId: "los-vilos" },
          { name: "Monte Patria", distinctId: "monte-patria" },
          { name: "Ovalle", distinctId: "ovalle" },
          { name: "Paiguano", distinctId: "paiguano" },
          { name: "Punitaqui", distinctId: "punitaqui" },
          { name: "Río Hurtado", distinctId: "rio-hurtado" },
          { name: "Salamanca", distinctId: "salamanca" },
          { name: "Vicuña", distinctId: "vicuna" },
        ],
      },
      {
        name: "Valparaíso",
        distinctId: "Valparaiso",
        subregions: [
          { name: "Algarrobo", distinctId: "algarrobo" },
          { name: "Cabildo", distinctId: "cabildo" },
          { name: "Calera", distinctId: "calera" },
          { name: "Calle Larga", distinctId: "calle-larga" },
          { name: "Cartagena", distinctId: "cartagena" },
          { name: "Casablanca", distinctId: "casablanca" },
          { name: "Catemu", distinctId: "catemu" },
          { name: "Concón", distinctId: "concon" },
          { name: "El Quisco", distinctId: "el-quisco" },
          { name: "El Tabo", distinctId: "el-tabo" },
          { name: "Hijuelas", distinctId: "hijuelas" },
          { name: "Isla de Pascua", distinctId: "isla-de-pascua" },
          { name: "Juan Fernández", distinctId: "juan-fernandez" },
          { name: "La Cruz", distinctId: "la-cruz" },
          { name: "La Ligua", distinctId: "la-ligua" },
          { name: "Limache", distinctId: "limache" },
          { name: "Llaillay", distinctId: "llaillay" },
          { name: "Los Andes", distinctId: "los-andes" },
          { name: "Nogales", distinctId: "nogales" },
          { name: "Olmué", distinctId: "olmue" },
          { name: "Panquehue", distinctId: "panquehue" },
          { name: "Papudo", distinctId: "papudo" },
          { name: "Petorca", distinctId: "petorca" },
          { name: "Puchuncaví", distinctId: "puchuncavi" },
          { name: "Putaendo", distinctId: "putaendo" },
          { name: "Quillota", distinctId: "quillota" },
          { name: "Quilpué", distinctId: "quilpue" },
          { name: "Quintero", distinctId: "quintero" },
          { name: "Rinconada", distinctId: "rinconada" },
          { name: "San Antonio", distinctId: "san-antonio" },
          { name: "San Esteban", distinctId: "san-esteban" },
          { name: "San Felipe", distinctId: "san-felipe" },
          { name: "Santa María", distinctId: "santa-maria" },
          { name: "Santo Domingo", distinctId: "santo-domingo" },
          { name: "Valparaíso", distinctId: "valparaiso" },
          { name: "Villa Alemana", distinctId: "villa-alemana" },
          { name: "Viña del Mar", distinctId: "vina-del-mar" },
          { name: "Zapallar", distinctId: "zapallar" },
        ],
      },
      {
        name: "Metropolitana de Santiago",
        distinctId: "Metropolitana-de-Santiago",
        subregions: [
          { name: "Alhué", distinctId: "alhue" },
          { name: "Buin", distinctId: "buin" },
          { name: "Calera de Tango", distinctId: "calera-de-tango" },
          { name: "Cerrillos", distinctId: "cerrillos" },
          { name: "Cerro Navia", distinctId: "cerro-navia" },
          { name: "Colina", distinctId: "colina" },
          { name: "Conchalí", distinctId: "conchali" },
          { name: "Curacaví", distinctId: "curacavi" },
          { name: "El Bosque", distinctId: "el-bosque" },
          { name: "El Monte", distinctId: "el-monte" },
          { name: "Estación Central", distinctId: "estacion-central" },
          { name: "Huechuraba", distinctId: "huechuraba" },
          { name: "Independencia", distinctId: "independencia" },
          { name: "Isla de Maipo", distinctId: "isla-de-maipo" },
          { name: "La Cisterna", distinctId: "la-cisterna" },
          { name: "La Florida", distinctId: "la-florida" },
          { name: "La Granja", distinctId: "la-granja" },
          { name: "La Pintana", distinctId: "la-pintana" },
          { name: "La Reina", distinctId: "la-reina" },
          { name: "Lampa", distinctId: "lampa" },
          { name: "Las Condes", distinctId: "las-condes" },
          { name: "Lo Barnechea", distinctId: "lo-barnechea" },
          { name: "Lo Espejo", distinctId: "lo-espejo" },
          { name: "Lo Prado", distinctId: "lo-prado" },
          { name: "Macul", distinctId: "macul" },
          { name: "Maipú", distinctId: "maipu" },
          { name: "María Pinto", distinctId: "maria-pinto" },
          { name: "Melipilla", distinctId: "melipilla" },
          { name: "Ñuñoa", distinctId: "nunoa" },
          { name: "Padre Hurtado", distinctId: "padre-hurtado" },
          { name: "Paine", distinctId: "paine" },
          { name: "Pedro Aguirre Cerda", distinctId: "pedro-aguirre-cerda" },
          { name: "Peñaflor", distinctId: "penaflor" },
          { name: "Peñalolén", distinctId: "penalolen" },
          { name: "Pirque", distinctId: "pirque" },
          { name: "Providencia", distinctId: "providencia" },
          { name: "Pudahuel", distinctId: "pudahuel" },
          { name: "Puente Alto", distinctId: "puente-alto" },
          { name: "Quilicura", distinctId: "quilicura" },
          { name: "Quinta Normal", distinctId: "quinta-normal" },
          { name: "Recoleta", distinctId: "recoleta" },
          { name: "Renca", distinctId: "renca" },
          { name: "San Bernardo", distinctId: "san-bernardo" },
          { name: "San Joaquín", distinctId: "san-joaquin" },
          { name: "San José de Maipo", distinctId: "san-jose-de-maipo" },
          { name: "San Miguel", distinctId: "san-miguel" },
          { name: "San Pedro", distinctId: "san-pedro" },
          { name: "San Ramón", distinctId: "san-ramon" },
          { name: "Santiago", distinctId: "santiago" },
          { name: "Talagante", distinctId: "talagante" },
          { name: "Tiltil", distinctId: "tiltil" },
          { name: "Vitacura", distinctId: "vitacura" },
        ],
      },
      {
        name: "Libertador Gral. Bernardo O’Higgins",
        distinctId: "Libertador-Gral.-Bernardo-OHiggins",
        subregions: [
          { name: "Chimbarongo", distinctId: "chimbarongo" },
          { name: "Chépica", distinctId: "chepica" },
          { name: "Codegua", distinctId: "codegua" },
          { name: "Coinco", distinctId: "coinco" },
          { name: "Coltauco", distinctId: "coltauco" },
          { name: "Doñihue", distinctId: "donihue" },
          { name: "Graneros", distinctId: "graneros" },
          { name: "La Estrella", distinctId: "la-estrella" },
          { name: "Las Cabras", distinctId: "las-cabras" },
          { name: "Litueche", distinctId: "litueche" },
          { name: "Lolol", distinctId: "lolol" },
          { name: "Machalí", distinctId: "machali" },
          { name: "Malloa", distinctId: "malloa" },
          { name: "Marchihue", distinctId: "marchihue" },
          { name: "Nancagua", distinctId: "nancagua" },
          { name: "Navidad", distinctId: "navidad" },
          { name: "Olivar", distinctId: "olivar" },
          { name: "Mostazal", distinctId: "mostazal" },
          { name: "Palmilla", distinctId: "palmilla" },
          { name: "Paredones", distinctId: "paredones" },
          { name: "Peralillo", distinctId: "peralillo" },
          { name: "Peumo", distinctId: "peumo" },
          { name: "Pichidegua", distinctId: "pichidegua" },
          { name: "Pichilemu", distinctId: "pichilemu" },
          { name: "Placilla", distinctId: "placilla" },
          { name: "Pumanque", distinctId: "pumanque" },
          { name: "Quinta de Tilcoco", distinctId: "quinta-de-tilcoco" },
          { name: "Rancagua", distinctId: "rancagua" },
          { name: "Rengo", distinctId: "rengo" },
          { name: "Requínoa", distinctId: "requinoa" },
          { name: "San Fernando", distinctId: "san-fernando" },
          { name: "San Vicente", distinctId: "san-vicente" },
          { name: "Santa Cruz", distinctId: "santa-cruz" },
        ],
      },
      {
        name: "Maule",
        distinctId: "Maule",
        subregions: [
          { name: "Cauquenes", distinctId: "cauquenes" },
          { name: "Chanco", distinctId: "chanco" },
          { name: "Colbún", distinctId: "colbun" },
          { name: "Constitución", distinctId: "constitucion" },
          { name: "Curepto", distinctId: "curepto" },
          { name: "Curicó", distinctId: "curico" },
          { name: "Empedrado", distinctId: "empedrado" },
          { name: "Hualañé", distinctId: "hualane" },
          { name: "Licantén", distinctId: "licanten" },
          { name: "Linares", distinctId: "linares" },
          { name: "Longaví", distinctId: "longavi" },
          { name: "Maule", distinctId: "maule" },
          { name: "Molina", distinctId: "molina" },
          { name: "Parral", distinctId: "parral" },
          { name: "Pelarco", distinctId: "pelarco" },
          { name: "Pelluhue", distinctId: "pelluhue" },
          { name: "Pencahue", distinctId: "pencahue" },
          { name: "Rauco", distinctId: "rauco" },
          { name: "Retiro", distinctId: "retiro" },
          { name: "Romeral", distinctId: "romeral" },
          { name: "Río Claro", distinctId: "rio-claro" },
          { name: "Sagrada Familia", distinctId: "sagrada-familia" },
          { name: "San Clemente", distinctId: "san-clemente" },
          {
            name: "San Javier de Loncomilla",
            distinctId: "san-javier-de-loncomilla",
          },
          { name: "San Rafael", distinctId: "san-rafael" },
          { name: "Talca", distinctId: "talca" },
          { name: "Teno", distinctId: "teno" },
          { name: "Vichuquén", distinctId: "vichuquen" },
          { name: "Villa Alegre", distinctId: "villa-alegre" },
          { name: "Yerbas Buenas", distinctId: "yerbas-buenas" },
        ],
      },
      {
        name: "Ñuble",
        distinctId: "Ñuble",
        subregions: [
          { name: "Bulnes", distinctId: "bulnes" },
          { name: "Chillán Viejo", distinctId: "chillan-viejo" },
          { name: "Chillán", distinctId: "chillan" },
          { name: "Cobquecura", distinctId: "cobquecura" },
          { name: "Coelemu", distinctId: "coelemu" },
          { name: "Coihueco", distinctId: "coihueco" },
          { name: "El Carmen", distinctId: "el-carmen" },
          { name: "Ninhue", distinctId: "ninhue" },
          { name: "Ñiquén", distinctId: "niquen" },
          { name: "Pemuco", distinctId: "pemuco" },
          { name: "Pinto", distinctId: "pinto" },
          { name: "Portezuelo", distinctId: "portezuelo" },
          { name: "Quillón", distinctId: "quillon" },
          { name: "Quirihue", distinctId: "quirihue" },
          { name: "Ránquil", distinctId: "ranquil" },
          { name: "San Carlos", distinctId: "san-carlos" },
          { name: "San Fabián", distinctId: "san-fabian" },
          { name: "San Ignacio", distinctId: "san-ignacio" },
          { name: "San Nicolás", distinctId: "san-nicolas" },
          { name: "Treguaco", distinctId: "treguaco" },
          { name: "Yungay", distinctId: "yungay" },
        ],
      },
      {
        name: "Biobío",
        distinctId: "Biobio",
        subregions: [
          { name: "Alto Biobío", distinctId: "alto-biobio" },
          { name: "Antuco", distinctId: "antuco" },
          { name: "Arauco", distinctId: "arauco" },
          { name: "Cabrero", distinctId: "cabrero" },
          { name: "Cañete", distinctId: "canete" },
          { name: "Chiguayante", distinctId: "chiguayante" },
          { name: "Concepción", distinctId: "concepcion" },
          { name: "Contulmo", distinctId: "contulmo" },
          { name: "Coronel", distinctId: "coronel" },
          { name: "Curanilahue", distinctId: "curanilahue" },
          { name: "Florida", distinctId: "florida" },
          { name: "Hualpén", distinctId: "hualpen" },
          { name: "Hualqui", distinctId: "hualqui" },
          { name: "Laja", distinctId: "laja" },
          { name: "Lebu", distinctId: "lebu" },
          { name: "Los Álamos", distinctId: "los-alamos" },
          { name: "Los Ángeles", distinctId: "los-angeles" },
          { name: "Lota", distinctId: "lota" },
          { name: "Mulchén", distinctId: "mulchen" },
          { name: "Nacimiento", distinctId: "nacimiento" },
          { name: "Negrete", distinctId: "negrete" },
          { name: "Penco", distinctId: "penco" },
          { name: "Quilaco", distinctId: "quilaco" },
          { name: "Quilleco", distinctId: "quilleco" },
          { name: "San Pedro de la Paz", distinctId: "san-pedro-de-la-paz" },
          { name: "San Rosendo", distinctId: "san-rosendo" },
          { name: "Santa Bárbara", distinctId: "santa-barbara" },
          { name: "Santa Juana", distinctId: "santa-juana" },
          { name: "Talcahuano", distinctId: "talcahuano" },
          { name: "Tirúa", distinctId: "tirua" },
          { name: "Tomé", distinctId: "tome" },
          { name: "Tucapel", distinctId: "tucapel" },
          { name: "Yumbel", distinctId: "yumbel" },
        ],
      },
      {
        name: "Araucanía",
        distinctId: "Araucania",
        subregions: [
          { name: "Angol", distinctId: "angol" },
          { name: "Carahue", distinctId: "carahue" },
          { name: "Cholchol", distinctId: "cholchol" },
          { name: "Collipulli", distinctId: "collipulli" },
          { name: "Cunco", distinctId: "cunco" },
          { name: "Curacautín", distinctId: "curacautin" },
          { name: "Curarrehue", distinctId: "curarrehue" },
          { name: "Ercilla", distinctId: "ercilla" },
          { name: "Freire", distinctId: "freire" },
          { name: "Galvarino", distinctId: "galvarino" },
          { name: "Gorbea", distinctId: "gorbea" },
          { name: "Lautaro", distinctId: "lautaro" },
          { name: "Loncoche", distinctId: "loncoche" },
          { name: "Lonquimay", distinctId: "lonquimay" },
          { name: "Los Sauces", distinctId: "los-sauces" },
          { name: "Lumaco", distinctId: "lumaco" },
          { name: "Melipeuco", distinctId: "melipeuco" },
          { name: "Nueva Imperial", distinctId: "nueva-imperial" },
          { name: "Padre las Casas", distinctId: "padre-las-casas" },
          { name: "Perquenco", distinctId: "perquenco" },
          { name: "Pitrufquén", distinctId: "pitrufquen" },
          { name: "Pucón", distinctId: "pucon" },
          { name: "Purén", distinctId: "puren" },
          { name: "Renaico", distinctId: "renaico" },
          { name: "Saavedra", distinctId: "saavedra" },
          { name: "Temuco", distinctId: "temuco" },
          { name: "Teodoro Schmidt", distinctId: "teodoro-schmidt" },
          { name: "Toltén", distinctId: "tolten" },
          { name: "Traiguén", distinctId: "traiguen" },
          { name: "Victoria", distinctId: "victoria" },
          { name: "Vilcún", distinctId: "vilcun" },
          { name: "Villarrica", distinctId: "villarrica" },
        ],
      },
      {
        name: "Los Ríos",
        distinctId: "Los-Rios",
        subregions: [
          { name: "Corral", distinctId: "corral" },
          { name: "Futrono", distinctId: "futrono" },
          { name: "La Unión", distinctId: "la-union" },
          { name: "Lago Ranco", distinctId: "lago-ranco" },
          { name: "Lanco", distinctId: "lanco" },
          { name: "Los Lagos", distinctId: "los-lagos" },
          { name: "Mariquina", distinctId: "mariquina" },
          { name: "Máfil", distinctId: "mafil" },
          { name: "Paillaco", distinctId: "paillaco" },
          { name: "Panguipulli", distinctId: "panguipulli" },
          { name: "Río Bueno", distinctId: "rio-bueno" },
          { name: "Valdivia", distinctId: "valdivia" },
        ],
      },
      {
        name: "Los Lagos",
        distinctId: "Los-Lagos",
        subregions: [
          { name: "Ancud", distinctId: "ancud" },
          { name: "Calbuco", distinctId: "calbuco" },
          { name: "Castro", distinctId: "castro" },
          { name: "Chaitén", distinctId: "chaiten" },
          { name: "Chonchi", distinctId: "chonchi" },
          { name: "Cochamó", distinctId: "cochamo" },
          { name: "Curaco de Vélez", distinctId: "curaco-de-velez" },
          { name: "Dalcahue", distinctId: "dalcahue" },
          { name: "Fresia", distinctId: "fresia" },
          { name: "Frutillar", distinctId: "frutillar" },
          { name: "Futaleufú", distinctId: "futaleufu" },
          { name: "Hualaihué", distinctId: "hualaihue" },
          { name: "Llanquihue", distinctId: "llanquihue" },
          { name: "Los Muermos", distinctId: "los-muermos" },
          { name: "Maullín", distinctId: "maullin" },
          { name: "Osorno", distinctId: "osorno" },
          { name: "Palena", distinctId: "palena" },
          { name: "Puerto Montt", distinctId: "puerto-montt" },
          { name: "Puerto Octay", distinctId: "puerto-octay" },
          { name: "Puerto Varas", distinctId: "puerto-varas" },
          { name: "Puqueldón", distinctId: "puqueldon" },
          { name: "Purranque", distinctId: "purranque" },
          { name: "Puyehue", distinctId: "puyehue" },
          { name: "Queilén", distinctId: "queilen" },
          { name: "Quellón", distinctId: "quellon" },
          { name: "Quemchi", distinctId: "quemchi" },
          { name: "Quinchao", distinctId: "quinchao" },
          { name: "Río Negro", distinctId: "rio-negro" },
          { name: "San Juan de la Costa", distinctId: "san-juan-de-la-costa" },
          { name: "San Pablo", distinctId: "san-pablo" },
        ],
      },
      {
        name: "Aisén del Gral. Carlos Ibáñez del Campo",
        distinctId: "Aisen-del-Gral.-Carlos-Ibañez-del-Campo",
        subregions: [
          { name: "Aysén", distinctId: "aysen" },
          { name: "Chile Chico", distinctId: "chile-chico" },
          { name: "Cisnes", distinctId: "cisnes" },
          { name: "Cochrane", distinctId: "cochrane" },
          { name: "Coyhaique", distinctId: "coyhaique" },
          { name: "Guaitecas", distinctId: "guaitecas" },
          { name: "Lago Verde", distinctId: "lago-verde" },
          { name: "O’Higgins", distinctId: "ohiggins" },
          { name: "Río Ibáñez", distinctId: "rio-ibanez" },
          { name: "Tortel", distinctId: "tortel" },
        ],
      },
      {
        name: "Magallanes y de la Antártica Chilena",
        distinctId: "Magallanes-y-de-la-Antartica-Chilena",
        subregions: [
          { name: "Antártica", distinctId: "antartica" },
          { name: "Cabo de Hornos", distinctId: "cabo-de-hornos" },
          { name: "Laguna Blanca", distinctId: "laguna-blanca" },
          { name: "Natales", distinctId: "natales" },
          { name: "Porvenir", distinctId: "porvenir" },
          { name: "Primavera", distinctId: "primavera" },
          { name: "Punta Arenas", distinctId: "punta-arenas" },
          { name: "Río Verde", distinctId: "rio-verde" },
          { name: "San Gregorio", distinctId: "san-gregorio" },
          { name: "Timaukel", distinctId: "timaukel" },
          { name: "Torres del Paine", distinctId: "torres-del-paine" },
        ],
      },
    ],
  },
  {
    name: "México",
    distinctId: "Mexico",
    regions: [
      {
        name: "Aguascalientes",
        distinctId: "Aguascalientes",
        subregions: [
          { name: "Aguascalientes", distinctId: "Aguascalientes" },
          { name: "Asientos", distinctId: "Asientos" },
          { name: "Calvillo", distinctId: "Calvillo" },
          { name: "Cosio", distinctId: "Cosio" },
          { name: "El Llano", distinctId: "El-Llano" },
          { name: "Jesus Maria", distinctId: "Jesus-Maria" },
          { name: "Pabellon de Arteaga", distinctId: "Pabellon-de-Arteaga" },
          { name: "Rincon de Romos", distinctId: "Rincon-de-Romos" },
          {
            name: "San Francisco de los Romo",
            distinctId: "San-Francisco-de-los-Romo",
          },
          { name: "San Jose de Gracia", distinctId: "San-Jose-de-Gracia" },
          { name: "Tepezala", distinctId: "Tepezala" },
        ],
      },
      {
        name: "Baja California",
        distinctId: "Baja-California",
        subregions: [
          { name: "Ensenada", distinctId: "Ensenada" },
          { name: "Mexicali", distinctId: "Mexicali" },
          { name: "Playas de Rosarito", distinctId: "Playas-de-Rosarito" },
          { name: "Tecate", distinctId: "Tecate" },
          { name: "Tijuana", distinctId: "Tijuana" },
        ],
      },
      {
        name: "Baja California Sur",
        distinctId: "Baja-California-Sur",
        subregions: [
          { name: "Comondu", distinctId: "Comondu" },
          { name: "La Paz", distinctId: "La-Paz" },
          { name: "Loreto", distinctId: "Loreto" },
          { name: "Los Cabos", distinctId: "Los-Cabos" },
          { name: "Mulege", distinctId: "Mulege" },
        ],
      },
      {
        name: "Campeche",
        distinctId: "Campeche",
        subregions: [
          { name: "Calakmul", distinctId: "Calakmul" },
          { name: "Calkini", distinctId: "Calkini" },
          { name: "Campeche", distinctId: "Campeche" },
          { name: "Candelaria", distinctId: "Candelaria" },
          { name: "Carmen", distinctId: "Carmen" },
          { name: "Champoton", distinctId: "Champoton" },
          { name: "Escarcega", distinctId: "Escarcega" },
          { name: "Hecelchakan", distinctId: "Hecelchakan" },
          { name: "Hopelchen", distinctId: "Hopelchen" },
          { name: "Palizada", distinctId: "Palizada" },
          { name: "Tenabo", distinctId: "Tenabo" },
        ],
      },
      {
        name: "Coahuila",
        distinctId: "Coahuila",
        subregions: [
          { name: "Abasolo", distinctId: "Abasolo" },
          { name: "Acuna", distinctId: "Acuna" },
          { name: "Allende", distinctId: "Allende" },
          { name: "Arteaga", distinctId: "Arteaga" },
          { name: "Candela", distinctId: "Candela" },
          { name: "Castanos", distinctId: "Castanos" },
          { name: "Cuatro Cienegas", distinctId: "Cuatro-Cienegas" },
          { name: "Francisco I. Madero", distinctId: "Francisco-I.-Madero" },
          { name: "Frontera", distinctId: "Frontera" },
          { name: "General Cepeda", distinctId: "General Cepeda" },
          { name: "Guerrero", distinctId: "Guerrero" },
          { name: "Hidalgo", distinctId: "Hidalgo" },
          { name: "Jimenez", distinctId: "Jimenez" },
          { name: "Juarez", distinctId: "Juarez" },
          { name: "Lamadrid", distinctId: "Lamadrid" },
          { name: "Matamoros", distinctId: "Matamoros" },
          { name: "Monclova", distinctId: "Monclova" },
          { name: "Morelos", distinctId: "Morelos" },
          { name: "Muzquiz", distinctId: "Muzquiz" },
          { name: "Nadadores", distinctId: "Nadadores" },
          { name: "Nava", distinctId: "Nava" },
          { name: "Ocampo", distinctId: "Ocampo" },
          { name: "Parras", distinctId: "Parras" },
          { name: "Piedras Negras", distinctId: "Piedras-Negras" },
          { name: "Progreso", distinctId: "Progreso" },
          { name: "Ramos Arizpe", distinctId: "Ramos-Arizpe" },
          { name: "Sabinas", distinctId: "Sabinas" },
          { name: "Sacramento", distinctId: "Sacramento" },
          { name: "Saltillo", distinctId: "Saltillo" },
          { name: "San Buenaventura", distinctId: "San-Buenaventura" },
          { name: "San Juan de Sabinas", distinctId: "San-Juan-de-Sabinas" },
          { name: "San Pedro", distinctId: "San-Pedro" },
          { name: "Sierra Mojada", distinctId: "Sierra-Mojada" },
          { name: "Torreon", distinctId: "Torreon" },
          { name: "Viesca", distinctId: "Viesca" },
          { name: "Villa Union", distinctId: "Villa-Union" },
          { name: "Zaragoza", distinctId: "Zaragoza" },
        ],
      },
      {
        name: "Colima",
        distinctId: "Colima",
        subregions: [
          { name: "Armeria", distinctId: "Armeria" },
          { name: "Colima", distinctId: "Colima" },
          { name: "Comala", distinctId: "Comala" },
          { name: "Coquimatlan", distinctId: "Coquimatlan" },
          { name: "Cuauhtemoc", distinctId: "Cuauhtemoc" },
          { name: "Ixtlahuacan", distinctId: "Ixtlahuacan" },
          { name: "Manzanillo", distinctId: "Manzanillo" },
          { name: "Minatitlan", distinctId: "Minatitlan" },
          { name: "Tecoman", distinctId: "Tecoman" },
          { name: "Villa de Alvarez", distinctId: "Villa-de-Alvarez" },
        ],
      },
      {
        name: "Chiapas",
        distinctId: "Chiapas",
        subregions: [
          { name: "Acacoyagua", distinctId: "Acacoyagua" },
          { name: "Acala", distinctId: "Acala" },
          { name: "Acapetahua", distinctId: "Acapetahua" },
          { name: "Aldama", distinctId: "Aldama" },
          { name: "Altamirano", distinctId: "Altamirano" },
          {
            name: "Amatenango de la Frontera",
            distinctId: "Amatenango-de-la-Frontera",
          },
          { name: "Amatenango del Valle", distinctId: "Amatenango-del-Valle" },
          { name: "Amatan", distinctId: "Amatan" },
          { name: "Angel Albino Corzo", distinctId: "Angel-Albino-Corzo" },
          { name: "Arriaga", distinctId: "Arriaga" },
          { name: "Bejucal de Ocampo", distinctId: "Bejucal-de-Ocampo" },
          { name: "Bella Vista", distinctId: "Bella-Vista" },
          {
            name: "Benemerito de las Americas",
            distinctId: "Benemerito-de-las-Americas",
          },
          { name: "Berriozabal", distinctId: "Berriozabal" },
          { name: "Bochil", distinctId: "Bochil" },
          { name: "Cacahoatan", distinctId: "Cacahoatan" },
          {
            name: "Capitan Luis Angel Vidal",
            distinctId: "Capitan-Luis-Angel-Vidal",
          },
          { name: "Catazaja", distinctId: "Catazaja" },
          { name: "Chalchihuitan", distinctId: "Chalchihuitan" },
          { name: "Chamula", distinctId: "Chamula" },
          { name: "Chanal", distinctId: "Chanal" },
          { name: "Chapultenango", distinctId: "Chapultenango" },
          { name: "Chenalho", distinctId: "Chenalho" },
          { name: "Chiapa de Corzo", distinctId: "Chiapa de Corzo" },
          { name: "Chiapilla", distinctId: "Chiapilla" },
          { name: "Chicoasen", distinctId: "Chicoasen" },
          { name: "Chicomuselo", distinctId: "Chicomuselo" },
          { name: "Chilon", distinctId: "Chilon" },
          { name: "Cintalapa", distinctId: "Cintalapa" },
          { name: "Coapilla", distinctId: "Coapilla" },
          { name: "Comitan de Dominguez", distinctId: "Comitan-de-Dominguez" },
          { name: "Copainala", distinctId: "Copainala" },
          { name: "El Bosque", distinctId: "El-Bosque" },
          { name: "El Parral", distinctId: "El-Parral" },
          { name: "El Porvenir", distinctId: "El-Porvenir" },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Escuintla", distinctId: "Escuintla" },
          { name: "Francisco Leon", distinctId: "Francisco-Leon" },
          { name: "Frontera Comalapa", distinctId: "Frontera-Comalapa" },
          { name: "Frontera Hidalgo", distinctId: "Frontera-Hidalgo" },
          { name: "Huehuetan", distinctId: "Huehuetan" },
          { name: "Huitiupan", distinctId: "Huitiupan" },
          { name: "Huixtla", distinctId: "Huixtla" },
          { name: "Huixtan", distinctId: "Huixtan" },
          { name: "Ixhuatan", distinctId: "Ixhuatan" },
          { name: "Ixtacomitan", distinctId: "Ixtacomitan" },
          { name: "Ixtapa", distinctId: "Ixtapa" },
          { name: "Ixtapangajoya", distinctId: "Ixtapangajoya" },
          { name: "Jiquipilas", distinctId: "Jiquipilas" },
          { name: "Jitotol", distinctId: "Jitotol" },
          { name: "Juarez", distinctId: "Juarez" },
          { name: "La Concordia", distinctId: "La-Concordia" },
          { name: "La Grandeza", distinctId: "La-Grandeza" },
          { name: "La Independencia", distinctId: "La-Independencia" },
          { name: "La Libertad", distinctId: "La-Libertad" },
          { name: "La Trinitaria", distinctId: "La-Trinitaria" },
          { name: "Larrainzar", distinctId: "Larrainzar" },
          { name: "Las Margaritas", distinctId: "Las-Margaritas" },
          { name: "Las Rosas", distinctId: "Las-Rosas" },
          { name: "Mapastepec", distinctId: "Mapastepec" },
          { name: "Maravilla Tenejapa", distinctId: "Maravilla-Tenejapa" },
          { name: "Marques de Comillas", distinctId: "Marques-de-Comillas" },
          { name: "Mazapa de Madero", distinctId: "Mazapa-de-Madero" },
          { name: "Mazatan", distinctId: "Mazatan" },
          { name: "Metapa", distinctId: "Metapa" },
          { name: "Mezcalapa", distinctId: "Mezcalapa" },
          { name: "Mitontic", distinctId: "Mitontic" },
          {
            name: "Montecristo de Guerrero",
            distinctId: "Montecristo-de-Guerrero",
          },
          { name: "Motozintla", distinctId: "Motozintla" },
          { name: "Nicolas Ruiz", distinctId: "Nicolas-Ruiz" },
          { name: "Ocosingo", distinctId: "Ocosingo" },
          { name: "Ocotepec", distinctId: "Ocotepec" },
          {
            name: "Ocozocoautla de Espinosa",
            distinctId: "Ocozocoautla-de-Espinosa",
          },
          { name: "Ostuacan", distinctId: "Ostuacan" },
          { name: "Osumacinta", distinctId: "Osumacinta" },
          { name: "Oxchuc", distinctId: "Oxchuc" },
          { name: "Palenque", distinctId: "Palenque" },
          { name: "Pantelho", distinctId: "Pantelho" },
          { name: "Pantepec", distinctId: "Pantepec" },
          { name: "Pichucalco", distinctId: "Pichucalco" },
          { name: "Pijijiapan", distinctId: "Pijijiapan" },
          {
            name: "Pueblo Nuevo Solistahuacan",
            distinctId: "Pueblo-Nuevo-Solistahuacan",
          },
          { name: "Rayon", distinctId: "Rayon" },
          { name: "Reforma", distinctId: "Reforma" },
          {
            name: "Rincon Chamula San Pedro",
            distinctId: "Rincon-Chamula-San-Pedro",
          },
          { name: "Sabanilla", distinctId: "Sabanilla" },
          { name: "Salto de Agua", distinctId: "Salto-de-Agua" },
          { name: "San Andres Duraznal", distinctId: "San-Andres-Duraznal" },
          {
            name: "San Cristobal de las Casas",
            distinctId: "San-Cristobal-de-las-Casas",
          },
          { name: "San Fernando", distinctId: "San-Fernando" },
          { name: "San Juan Cancuc", distinctId: "San-Juan-Cancuc" },
          { name: "San Lucas", distinctId: "San-Lucas" },
          { name: "Santiago el Pinar", distinctId: "Santiago-el-Pinar" },
          { name: "Siltepec", distinctId: "Siltepec" },
          { name: "Simojovel", distinctId: "Simojovel" },
          { name: "Sitala", distinctId: "Sitala" },
          { name: "Socoltenango", distinctId: "Socoltenango" },
          { name: "Solosuchiapa", distinctId: "Solosuchiapa" },
          { name: "Soyalo", distinctId: "Soyalo" },
          { name: "Suchiapa", distinctId: "Suchiapa" },
          { name: "Suchiate", distinctId: "Suchiate" },
          { name: "Sunuapa", distinctId: "Sunuapa" },
          { name: "Tapachula", distinctId: "Tapachula" },
          { name: "Tapalapa", distinctId: "Tapalapa" },
          { name: "Tapilula", distinctId: "Tapilula" },
          { name: "Tecpatan", distinctId: "Tecpatan" },
          { name: "Tenejapa", distinctId: "Tenejapa" },
          { name: "Teopisca", distinctId: "Teopisca" },
          { name: "Tila", distinctId: "Tila" },
          { name: "Tonala", distinctId: "Tonala" },
          { name: "Totolapa", distinctId: "Totolapa" },
          { name: "Tumbala", distinctId: "Tumbala" },
          { name: "Tuxtla Chico", distinctId: "Tuxtla-Chico" },
          { name: "Tuxtla Gutierrez", distinctId: "Tuxtla Gutierrez" },
          { name: "Tuzantan", distinctId: "Tuzantan" },
          { name: "Tzimol", distinctId: "Tzimol" },
          { name: "Union Juarez", distinctId: "Union-Juarez" },
          { name: "Venustiano Carranza", distinctId: "Venustiano-Carranza" },
          { name: "Villa Comaltitlan", distinctId: "Villa-Comaltitlan" },
          { name: "Villa Corzo", distinctId: "Villa-Corzo" },
          { name: "Villaflores", distinctId: "Villaflores" },
          { name: "Yajalon", distinctId: "Yajalon" },
          { name: "Zinacantan", distinctId: "Zinacantan" },
        ],
      },
      {
        name: "Chihuahua",
        distinctId: "Chihuahua",
        subregions: [
          { name: "Ahumada", distinctId: "Ahumada" },
          { name: "Aldama", distinctId: "Aldama" },
          { name: "Allende", distinctId: "Allende" },
          { name: "Aquiles Serdan", distinctId: "Aquiles Serdan" },
          { name: "Ascension", distinctId: "Ascension" },
          { name: "Bachiniva", distinctId: "Bachiniva" },
          { name: "Balleza", distinctId: "Balleza" },
          {
            name: "Batopilas de Manuel Gomez Morin",
            distinctId: "Batopilas-de-Manuel-Gomez-Morin",
          },
          { name: "Bocoyna", distinctId: "Bocoyna" },
          { name: "Buenaventura", distinctId: "Buenaventura" },
          { name: "Camargo", distinctId: "Camargo" },
          { name: "Carichi", distinctId: "Carichi" },
          { name: "Casas Grandes", distinctId: "Casas-Grandes" },
          { name: "Chihuahua", distinctId: "Chihuahua" },
          { name: "Chinipas", distinctId: "Chinipas" },
          { name: "Coronado", distinctId: "Coronado" },
          { name: "Coyame del Sotol", distinctId: "Coyame-del-Sotol" },
          { name: "Cuauhtemoc", distinctId: "Cuauhtemoc" },
          { name: "Cusihuiriachi", distinctId: "Cusihuiriachi" },
          { name: "Delicias", distinctId: "Delicias" },
          {
            name: "Dr. Belisario Dominguez",
            distinctId: "Dr.-Belisario-Dominguez",
          },
          { name: "El Tule", distinctId: "El-Tule" },
          { name: "Galeana", distinctId: "Galeana" },
          { name: "Gran Morelos", distinctId: "Gran-Morelos" },
          { name: "Guachochi", distinctId: "Guachochi" },
          { name: "Guadalupe y Calvo", distinctId: "Guadalupe-y-Calvo" },
          { name: "Guadalupe", distinctId: "Guadalupe" },
          { name: "Guazapares", distinctId: "Guazapares" },
          { name: "Guerrero", distinctId: "Guerrero" },
          { name: "Gomez Farias", distinctId: "Gomez-Farias" },
          { name: "Hidalgo del Parral", distinctId: "Hidalgo-del-Parral" },
          { name: "Huejotitan", distinctId: "Huejotitan" },
          { name: "Ignacio Zaragoza", distinctId: "Ignacio-Zaragoza" },
          { name: "Janos", distinctId: "Janos" },
          { name: "Jimenez", distinctId: "Jimenez" },
          { name: "Julimes", distinctId: "Julimes" },
          { name: "Juarez", distinctId: "Juarez" },
          { name: "La Cruz", distinctId: "La-Cruz" },
          { name: "Lopez", distinctId: "Lopez" },
          { name: "Madera", distinctId: "Madera" },
          { name: "Maguarichi", distinctId: "Maguarichi" },
          { name: "Manuel Benavides", distinctId: "Manuel-Benavides" },
          { name: "Matachi", distinctId: "Matachi" },
          { name: "Matamoros", distinctId: "Matamoros" },
          { name: "Meoqui", distinctId: "Meoqui" },
          { name: "Morelos", distinctId: "Morelos" },
          { name: "Moris", distinctId: "Moris" },
          { name: "Namiquipa", distinctId: "Namiquipa" },
          { name: "Nonoava", distinctId: "Nonoava" },
          { name: "Nuevo Casas Grandes", distinctId: "Nuevo-Casas-Grandes" },
          { name: "Ocampo", distinctId: "Ocampo" },
          { name: "Ojinaga", distinctId: "Ojinaga" },
          { name: "Praxedis G. Guerrero", distinctId: "Praxedis-G.-Guerrero" },
          { name: "Riva Palacio", distinctId: "Riva Palacio" },
          { name: "Rosales", distinctId: "Rosales" },
          { name: "Rosario", distinctId: "Rosario" },
          {
            name: "San Francisco de Borja",
            distinctId: "San-Francisco-de-Borja",
          },
          {
            name: "San Francisco de Conchos",
            distinctId: "San-Francisco-de-Conchos",
          },
          {
            name: "San Francisco del Oro",
            distinctId: "San-Francisco-del-Oro",
          },
          { name: "Santa Barbara", distinctId: "Santa-Barbara" },
          { name: "Santa Isabel", distinctId: "Santa-Isabel" },
          { name: "Satevo", distinctId: "Satevo" },
          { name: "Saucillo", distinctId: "Saucillo" },
          { name: "Temosachic", distinctId: "Temosachic" },
          { name: "Urique", distinctId: "Urique" },
          { name: "Uruachi", distinctId: "Uruachi" },
          { name: "Valle de Zaragoza", distinctId: "Valle-de-Zaragoza" },
        ],
      },
      {
        name: "Ciudad de México",
        distinctId: "Ciudad-de-Mexico",
        subregions: [
          { name: "Alvaro Obregon", distinctId: "Alvaro-Obregon" },
          { name: "Azcapotzalco", distinctId: "Azcapotzalco" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Coyoacan", distinctId: "Coyoacan" },
          {
            name: "Cuajimalpa de Morelos",
            distinctId: "Cuajimalpa-de-Morelos",
          },
          { name: "Cuauhtemoc", distinctId: "Cuauhtemoc" },
          { name: "Gustavo A. Madero", distinctId: "Gustavo-A.-Madero" },
          { name: "Iztacalco", distinctId: "Iztacalco" },
          { name: "Iztapalapa", distinctId: "Iztapalapa" },
          {
            name: "La Magdalena Contreras",
            distinctId: "La-Magdalena-Contreras",
          },
          { name: "Miguel Hidalgo", distinctId: "Miguel-Hidalgo" },
          { name: "Milpa Alta", distinctId: "Milpa-Alta" },
          { name: "Tlalpan", distinctId: "Tlalpan" },
          { name: "Tlahuac", distinctId: "Tlahuac" },
          { name: "Venustiano Carranza", distinctId: "Venustiano-Carranza" },
          { name: "Xochimilco", distinctId: "Xochimilco" },
        ],
      },
      {
        name: "Durango",
        distinctId: "Durango",
        subregions: [
          { name: "Canatlan", distinctId: "Canatlan" },
          { name: "Canelas", distinctId: "Canelas" },
          { name: "Coneto de Comonfort", distinctId: "Coneto-de-Comonfort" },
          { name: "Cuencame", distinctId: "Cuencame" },
          { name: "Durango", distinctId: "Durango" },
          { name: "El Oro", distinctId: "El-Oro" },
          {
            name: "General Simon Bolivar",
            distinctId: "General-Simon-Bolivar",
          },
          { name: "Gomez Palacio", distinctId: "Gomez-Palacio" },
          { name: "Guadalupe Victoria", distinctId: "Guadalupe-Victoria" },
          { name: "Guanacevi", distinctId: "Guanacevi" },
          { name: "Hidalgo", distinctId: "Hidalgo" },
          { name: "Inde", distinctId: "Inde" },
          { name: "Lerdo", distinctId: "Lerdo" },
          { name: "Mapimi", distinctId: "Mapimi" },
          { name: "Mezquital", distinctId: "Mezquital" },
          { name: "Nazas", distinctId: "Nazas" },
          { name: "Nombre de Dios", distinctId: "Nombre-de-Dios" },
          { name: "Nuevo Ideal", distinctId: "Nuevo-Ideal" },
          { name: "Ocampo", distinctId: "Ocampo" },
          { name: "Otaez", distinctId: "Otaez" },
          { name: "Panuco de Coronado", distinctId: "Panuco-de-Coronado" },
          { name: "Penon Blanco", distinctId: "Penon-Blanco" },
          { name: "Poanas", distinctId: "Poanas" },
          { name: "Pueblo Nuevo", distinctId: "Pueblo-Nuevo" },
          { name: "Rodeo", distinctId: "Rodeo" },
          { name: "San Bernardo", distinctId: "San-Bernardo" },
          { name: "San Dimas", distinctId: "San-Dimas" },
          {
            name: "San Juan de Guadalupe",
            distinctId: "San-Juan-de-Guadalupe",
          },
          { name: "San Juan del Rio", distinctId: "San-Juan-del-Rio" },
          { name: "San Luis del Cordero", distinctId: "San-Luis-del-Cordero" },
          { name: "San Pedro del Gallo", distinctId: "San-Pedro-del-Gallo" },
          { name: "Santa Clara", distinctId: "Santa-Clara" },
          { name: "Santiago Papasquiaro", distinctId: "Santiago-Papasquiaro" },
          { name: "Suchil", distinctId: "Suchil" },
          { name: "Tamazula", distinctId: "Tamazula" },
          { name: "Tepehuanes", distinctId: "Tepehuanes" },
          { name: "Tlahualilo", distinctId: "Tlahualilo" },
          { name: "Topia", distinctId: "Topia" },
          { name: "Vicente Guerrero", distinctId: "Vicente Guerrero" },
        ],
      },
      {
        name: "Guanajuato",
        distinctId: "Guanajuato",
        subregions: [
          { name: "Abasolo", distinctId: "Abasolo" },
          { name: "Acambaro", distinctId: "Acambaro" },
          { name: "Apaseo el Alto", distinctId: "Apaseo-el-Alto" },
          { name: "Apaseo el Grande", distinctId: "Apaseo-el-Grande" },
          { name: "Atarjea", distinctId: "Atarjea" },
          { name: "Celaya", distinctId: "Celaya" },
          { name: "Comonfort", distinctId: "Comonfort" },
          { name: "Coroneo", distinctId: "Coroneo" },
          { name: "Cortazar", distinctId: "Cortazar" },
          { name: "Cueramaro", distinctId: "Cueramaro" },
          { name: "Doctor Mora", distinctId: "Doctor-Mora" },
          {
            name: "Dolores Hidalgo Cuna de la Independencia Nacional",
            distinctId: "Dolores-Hidalgo-Cuna-de-la-Independencia-Nacional",
          },
          { name: "Guanajuato", distinctId: "Guanajuato" },
          { name: "Huanimaro", distinctId: "Huanimaro" },
          { name: "Irapuato", distinctId: "Irapuato" },
          { name: "Jaral del Progreso", distinctId: "Jaral-del-Progreso" },
          { name: "Jerecuaro", distinctId: "Jerecuaro" },
          { name: "Leon", distinctId: "Leon" },
          { name: "Manuel Doblado", distinctId: "Manuel-Doblado" },
          { name: "Moroleon", distinctId: "Moroleon" },
          { name: "Penjamo", distinctId: "Penjamo" },
          { name: "Pueblo Nuevo", distinctId: "Pueblo-Nuevo" },
          { name: "Purisima del Rincon", distinctId: "Purisima-del-Rincon" },
          { name: "Romita", distinctId: "Romita" },
          { name: "Salamanca", distinctId: "Salamanca" },
          { name: "Salvatierra", distinctId: "Salvatierra" },
          {
            name: "San Diego de la Union",
            distinctId: "San-Diego-de-la-Union",
          },
          { name: "San Felipe", distinctId: "San-Felipe" },
          {
            name: "San Francisco del Rincon",
            distinctId: "San-Francisco-del-Rincon",
          },
          { name: "San Jose Iturbide", distinctId: "San-Jose-Iturbide" },
          { name: "San Luis de la Paz", distinctId: "San-Luis-de-la-Paz" },
          {
            name: "San Miguel de Allende",
            distinctId: "San-Miguel-de-Allende",
          },
          { name: "Santa Catarina", distinctId: "Santa-Catarina" },
          {
            name: "Santa Cruz de Juventino Rosas",
            distinctId: "Santa-Cruz-de-Juventino-Rosas",
          },
          { name: "Santiago Maravatio", distinctId: "Santiago-Maravatio" },
          { name: "Silao de la Victoria", distinctId: "Silao-de-la-Victoria" },
          { name: "Tarandacuao", distinctId: "Tarandacuao" },
          { name: "Tarimoro", distinctId: "Tarimoro" },
          { name: "Tierra Blanca", distinctId: "Tierra-Blanca" },
          { name: "Uriangato", distinctId: "Uriangato" },
          { name: "Valle de Santiago", distinctId: "Valle-de-Santiago" },
          { name: "Victoria", distinctId: "Victoria" },
          { name: "Villagran", distinctId: "Villagran" },
          { name: "Xichu", distinctId: "Xichu" },
          { name: "ddsss", distinctId: "Yuriria" },
        ],
      },
      {
        name: "Guerrero",
        distinctId: "Guerrero",
        subregions: [
          { name: "Acapulco de Juarez", distinctId: "Acapulco-de-Juarez" },
          { name: "Acatepec", distinctId: "Acatepec" },
          { name: "Ahuacuotzingo", distinctId: "Ahuacuotzingo" },
          {
            name: "Ajuchitlan del Progreso",
            distinctId: "Ajuchitlan-del-Progreso",
          },
          {
            name: "Alcozauca de Guerrero",
            distinctId: "Alcozauca-de-Guerrero",
          },
          { name: "Alpoyeca", distinctId: "Alpoyeca" },
          { name: "Apaxtla", distinctId: "Apaxtla" },
          { name: "Arcelia", distinctId: "Arcelia" },
          { name: "Atenango del Rio", distinctId: "Atenango-del-Rio" },
          {
            name: "Atlamajalcingo del Monte",
            distinctId: "Atlamajalcingo-del-Monte",
          },
          { name: "Atlixtac", distinctId: "Atlixtac" },
          { name: "Atoyac de Alvarez", distinctId: "Atoyac-de-Alvarez" },
          { name: "Ayutla de los Libres", distinctId: "Ayutla-de-los-Libres" },
          { name: "Azoyu", distinctId: "Azoyu" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          {
            name: "Buenavista de Cuellar",
            distinctId: "Buenavista-de-Cuellar",
          },
          { name: "Chilapa de Alvarez", distinctId: "Chilapa-de-Alvarez" },
          {
            name: "Chilpancingo de los Bravo",
            distinctId: "Chilpancingo-de-los-Bravo",
          },
          {
            name: "Coahuayutla de Jose Maria Izazaga",
            distinctId: "Coahuayutla-de-Jose-Maria-Izazaga",
          },
          { name: "Cochoapa el Grande", distinctId: "Cochoapa-el-Grande" },
          { name: "Cocula", distinctId: "Cocula" },
          { name: "Copala", distinctId: "Copala" },
          { name: "Copalillo", distinctId: "Copalillo" },
          { name: "Copanatoyac", distinctId: "Copanatoyac" },
          { name: "Coyuca de Benitez", distinctId: "Coyuca-de-Benitez" },
          { name: "Coyuca de Catalan", distinctId: "Coyuca-de-Catalan" },
          { name: "Cuajinicuilapa", distinctId: "Cuajinicuilapa" },
          { name: "Cualac", distinctId: "Cualac" },
          { name: "Cuautepec", distinctId: "Cuautepec" },
          {
            name: "Cuetzala del Progreso",
            distinctId: "Cuetzala-del-Progreso",
          },
          { name: "Cutzamala de Pinzon", distinctId: "Cutzamala-de-Pinzon" },
          { name: "Eduardo Neri", distinctId: "Eduardo-Neri" },
          { name: "Florencio Villarreal", distinctId: "Florencio-Villarreal" },
          {
            name: "General Canuto A. Neri",
            distinctId: "General-Canuto-A.-Neri",
          },
          {
            name: "General Heliodoro Castillo",
            distinctId: "General-Heliodoro-Castillo",
          },
          { name: "Huamuxtitlan", distinctId: "Huamuxtitlan" },
          {
            name: "Huitzuco de los Figueroa",
            distinctId: "Huitzuco-de-los-Figueroa",
          },
          {
            name: "Iguala de la Independencia",
            distinctId: "Iguala-de-la-Independencia",
          },
          { name: "Igualapa", distinctId: "Igualapa" },
          { name: "Iliatenco", distinctId: "Iliatenco" },
          {
            name: "Ixcateopan de Cuauhtemoc",
            distinctId: "Ixcateopan-de-Cuauhtemoc",
          },
          {
            name: "Jose Joaquin de Herrera",
            distinctId: "Jose-Joaquin-de-Herrera",
          },
          { name: "Juan R. Escudero", distinctId: "Juan-R.-Escudero" },
          { name: "Juchitan", distinctId: "Juchitan" },
          { name: "Leonardo Bravo", distinctId: "Leonardo-Bravo" },
          { name: "Malinaltepec", distinctId: "Malinaltepec" },
          { name: "Marquelia", distinctId: "Marquelia" },
          { name: "Martir de Cuilapan", distinctId: "Martir de Cuilapan" },
          { name: "Metlatonoc", distinctId: "Metlatonoc" },
          { name: "Mochitlan", distinctId: "Mochitlan" },
          { name: "Olinala", distinctId: "Olinala" },
          { name: "Ometepec", distinctId: "Ometepec" },
          {
            name: "Pedro Ascencio Alquisiras",
            distinctId: "Pedro-Ascencio-Alquisiras",
          },
          { name: "Petatlan", distinctId: "Petatlan" },
          { name: "Pilcaya", distinctId: "Pilcaya" },
          { name: "Pungarabato", distinctId: "Pungarabato" },
          { name: "Quechultenango", distinctId: "Quechultenango" },
          { name: "San Luis Acatlan", distinctId: "San-Luis-Acatlan" },
          { name: "San Marcos", distinctId: "San-Marcos" },
          { name: "San Miguel Totolapan", distinctId: "San-Miguel-Totolapan" },
          { name: "Taxco de Alarcon", distinctId: "Taxco-de-Alarcon" },
          { name: "Tecoanapa", distinctId: "Tecoanapa" },
          { name: "Tecpan de Galeana", distinctId: "Tecpan-de-Galeana" },
          { name: "Teloloapan", distinctId: "Teloloapan" },
          {
            name: "Tepecoacuilco de Trujano",
            distinctId: "Tepecoacuilco-de-Trujano",
          },
          { name: "Tetipac", distinctId: "Tetipac" },
          { name: "Tixtla de Guerrero", distinctId: "Tixtla-de-Guerrero" },
          { name: "Tlacoachistlahuaca", distinctId: "Tlacoachistlahuaca" },
          { name: "Tlacoapa", distinctId: "Tlacoapa" },
          { name: "Tlalchapa", distinctId: "Tlalchapa" },
          {
            name: "Tlalixtaquilla de Maldonado",
            distinctId: "Tlalixtaquilla-de-Maldonado",
          },
          { name: "Tlapa de Comonfort", distinctId: "Tlapa-de-Comonfort" },
          { name: "Tlapehuala", distinctId: "Tlapehuala" },
          { name: "Xalpatlahuac", distinctId: "Xalpatlahuac" },
          { name: "Xochihuehuetlan", distinctId: "Xochihuehuetlan" },
          { name: "Xochistlahuaca", distinctId: "Xochistlahuaca" },
          { name: "Zapotitlan Tablas", distinctId: "Zapotitlan-Tablas" },
          {
            name: "Zihuatanejo de Azueta",
            distinctId: "Zihuatanejo-de-Azueta",
          },
          { name: "Zirandaro", distinctId: "Zirandaro" },
          { name: "Zitlala", distinctId: "Zitlala" },
        ],
      },
      {
        name: "Hidalgo",
        distinctId: "Hidalgo",
        subregions: [
          { name: "Acatlan", distinctId: "Acatlan" },
          { name: "Acaxochitlan", distinctId: "Acaxochitlan" },
          { name: "Actopan", distinctId: "Actopan" },
          {
            name: "Agua Blanca de Iturbide",
            distinctId: "Agua-Blanca-de-Iturbide",
          },
          { name: "Ajacuba", distinctId: "Ajacuba" },
          { name: "Alfajayucan", distinctId: "Alfajayucan" },
          { name: "Almoloya", distinctId: "Almoloya" },
          { name: "Apan", distinctId: "Apan" },
          { name: "Atitalaquia", distinctId: "Atitalaquia" },
          { name: "Atlapexco", distinctId: "Atlapexco" },
          { name: "Atotonilco de Tula", distinctId: "Atotonilco-de-Tula" },
          { name: "Atotonilco el Grande", distinctId: "Atotonilco-el-Grande" },
          { name: "Calnali", distinctId: "Calnali" },
          { name: "Cardonal", distinctId: "Cardonal" },
          { name: "Chapantongo", distinctId: "Chapantongo" },
          { name: "Chapulhuacan", distinctId: "Chapulhuacan" },
          { name: "Chilcuautla", distinctId: "Chilcuautla" },
          {
            name: "Cuautepec de Hinojosa",
            distinctId: "Cuautepec-de-Hinojosa",
          },
          { name: "El Arenal", distinctId: "El-Arenal" },
          { name: "Eloxochitlan", distinctId: "Eloxochitlan" },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Epazoyucan", distinctId: "Epazoyucan" },
          { name: "Francisco I. Madero", distinctId: "Francisco-I.-Madero" },
          { name: "Huasca de Ocampo", distinctId: "Huasca-de-Ocampo" },
          { name: "Huautla", distinctId: "Huautla" },
          { name: "Huazalingo", distinctId: "Huazalingo" },
          { name: "Huehuetla", distinctId: "Huehuetla" },
          { name: "Huejutla de Reyes", distinctId: "Huejutla-de-Reyes" },
          { name: "Huichapan", distinctId: "Huichapan" },
          { name: "Ixmiquilpan", distinctId: "Ixmiquilpan" },
          { name: "Jacala de Ledezma", distinctId: "Jacala-de-Ledezma" },
          { name: "Jaltocan", distinctId: "Jaltocan" },
          { name: "Juarez Hidalgo", distinctId: "Juarez-Hidalgo" },
          { name: "La Mision", distinctId: "La-Mision" },
          { name: "Lolotla", distinctId: "Lolotla" },
          { name: "Metepec", distinctId: "Metepec" },
          { name: "Metztitlan", distinctId: "Metztitlan" },
          {
            name: "Mineral de la Reforma",
            distinctId: "Mineral-de-la-Reforma",
          },
          { name: "Mineral del Chico", distinctId: "Mineral-del-Chico" },
          { name: "Mineral del Monte", distinctId: "Mineral-del-Monte" },
          {
            name: "Mixquiahuala de Juarez",
            distinctId: "Mixquiahuala-de-Juarez",
          },
          { name: "Molango de Escamilla", distinctId: "Molango-de-Escamilla" },
          { name: "Nicolas Flores", distinctId: "Nicolas-Flores" },
          { name: "Nopala de Villagran", distinctId: "Nopala-de-Villagran" },
          { name: "Omitlan de Juarez", distinctId: "Omitlan-de-Juarez" },
          { name: "Pachuca de Soto", distinctId: "Pachuca-de-Soto" },
          { name: "Pacula", distinctId: "Pacula" },
          { name: "Pisaflores", distinctId: "Pisaflores" },
          { name: "Progreso de Obregon", distinctId: "Progreso-de-Obregon" },
          {
            name: "San Agustin Metzquititlan",
            distinctId: "San-Agustin-Metzquititlan",
          },
          { name: "San Agustin Tlaxiaca", distinctId: "San-Agustin-Tlaxiaca" },
          {
            name: "San Bartolo Tutotepec",
            distinctId: "San-Bartolo-Tutotepec",
          },
          { name: "San Felipe Orizatlan", distinctId: "San-Felipe-Orizatlan" },
          { name: "San Salvador", distinctId: "San-Salvador" },
          {
            name: "Santiago Tulantepec de Lugo Guerrero",
            distinctId: "Santiago-Tulantepec-de-Lugo-Guerrero",
          },
          { name: "Santiago de Anaya", distinctId: "Santiago-de-Anaya" },
          { name: "Singuilucan", distinctId: "Singuilucan" },
          { name: "Tasquillo", distinctId: "Tasquillo" },
          { name: "Tecozautla", distinctId: "Tecozautla" },
          { name: "Tenango de Doria", distinctId: "Tenango-de-Doria" },
          { name: "Tepeapulco", distinctId: "Tepeapulco" },
          {
            name: "Tepehuacan de Guerrero",
            distinctId: "Tepehuacan-de-Guerrero",
          },
          {
            name: "Tepeji del Rio de Ocampo",
            distinctId: "Tepeji-del-Rio-de-Ocampo",
          },
          { name: "Tepetitlan", distinctId: "Tepetitlan" },
          { name: "Tetepango", distinctId: "Tetepango" },
          { name: "Tezontepec de Aldama", distinctId: "Tezontepec-de-Aldama" },
          { name: "Tianguistengo", distinctId: "Tianguistengo" },
          { name: "Tizayuca", distinctId: "Tizayuca" },
          { name: "Tlahuelilpan", distinctId: "Tlahuelilpan" },
          { name: "Tlahuiltepa", distinctId: "Tlahuiltepa" },
          { name: "Tlanalapa", distinctId: "Tlanalapa" },
          { name: "Tlanchinol", distinctId: "Tlanchinol" },
          { name: "Tlaxcoapan", distinctId: "Tlaxcoapan" },
          { name: "Tolcayuca", distinctId: "Tolcayuca" },
          { name: "Tula de Allende", distinctId: "Tula-de-Allende" },
          { name: "Tulancingo de Bravo", distinctId: "Tulancingo-de-Bravo" },
          { name: "Villa de Tezontepec", distinctId: "Villa-de-Tezontepec" },
          { name: "Xochiatipan", distinctId: "Xochiatipan" },
          { name: "Xochicoatlan", distinctId: "Xochicoatlan" },
          { name: "Yahualica", distinctId: "Yahualica" },
          {
            name: "Zacualtipan de Angeles",
            distinctId: "Zacualtipan-de-Angeles",
          },
          { name: "Zapotlan de Juarez", distinctId: "Zapotlan-de-Juarez" },
          { name: "Zempoala", distinctId: "Zempoala" },
          { name: "Zimapan", distinctId: "Zimapan" },
        ],
      },
      {
        name: "Jalisco",
        distinctId: "Jalisco",
        subregions: [
          { name: "Acatic", distinctId: "Acatic" },
          { name: "Acatlan de Juarez", distinctId: "Acatlan-de-Juarez" },
          { name: "Ahualulco de Mercado", distinctId: "Ahualulco-de-Mercado" },
          { name: "Amacueca", distinctId: "Amacueca" },
          { name: "Amatitan", distinctId: "Amatitan" },
          { name: "Ameca", distinctId: "Ameca" },
          { name: "Arandas", distinctId: "Arandas" },
          { name: "Atemajac de Brizuela", distinctId: "Atemajac-de-Brizuela" },
          { name: "Atenguillo", distinctId: "Atenguillo" },
          { name: "Atotonilco el Alto", distinctId: "Atotonilco-el-Alto" },
          { name: "Atoyac", distinctId: "Atoyac" },
          { name: "Autlan de Navarro", distinctId: "Autlan-de-Navarro" },
          { name: "Ayotlan", distinctId: "Ayotlan" },
          { name: "Ayutla", distinctId: "Ayutla" },
          { name: "Bolanos", distinctId: "Bolanos" },
          { name: "Cabo Corrientes", distinctId: "Cabo-Corrientes" },
          { name: "Canadas de Obregon", distinctId: "Canadas-de-Obregon" },
          { name: "Casimiro Castillo", distinctId: "Casimiro-Castillo" },
          { name: "Chapala", distinctId: "Chapala" },
          { name: "Chimaltitan", distinctId: "Chimaltitan" },
          { name: "Chiquilistlan", distinctId: "Chiquilistlan" },
          { name: "Cihuatlan", distinctId: "Cihuatlan" },
          { name: "Cocula", distinctId: "Cocula" },
          { name: "Colotlan", distinctId: "Colotlan" },
          {
            name: "Concepcion de Buenos Aires",
            distinctId: "Concepcion-de-Buenos-Aires",
          },
          {
            name: "Cuautitlan de Garcia Barragan",
            distinctId: "Cuautitlan-de-Garcia-Barragan",
          },
          { name: "Cuautla", distinctId: "Cuautla" },
          { name: "Cuquio", distinctId: "Cuquio" },
          { name: "Degollado", distinctId: "Degollado" },
          { name: "Ejutla", distinctId: "Ejutla" },
          { name: "El Arenal", distinctId: "El-Arenal" },
          { name: "El Grullo", distinctId: "El-Grullo" },
          { name: "El Limon", distinctId: "El-Limon" },
          { name: "El Salto", distinctId: "El-Salto" },
          { name: "Encarnacion de Diaz", distinctId: "Encarnacion-de-Diaz" },
          { name: "Etzatlan", distinctId: "Etzatlan" },
          { name: "Gomez Farias", distinctId: "Gomez-Farias" },
          { name: "Guachinango", distinctId: "Guachinango" },
          { name: "Guadalajara", distinctId: "Guadalajara" },
          { name: "Hostotipaquillo", distinctId: "Hostotipaquillo" },
          { name: "Huejucar", distinctId: "Huejucar" },
          { name: "Huejuquilla el Alto", distinctId: "Huejuquilla-el-Alto" },
          {
            name: "Ixtlahuacan de los Membrillos",
            distinctId: "Ixtlahuacan-de-los-Membrillos",
          },
          { name: "Ixtlahuacan del Rio", distinctId: "Ixtlahuacan-del-Rio" },
          { name: "Jalostotitlan", distinctId: "Jalostotitlan" },
          { name: "Jamay", distinctId: "Jamay" },
          { name: "Jesus Maria", distinctId: "Jesus-Maria" },
          {
            name: "Jilotlan de los Dolores",
            distinctId: "Jilotlan-de-los-Dolores",
          },
          { name: "Jocotepec", distinctId: "Jocotepec" },
          { name: "Juanacatlan", distinctId: "Juanacatlan" },
          { name: "Juchitlan", distinctId: "Juchitlan" },
          { name: "La Barca", distinctId: "La-Barca" },
          { name: "La Huerta", distinctId: "La-Huerta" },
          {
            name: "La Manzanilla de la Paz",
            distinctId: "La Manzanilla-de-la-Paz",
          },
          { name: "Lagos de Moreno", distinctId: "Lagos-de-Moreno" },
          { name: "Magdalena", distinctId: "Magdalena" },
          { name: "Mascota", distinctId: "Mascota" },
          { name: "Mazamitla", distinctId: "Mazamitla" },
          { name: "Mexticacan", distinctId: "Mexticacan" },
          { name: "Mezquitic", distinctId: "Mezquitic" },
          { name: "Mixtlan", distinctId: "Mixtlan" },
          { name: "Ocotlan", distinctId: "Ocotlan" },
          { name: "Ojuelos de Jalisco", distinctId: "Ojuelos-de-Jalisco" },
          { name: "Pihuamo", distinctId: "Pihuamo" },
          { name: "Poncitlan", distinctId: "Poncitlan" },
          { name: "Puerto Vallarta", distinctId: "Puerto-Vallarta" },
          { name: "Quitupan", distinctId: "Quitupan" },
          {
            name: "San Cristobal de la Barranca",
            distinctId: "San-Cristobal-de-la-Barranca",
          },
          {
            name: "San Diego de Alejandria",
            distinctId: "San-Diego-de-Alejandria",
          },
          { name: "San Gabriel", distinctId: "San-Gabriel" },
          {
            name: "San Ignacio Cerro Gordo",
            distinctId: "San-Ignacio-Cerro-Gordo",
          },
          {
            name: "San Juan de los Lagos",
            distinctId: "San-Juan-de-los-Lagos",
          },
          {
            name: "San Juanito de Escobedo",
            distinctId: "San-Juanito-de-Escobedo",
          },
          { name: "San Julian", distinctId: "San-Julian" },
          { name: "San Marcos", distinctId: "San-Marcos" },
          { name: "San Martin Hidalgo", distinctId: "San-Martin-Hidalgo" },
          {
            name: "San Martin de Bolanos",
            distinctId: "San-Martin-de-Bolanos",
          },
          { name: "San Miguel el Alto", distinctId: "San-Miguel-el-Alto" },
          {
            name: "San Pedro Tlaquepaque",
            distinctId: "San-Pedro-Tlaquepaque",
          },
          {
            name: "San Sebastian del Oeste",
            distinctId: "San-Sebastian-del-Oeste",
          },
          {
            name: "Santa Maria de los Angeles",
            distinctId: "Santa-Maria-de-los-Angeles",
          },
          { name: "Santa Maria del Oro", distinctId: "Santa-Maria-del-Oro" },
          { name: "Sayula", distinctId: "Sayula" },
          { name: "Tala", distinctId: "Tala" },
          { name: "Talpa de Allende", distinctId: "Talpa-de-Allende" },
          { name: "Tamazula de Gordiano", distinctId: "Tamazula-de-Gordiano" },
          { name: "Tapalpa", distinctId: "Tapalpa" },
          { name: "Tecalitlan", distinctId: "Tecalitlan" },
          {
            name: "Techaluta de Montenegro",
            distinctId: "Techaluta-de-Montenegro",
          },
          { name: "Tecolotlan", distinctId: "Tecolotlan" },
          { name: "Tenamaxtlan", distinctId: "Tenamaxtlan" },
          { name: "Teocaltiche", distinctId: "Teocaltiche" },
          {
            name: "Teocuitatlan de Corona",
            distinctId: "Teocuitatlan-de-Corona",
          },
          {
            name: "Tepatitlan de Morelos",
            distinctId: "Tepatitlan-de-Morelos",
          },
          { name: "Tequila", distinctId: "Tequila" },
          { name: "Teuchitlan", distinctId: "Teuchitlan" },
          { name: "Tizapan el Alto", distinctId: "Tizapan-el-Alto" },
          { name: "Tlajomulco de Zuniga", distinctId: "Tlajomulco-de-Zuniga" },
          { name: "Toliman", distinctId: "Toliman" },
          { name: "Tomatlan", distinctId: "Tomatlan" },
          { name: "Tonala", distinctId: "Tonala" },
          { name: "Tonaya", distinctId: "Tonaya" },
          { name: "Tonila", distinctId: "Tonila" },
          { name: "Totatiche", distinctId: "Totatiche" },
          { name: "Tototlan", distinctId: "Tototlan" },
          { name: "Tuxcacuesco", distinctId: "Tuxcacuesco" },
          { name: "Tuxcueca", distinctId: "Tuxcueca" },
          { name: "Tuxpan", distinctId: "Tuxpan" },
          { name: "Union de San Antonio", distinctId: "Union-de-San-Antonio" },
          { name: "Union de Tula", distinctId: "Union-de-Tula" },
          { name: "Valle de Guadalupe", distinctId: "Valle-de-Guadalupe" },
          { name: "Valle de Juarez", distinctId: "Valle-de-Juarez" },
          { name: "Villa Corona", distinctId: "Villa-Corona" },
          { name: "Villa Guerrero", distinctId: "Villa-Guerrero" },
          { name: "Villa Hidalgo", distinctId: "Villa-Hidalgo" },
          { name: "Villa Purificacion", distinctId: "Villa-Purificacion" },
          {
            name: "Yahualica de Gonzalez Gallo",
            distinctId: "Yahualica-de-Gonzalez-Gallo",
          },
          { name: "Zacoalco de Torres", distinctId: "Zacoalco-de-Torres" },
          { name: "Zapopan", distinctId: "Zapopan" },
          { name: "Zapotiltic", distinctId: "Zapotiltic" },
          {
            name: "Zapotitlan de Vadillo",
            distinctId: "Zapotitlan-de-Vadillo",
          },
          { name: "Zapotlan del Rey", distinctId: "Zapotlan-del-Rey" },
          { name: "Zapotlan el Grande", distinctId: "Zapotlan-el-Grande" },
          { name: "Zapotlanejo", distinctId: "Zapotlanejo" },
        ],
      },
      {
        name: "Estado de México",
        distinctId: "Estado-de-Mexico",
        subregions: [
          {
            name: "Acambay de Ruiz Castaneda",
            distinctId: "Acambay-de-Ruiz-Castaneda",
          },
          { name: "Acolman", distinctId: "Acolman" },
          { name: "Aculco", distinctId: "Aculco" },
          {
            name: "Almoloya de Alquisiras",
            distinctId: "Almoloya-de-Alquisiras",
          },
          { name: "Almoloya de Juarez", distinctId: "Almoloya-de-Juarez" },
          { name: "Almoloya del Rio", distinctId: "Almoloya-del-Rio" },
          { name: "Amanalco", distinctId: "Amanalco" },
          { name: "Amatepec", distinctId: "Amatepec" },
          { name: "Amecameca", distinctId: "Amecameca" },
          { name: "Apaxco", distinctId: "Apaxco" },
          { name: "Atenco", distinctId: "Atenco" },
          { name: "Atizapan de Zaragoza", distinctId: "Atizapan-de-Zaragoza" },
          { name: "Atizapan", distinctId: "Atizapan" },
          { name: "Atlacomulco", distinctId: "Atlacomulco" },
          { name: "Atlautla", distinctId: "Atlautla" },
          { name: "Axapusco", distinctId: "Axapusco" },
          { name: "Ayapango", distinctId: "Ayapango" },
          { name: "Calimaya", distinctId: "Calimaya" },
          { name: "Capulhuac", distinctId: "Capulhuac" },
          { name: "Chalco", distinctId: "Chalco" },
          { name: "Chapa de Mota", distinctId: "Chapa-de-Mota" },
          { name: "Chapultepec", distinctId: "Chapultepec" },
          { name: "Chiautla", distinctId: "Chiautla" },
          { name: "Chicoloapan", distinctId: "Chicoloapan" },
          { name: "Chiconcuac", distinctId: "Chiconcuac" },
          { name: "Chimalhuacan", distinctId: "Chimalhuacan" },
          {
            name: "Coacalco de Berriozabal",
            distinctId: "Coacalco-de-Berriozabal",
          },
          { name: "Coatepec Harinas", distinctId: "Coatepec-Harinas" },
          { name: "Cocotitlan", distinctId: "Cocotitlan" },
          { name: "Coyotepec", distinctId: "Coyotepec" },
          { name: "Cuautitlan Izcalli", distinctId: "Cuautitlan-Izcalli" },
          { name: "Cuautitlan", distinctId: "Cuautitlan" },
          { name: "Donato Guerra", distinctId: "Donato-Guerra" },
          { name: "Ecatepec de Morelos", distinctId: "Ecatepec-de-Morelos" },
          { name: "Ecatzingo", distinctId: "Ecatzingo" },
          { name: "El Oro", distinctId: "El-Oro" },
          { name: "Huehuetoca", distinctId: "Huehuetoca" },
          { name: "Hueypoxtla", distinctId: "Hueypoxtla" },
          { name: "Huixquilucan", distinctId: "Huixquilucan" },
          { name: "Isidro Fabela", distinctId: "Isidro-Fabela" },
          { name: "Ixtapaluca", distinctId: "Ixtapaluca" },
          { name: "Ixtapan de la Sal", distinctId: "Ixtapan-de-la-Sal" },
          { name: "Ixtapan del Oro", distinctId: "Ixtapan-del-Oro" },
          { name: "Ixtlahuaca", distinctId: "Ixtlahuaca" },
          { name: "Jaltenco", distinctId: "Jaltenco" },
          { name: "Jilotepec", distinctId: "Jilotepec" },
          { name: "Jilotzingo", distinctId: "Jilotzingo" },
          { name: "Jiquipilco", distinctId: "Jiquipilco" },
          { name: "Jocotitlan", distinctId: "Jocotitlan" },
          { name: "Joquicingo", distinctId: "Joquicingo" },
          { name: "Juchitepec", distinctId: "Juchitepec" },
          { name: "La Paz", distinctId: "La Paz" },
          { name: "Lerma", distinctId: "Lerma" },
          { name: "Luvianos", distinctId: "Luvianos" },
          { name: "Malinalco", distinctId: "Malinalco" },
          { name: "Melchor Ocampo", distinctId: "Melchor-Ocampo" },
          { name: "Metepec", distinctId: "Metepec" },
          { name: "Mexicaltzingo", distinctId: "Mexicaltzingo" },
          { name: "Morelos", distinctId: "Morelos" },
          { name: "Naucalpan de Juarez", distinctId: "Naucalpan-de-Juarez" },
          { name: "Nextlalpan", distinctId: "Nextlalpan" },
          { name: "Nezahualcoyotl", distinctId: "Nezahualcoyotl" },
          { name: "Nicolas Romero", distinctId: "Nicolas Romero" },
          { name: "Nopaltepec", distinctId: "Nopaltepec" },
          { name: "Ocoyoacac", distinctId: "Ocoyoacac" },
          { name: "Ocuilan", distinctId: "Ocuilan" },
          { name: "Otumba", distinctId: "Otumba" },
          { name: "Otzoloapan", distinctId: "Otzoloapan" },
          { name: "Otzolotepec", distinctId: "Otzolotepec" },
          { name: "Ozumba", distinctId: "Ozumba" },
          { name: "Papalotla", distinctId: "Papalotla" },
          { name: "Polotitlan", distinctId: "Polotitlan" },
          { name: "Rayon", distinctId: "Rayon" },
          { name: "San Antonio la Isla", distinctId: "San-Antonio-la-Isla" },
          {
            name: "San Felipe del Progreso",
            distinctId: "San-Felipe-del-Progreso",
          },
          { name: "San Jose del Rincon", distinctId: "San-Jose-del-Rincon" },
          {
            name: "San Martin de las Piramides",
            distinctId: "San-Martin-de-las-Piramides",
          },
          { name: "San Mateo Atenco", distinctId: "San-Mateo-Atenco" },
          {
            name: "San Simon de Guerrero",
            distinctId: "San-Simon-de-Guerrero",
          },
          { name: "Santo Tomas", distinctId: "Santo-Tomas" },
          {
            name: "Soyaniquilpan de Juarez",
            distinctId: "Soyaniquilpan-de-Juarez",
          },
          { name: "Sultepec", distinctId: "Sultepec" },
          { name: "Tecamac", distinctId: "Tecamac" },
          { name: "Tejupilco", distinctId: "Tejupilco" },
          { name: "Temamatla", distinctId: "Temamatla" },
          { name: "Temascalapa", distinctId: "Temascalapa" },
          { name: "Temascalcingo", distinctId: "Temascalcingo" },
          { name: "Temascaltepec", distinctId: "Temascaltepec" },
          { name: "Temoaya", distinctId: "Temoaya" },
          { name: "Tenancingo", distinctId: "Tenancingo" },
          { name: "Tenango del Aire", distinctId: "Tenango-del-Aire" },
          { name: "Tenango del Valle", distinctId: "Tenango-del-Valle" },
          { name: "Teoloyucan", distinctId: "Teoloyucan" },
          { name: "Teotihuacan", distinctId: "Teotihuacan" },
          { name: "Tepetlaoxtoc", distinctId: "Tepetlaoxtoc" },
          { name: "Tepetlixpa", distinctId: "Tepetlixpa" },
          { name: "Tepotzotlan", distinctId: "Tepotzotlan" },
          { name: "Tequixquiac", distinctId: "Tequixquiac" },
          { name: "Texcaltitlan", distinctId: "Texcaltitlan" },
          { name: "Texcalyacac", distinctId: "Texcalyacac" },
          { name: "Texcoco", distinctId: "Texcoco" },
          { name: "Tezoyuca", distinctId: "Tezoyuca" },
          { name: "Tianguistenco", distinctId: "Tianguistenco" },
          { name: "Timilpan", distinctId: "Timilpan" },
          { name: "Tlalmanalco", distinctId: "Tlalmanalco" },
          { name: "Tlalnepantla de Baz", distinctId: "Tlalnepantla-de-Baz" },
          { name: "Tlatlaya", distinctId: "Tlatlaya" },
          { name: "Toluca", distinctId: "Toluca" },
          { name: "Tonanitla", distinctId: "Tonanitla" },
          { name: "Tonatico", distinctId: "Tonatico" },
          { name: "Tultepec", distinctId: "Tultepec" },
          { name: "Tultitlan", distinctId: "Tultitlan" },
          { name: "Valle de Bravo", distinctId: "Valle-de-Bravo" },
          {
            name: "Valle de Chalco Solidaridad",
            distinctId: "Valle-de-Chalco-Solidaridad",
          },
          { name: "Villa Guerrero", distinctId: "Villa-Guerrero" },
          { name: "Villa Victoria", distinctId: "Villa-Victoria" },
          { name: "Villa de Allende", distinctId: "Villa-de-Allende" },
          { name: "Villa del Carbon", distinctId: "Villa-del-Carbon" },
          { name: "Xalatlaco", distinctId: "Xalatlaco" },
          { name: "Xonacatlan", distinctId: "Xonacatlan" },
          { name: "Zacazonapan", distinctId: "Zacazonapan" },
          { name: "Zacualpan", distinctId: "Zacualpan" },
          { name: "Zinacantepec", distinctId: "Zinacantepec" },
          { name: "Zumpahuacan", distinctId: "Zumpahuacan" },
          { name: "Zumpango", distinctId: "Zumpango" },
        ],
      },
      {
        name: "Michoacan",
        distinctId: "Michoacan",
        subregions: [
          { name: "Acuitzio", distinctId: "Acuitzio" },
          { name: "Aguililla", distinctId: "Aguililla" },
          { name: "Alvaro Obregon", distinctId: "Alvaro-Obregon" },
          { name: "Angamacutiro", distinctId: "Angamacutiro" },
          { name: "Angangueo", distinctId: "Angangueo" },
          { name: "Apatzingan", distinctId: "Apatzingan" },
          { name: "Aporo", distinctId: "Aporo" },
          { name: "Aquila", distinctId: "Aquila" },
          { name: "Ario", distinctId: "Ario" },
          { name: "Arteaga", distinctId: "Arteaga" },
          { name: "Brisenas", distinctId: "Brisenas" },
          { name: "Buenavista", distinctId: "Buenavista" },
          { name: "Caracuaro", distinctId: "Caracuaro" },
          { name: "Charapan", distinctId: "Charapan" },
          { name: "Charo", distinctId: "Charo" },
          { name: "Chavinda", distinctId: "Chavinda" },
          { name: "Cheran", distinctId: "Cheran" },
          { name: "Chilchota", distinctId: "Chilchota" },
          { name: "Chinicuila", distinctId: "Chinicuila" },
          { name: "Chucandiro", distinctId: "Chucandiro" },
          { name: "Churintzio", distinctId: "Churintzio" },
          { name: "Churumuco", distinctId: "Churumuco" },
          { name: "Coahuayana", distinctId: "Coahuayana" },
          {
            name: "Coalcoman de Vazquez Pallares",
            distinctId: "Coalcoman-de Vazquez Pallares",
          },
          { name: "Coeneo", distinctId: "Coeneo" },
          {
            name: "Cojumatlan de Regules",
            distinctId: "Cojumatlan-de Regules",
          },
          { name: "Contepec", distinctId: "Contepec" },
          { name: "Copandaro", distinctId: "Copandaro" },
          { name: "Cotija", distinctId: "Cotija" },
          { name: "Cuitzeo", distinctId: "Cuitzeo" },
          { name: "Ecuandureo", distinctId: "Ecuandureo" },
          { name: "Epitacio Huerta", distinctId: "Epitacio-Huerta" },
          { name: "Erongaricuaro", distinctId: "Erongaricuaro" },
          { name: "Gabriel Zamora", distinctId: "Gabriel-Zamora" },
          { name: "Hidalgo", distinctId: "Hidalgo" },
          { name: "Huandacareo", distinctId: "Huandacareo" },
          { name: "Huaniqueo", distinctId: "Huaniqueo" },
          { name: "Huetamo", distinctId: "Huetamo" },
          { name: "Huiramba", distinctId: "Huiramba" },
          { name: "Indaparapeo", distinctId: "Indaparapeo" },
          { name: "Irimbo", distinctId: "Irimbo" },
          { name: "Ixtlan", distinctId: "Ixtlan" },
          { name: "Jacona", distinctId: "Jacona" },
          { name: "Jimenez", distinctId: "Jimenez" },
          { name: "Jiquilpan", distinctId: "Jiquilpan" },
          { name: "Jose Sixto Verduzco", distinctId: "Jose-Sixto Verduzco" },
          { name: "Juarez", distinctId: "Juarez" },
          { name: "Jungapeo", distinctId: "Jungapeo" },
          { name: "La Huacana", distinctId: "La-Huacana" },
          { name: "La Piedad", distinctId: "La-Piedad" },
          { name: "Lagunillas", distinctId: "Lagunillas" },
          { name: "Lazaro Cardenas", distinctId: "Lazaro-Cardenas" },
          { name: "Los Reyes", distinctId: "Los-Reyes" },
          { name: "Madero", distinctId: "Madero" },
          { name: "Maravatio", distinctId: "Maravatio" },
          { name: "Marcos Castellanos", distinctId: "Marcos-Castellanos" },
          { name: "Morelia", distinctId: "Morelia" },
          { name: "Morelos", distinctId: "Morelos" },
          { name: "Mugica", distinctId: "Mugica" },
          { name: "Nahuatzen", distinctId: "Nahuatzen" },
          { name: "Nocupetaro", distinctId: "Nocupetaro" },
          {
            name: "Nuevo Parangaricutiro",
            distinctId: "Nuevo-Parangaricutiro",
          },
          { name: "Nuevo Urecho", distinctId: "Nuevo-Urecho" },
          { name: "Numaran", distinctId: "Numaran" },
          { name: "Ocampo", distinctId: "Ocampo" },
          { name: "Pajacuaran", distinctId: "Pajacuaran" },
          { name: "Panindicuaro", distinctId: "Panindicuaro" },
          { name: "Paracho", distinctId: "Paracho" },
          { name: "Paracuaro", distinctId: "Paracuaro" },
          { name: "Patzcuaro", distinctId: "Patzcuaro" },
          { name: "Penjamillo", distinctId: "Penjamillo" },
          { name: "Periban", distinctId: "Periban" },
          { name: "Purepero", distinctId: "Purepero" },
          { name: "Puruandiro", distinctId: "Puruandiro" },
          { name: "Querendaro", distinctId: "Querendaro" },
          { name: "Quiroga", distinctId: "Quiroga" },
          { name: "Sahuayo", distinctId: "Sahuayo" },
          { name: "Salvador Escalante", distinctId: "Salvador-Escalante" },
          { name: "San Lucas", distinctId: "San-Lucas" },
          { name: "Santa Ana Maya", distinctId: "Santa-Ana Maya" },
          { name: "Senguio", distinctId: "Senguio" },
          { name: "Susupuato", distinctId: "Susupuato" },
          { name: "Tacambaro", distinctId: "Tacambaro" },
          { name: "Tancitaro", distinctId: "Tancitaro" },
          { name: "Tangamandapio", distinctId: "Tangamandapio" },
          { name: "Tangancicuaro", distinctId: "Tangancicuaro" },
          { name: "Tanhuato", distinctId: "Tanhuato" },
          { name: "Taretan", distinctId: "Taretan" },
          { name: "Tarimbaro", distinctId: "Tarimbaro" },
          { name: "Tepalcatepec", distinctId: "Tepalcatepec" },
          { name: "Tingambato", distinctId: "Tingambato" },
          { name: "Tinguindin", distinctId: "Tinguindin" },
          {
            name: "Tiquicheo de Nicolas Romero",
            distinctId: "Tiquicheo-de Nicolas Romero",
          },
          { name: "Tlalpujahua", distinctId: "Tlalpujahua" },
          { name: "Tlazazalca", distinctId: "Tlazazalca" },
          { name: "Tocumbo", distinctId: "Tocumbo" },
          { name: "Tumbiscatio", distinctId: "Tumbiscatio" },
          { name: "Turicato", distinctId: "Turicato" },
          { name: "Tuxpan", distinctId: "Tuxpan" },
          { name: "Tuzantla", distinctId: "Tuzantla" },
          { name: "Tzintzuntzan", distinctId: "Tzintzuntzan" },
          { name: "Tzitzio", distinctId: "Tzitzio" },
          { name: "Uruapan", distinctId: "Uruapan" },
          { name: "Venustiano Carranza", distinctId: "Venustiano-Carranza" },
          { name: "Villamar", distinctId: "Villamar" },
          { name: "Vista Hermosa", distinctId: "Vista-Hermosa" },
          { name: "Yurecuaro", distinctId: "Yurecuaro" },
          { name: "Zacapu", distinctId: "Zacapu" },
          { name: "Zamora", distinctId: "Zamora" },
          { name: "Zinaparo", distinctId: "Zinaparo" },
          { name: "Zinapecuaro", distinctId: "Zinapecuaro" },
          { name: "Ziracuaretiro", distinctId: "Ziracuaretiro" },
          { name: "Zitacuaro", distinctId: "Zitacuaro" },
        ],
      },
      {
        name: "Morelos",
        distinctId: "Morelos",
        subregions: [
          { name: "Amacuzac", distinctId: "Amacuzac" },
          { name: "Atlatlahucan", distinctId: "Atlatlahucan" },
          { name: "Axochiapan", distinctId: "Axochiapan" },
          { name: "Ayala", distinctId: "Ayala" },
          { name: "Coatlan del Rio", distinctId: "Coatlan-del-Rio" },
          { name: "Cuautla", distinctId: "Cuautla" },
          { name: "Cuernavaca", distinctId: "Cuernavaca" },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Huitzilac", distinctId: "Huitzilac" },
          { name: "Jantetelco", distinctId: "Jantetelco" },
          { name: "Jiutepec", distinctId: "Jiutepec" },
          { name: "Jojutla", distinctId: "Jojutla" },
          {
            name: "Jonacatepec de Leandro Valle",
            distinctId: "Jonacatepec-de Leandro Valle",
          },
          { name: "Mazatepec", distinctId: "Mazatepec" },
          { name: "Miacatlan", distinctId: "Miacatlan" },
          { name: "Ocuituco", distinctId: "Ocuituco" },
          { name: "Puente de Ixtla", distinctId: "Puente-de-Ixtla" },
          { name: "Temixco", distinctId: "Temixco" },
          { name: "Temoac", distinctId: "Temoac" },
          { name: "Tepalcingo", distinctId: "Tepalcingo" },
          { name: "Tepoztlan", distinctId: "Tepoztlan" },
          { name: "Tetecala", distinctId: "Tetecala" },
          { name: "Tetela del Volcan", distinctId: "Tetela-del-Volcan" },
          { name: "Tlalnepantla", distinctId: "Tlalnepantla" },
          {
            name: "Tlaltizapan de Zapata",
            distinctId: "Tlaltizapan-de Zapata",
          },
          { name: "Tlaquiltenango", distinctId: "Tlaquiltenango" },
          { name: "Tlayacapan", distinctId: "Tlayacapan" },
          { name: "Totolapan", distinctId: "Totolapan" },
          { name: "Xochitepec", distinctId: "Xochitepec" },
          { name: "Yautepec", distinctId: "Yautepec" },
          { name: "Yecapixtla", distinctId: "Yecapixtla" },
          { name: "Zacatepec", distinctId: "Zacatepec" },
          { name: "Zacualpan de Amilpas", distinctId: "Zacualpan-de-Amilpas" },
        ],
      },
      {
        name: "Nayarit",
        distinctId: "Nayarit",
        subregions: [
          { name: "Acaponeta", distinctId: "Acaponeta" },
          { name: "Ahuacatlan", distinctId: "Ahuacatlan" },
          { name: "Amatlan de Canas", distinctId: "Amatlan-de-Canas" },
          { name: "Bahia de Banderas", distinctId: "Bahia-de-Banderas" },
          { name: "Compostela", distinctId: "Compostela" },
          { name: "Del Nayar", distinctId: "Del-Nayar" },
          { name: "Huajicori", distinctId: "Huajicori" },
          { name: "Ixtlan del Rio", distinctId: "Ixtlan-del-Rio" },
          { name: "Jala", distinctId: "Jala" },
          { name: "La Yesca", distinctId: "La-Yesca" },
          { name: "Rosamorada", distinctId: "Rosamorada" },
          { name: "Ruiz", distinctId: "Ruiz" },
          { name: "San Blas", distinctId: "San-Blas" },
          { name: "San Pedro Lagunillas", distinctId: "San-Pedro-Lagunillas" },
          { name: "Santa Maria del Oro", distinctId: "Santa-Maria-del-Oro" },
          { name: "Santiago Ixcuintla", distinctId: "Santiago-Ixcuintla" },
          { name: "Tecuala", distinctId: "Tecuala" },
          { name: "Tepic", distinctId: "Tepic" },
          { name: "Tuxpan", distinctId: "Tuxpan" },
          { name: "Xalisco", distinctId: "Xalisco" },
        ],
      },
      {
        name: "Nuevo León",
        distinctId: "Nuevo Leon",
        subregions: [
          { name: "Abasolo", distinctId: "Abasolo" },
          { name: "Agualeguas", distinctId: "Agualeguas" },
          { name: "Allende", distinctId: "Allende" },
          { name: "Anahuac", distinctId: "Anahuac" },
          { name: "Apodaca", distinctId: "Apodaca" },
          { name: "Aramberri", distinctId: "Aramberri" },
          { name: "Bustamante", distinctId: "Bustamante" },
          { name: "Cadereyta Jimenez", distinctId: "Cadereyta-Jimenez" },
          { name: "Cerralvo", distinctId: "Cerralvo" },
          { name: "China", distinctId: "China" },
          { name: "Cienega de Flores", distinctId: "Cienega-de-Flores" },
          { name: "Doctor Arroyo", distinctId: "Doctor-Arroyo" },
          { name: "Doctor Coss", distinctId: "Doctor-Coss" },
          { name: "Doctor Gonzalez", distinctId: "Doctor-Gonzalez" },
          { name: "El Carmen", distinctId: "El-Carmen" },
          { name: "Galeana", distinctId: "Galeana" },
          { name: "Garcia", distinctId: "Garcia" },
          { name: "General Bravo", distinctId: "General-Bravo" },
          { name: "General Escobedo", distinctId: "General-Escobedo" },
          { name: "General Teran", distinctId: "General-Teran" },
          { name: "General Trevino", distinctId: "General-Trevino" },
          { name: "General Zaragoza", distinctId: "General-Zaragoza" },
          { name: "General Zuazua", distinctId: "General-Zuazua" },
          { name: "Guadalupe", distinctId: "Guadalupe" },
          { name: "Hidalgo", distinctId: "Hidalgo" },
          { name: "Higueras", distinctId: "Higueras" },
          { name: "Hualahuises", distinctId: "Hualahuises" },
          { name: "Iturbide", distinctId: "Iturbide" },
          { name: "Juarez", distinctId: "Juarez" },
          { name: "Lampazos de Naranjo", distinctId: "Lampazos-de-Naranjo" },
          { name: "Linares", distinctId: "Linares" },
          { name: "Los Aldamas", distinctId: "Los-Aldamas" },
          { name: "Los Herreras", distinctId: "Los-Herreras" },
          { name: "Los Ramones", distinctId: "Los-Ramones" },
          { name: "Marin", distinctId: "Marin" },
          { name: "Melchor Ocampo", distinctId: "Melchor-Ocampo" },
          { name: "Mier y Noriega", distinctId: "Mier-y-Noriega" },
          { name: "Mina", distinctId: "Mina" },
          { name: "Montemorelos", distinctId: "Montemorelos" },
          { name: "Monterrey", distinctId: "Monterrey" },
          { name: "Paras", distinctId: "Paras" },
          { name: "Pesqueria", distinctId: "Pesqueria" },
          { name: "Rayones", distinctId: "Rayones" },
          { name: "Sabinas Hidalgo", distinctId: "Sabinas-Hidalgo" },
          { name: "Salinas Victoria", distinctId: "Salinas-Victoria" },
          {
            name: "San Nicolas de los Garza",
            distinctId: "San-Nicolas-de-los-Garza",
          },
          {
            name: "San Pedro Garza Garcia",
            distinctId: "San-Pedro-Garza-Garcia",
          },
          { name: "Santa Catarina", distinctId: "Santa-Catarina" },
          { name: "Santiago", distinctId: "Santiago" },
          { name: "Vallecillo", distinctId: "Vallecillo" },
          { name: "Villaldama", distinctId: "Villaldama" },
        ],
      },
      {
        name: "Oaxaca",
        distinctId: "Oaxaca",
        subregions: [
          { name: "Abejones", distinctId: "Abejones" },
          {
            name: "Acatlan de Perez Figueroa",
            distinctId: "Acatlan-de-Perez-Figueroa",
          },
          { name: "Animas Trujano", distinctId: "Animas-Trujano" },
          { name: "Asuncion Cacalotepec", distinctId: "Asuncion-Cacalotepec" },
          { name: "Asuncion Cuyotepeji", distinctId: "Asuncion-Cuyotepeji" },
          { name: "Asuncion Ixtaltepec", distinctId: "Asuncion-Ixtaltepec" },
          { name: "Asuncion Nochixtlan", distinctId: "Asuncion-Nochixtlan" },
          { name: "Asuncion Ocotlan", distinctId: "Asuncion-Ocotlan" },
          { name: "Asuncion Tlacolulita", distinctId: "Asuncion-Tlacolulita" },
          { name: "Ayoquezco de Aldama", distinctId: "Ayoquezco-de-Aldama" },
          { name: "Ayotzintepec", distinctId: "Ayotzintepec" },
          { name: "Calihuala", distinctId: "Calihuala" },
          { name: "Candelaria Loxicha", distinctId: "Candelaria-Loxicha" },
          { name: "Capulalpam de Mendez", distinctId: "Capulalpam-de-Mendez" },
          { name: "Chahuites", distinctId: "Chahuites" },
          {
            name: "Chalcatongo de Hidalgo",
            distinctId: "Chalcatongo-de-Hidalgo",
          },
          {
            name: "Chiquihuitlan de Benito Juarez",
            distinctId: "Chiquihuitlan-de-Benito-Juarez",
          },
          { name: "Cienega de Zimatlan", distinctId: "Cienega-de-Zimatlan" },
          { name: "Ciudad Ixtepec", distinctId: "Ciudad-Ixtepec" },
          { name: "Coatecas Altas", distinctId: "Coatecas-Altas" },
          {
            name: "Coicoyan de las Flores",
            distinctId: "Coicoyan-de-las-Flores",
          },
          {
            name: "Concepcion Buenavista",
            distinctId: "Concepcion-Buenavista",
          },
          { name: "Concepcion Papalo", distinctId: "Concepcion-Papalo" },
          {
            name: "Constancia del Rosario",
            distinctId: "Constancia-del-Rosario",
          },
          { name: "Cosolapa", distinctId: "Cosolapa" },
          { name: "Cosoltepec", distinctId: "Cosoltepec" },
          { name: "Cuilapam de Guerrero", distinctId: "Cuilapam-de-Guerrero" },
          {
            name: "Cuna de la Independencia de Oaxaca",
            distinctId: "Cuna-de-la-Independencia-de-Oaxaca",
          },
          {
            name: "Cuyamecalco Villa de Zaragoza",
            distinctId: "Cuyamecalco-Villa-de-Zaragoza",
          },
          {
            name: "El Barrio de la Soledad",
            distinctId: "El-Barrio-de-la-Soledad",
          },
          { name: "El Espinal", distinctId: "El-Espinal" },
          {
            name: "Eloxochitlan de Flores Magon",
            distinctId: "Eloxochitlan-de-Flores-Magon",
          },
          { name: "Fresnillo de Trujano", distinctId: "Fresnillo-de-Trujano" },
          { name: "Guadalupe Etla", distinctId: "Guadalupe-Etla" },
          { name: "Guadalupe de Ramirez", distinctId: "Guadalupe-de-Ramirez" },
          { name: "Guelatao de Juarez", distinctId: "Guelatao-de-Juarez" },
          { name: "Guevea de Humboldt", distinctId: "Guevea-de-Humboldt" },
          {
            name: "Heroica Ciudad de Ejutla de Crespo",
            distinctId: "Heroica-Ciudad-de-Ejutla-de-Crespo",
          },
          {
            name: "Heroica Ciudad de Huajuapan de Leon",
            distinctId: "Heroica-Ciudad-de-Huajuapan-de-Leon",
          },
          {
            name: "Heroica Ciudad de Juchitan de Zaragoza",
            distinctId: "Heroica-Ciudad-de-Juchitan-de-Zaragoza",
          },
          {
            name: "Heroica Ciudad de Tlaxiaco",
            distinctId: "Heroica-Ciudad-de-Tlaxiaco",
          },
          {
            name: "Heroica Villa Tezoatlan de Segura y Luna",
            distinctId: "Heroica-Villa-Tezoatlan-de-Segura-y-Luna",
          },
          { name: "Huautepec", distinctId: "Huautepec" },
          { name: "Huautla de Jimenez", distinctId: "Huautla-de-Jimenez" },
          { name: "Ixpantepec Nieves", distinctId: "Ixpantepec-Nieves" },
          { name: "Ixtlan de Juarez", distinctId: "Ixtlan-de-Juarez" },
          { name: "La Compania", distinctId: "La-Compania" },
          { name: "La Pe", distinctId: "La-Pe" },
          { name: "La Reforma", distinctId: "La-Reforma" },
          {
            name: "La Trinidad Vista Hermosa",
            distinctId: "La-Trinidad-Vista-Hermosa",
          },
          { name: "Loma Bonita", distinctId: "Loma-Bonita" },
          { name: "Magdalena Apasco", distinctId: "Magdalena-Apasco" },
          { name: "Magdalena Jaltepec", distinctId: "Magdalena-Jaltepec" },
          { name: "Magdalena Mixtepec", distinctId: "Magdalena-Mixtepec" },
          { name: "Magdalena Ocotlan", distinctId: "Magdalena-Ocotlan" },
          { name: "Magdalena Penasco", distinctId: "Magdalena-Penasco" },
          { name: "Magdalena Teitipac", distinctId: "Magdalena-Teitipac" },
          {
            name: "Magdalena Tequisistlan",
            distinctId: "Magdalena-Tequisistlan",
          },
          { name: "Magdalena Tlacotepec", distinctId: "Magdalena-Tlacotepec" },
          {
            name: "Magdalena Yodocono de Porfirio Diaz",
            distinctId: "Magdalena-Yodocono-de-Porfirio-Diaz",
          },
          { name: "Magdalena Zahuatlan", distinctId: "Magdalena-Zahuatlan" },
          { name: "Mariscala de Juarez", distinctId: "Mariscala-de-Juarez" },
          { name: "Martires de Tacubaya", distinctId: "Martires-de-Tacubaya" },
          {
            name: "Matias Romero Avendano",
            distinctId: "Matias-Romero-Avendano",
          },
          {
            name: "Mazatlan Villa de Flores",
            distinctId: "Mazatlan-Villa-de-Flores",
          },
          { name: "Mesones Hidalgo", distinctId: "Mesones-Hidalgo" },
          {
            name: "Miahuatlan de Porfirio Diaz",
            distinctId: "Miahuatlan-de-Porfirio-Diaz",
          },
          {
            name: "Mixistlan de la Reforma",
            distinctId: "Mixistlan-de-la-Reforma",
          },
          { name: "Monjas", distinctId: "Monjas" },
          { name: "Natividad", distinctId: "Natividad" },
          { name: "Nazareno Etla", distinctId: "Nazareno-Etla" },
          { name: "Nejapa de Madero", distinctId: "Nejapa-de-Madero" },
          { name: "Nuevo Zoquiapam", distinctId: "Nuevo-Zoquiapam" },
          { name: "Oaxaca de Juarez", distinctId: "Oaxaca-de-Juarez" },
          { name: "Ocotlan de Morelos", distinctId: "Ocotlan-de-Morelos" },
          { name: "Pinotepa de Don Luis", distinctId: "Pinotepa-de-Don-Luis" },
          { name: "Pluma Hidalgo", distinctId: "Pluma-Hidalgo" },
          {
            name: "Putla Villa de Guerrero",
            distinctId: "Putla-Villa-de-Guerrero",
          },
          { name: "Reforma de Pineda", distinctId: "Reforma-de-Pineda" },
          { name: "Reyes Etla", distinctId: "Reyes-Etla" },
          { name: "Rojas de Cuauhtemoc", distinctId: "Rojas-de-Cuauhtemoc" },
          { name: "Salina Cruz", distinctId: "Salina-Cruz" },
          { name: "San Agustin Amatengo", distinctId: "San-Agustin-Amatengo" },
          { name: "San Agustin Atenango", distinctId: "San-Agustin-Atenango" },
          { name: "San Agustin Chayuco", distinctId: "San-Agustin-Chayuco" },
          { name: "San Agustin Etla", distinctId: "San-Agustin-Etla" },
          { name: "San Agustin Loxicha", distinctId: "San-Agustin-Loxicha" },
          {
            name: "San Agustin Tlacotepec",
            distinctId: "San-Agustin-Tlacotepec",
          },
          { name: "San Agustin Yatareni", distinctId: "San-Agustin-Yatareni" },
          {
            name: "San Agustin de las Juntas",
            distinctId: "San-Agustin-de-las-Juntas",
          },
          {
            name: "San Andres Cabecera Nueva",
            distinctId: "San-Andres-Cabecera-Nueva",
          },
          { name: "San Andres Dinicuiti", distinctId: "San-Andres-Dinicuiti" },
          {
            name: "San Andres Huaxpaltepec",
            distinctId: "San-Andres-Huaxpaltepec",
          },
          { name: "San Andres Huayapam", distinctId: "San-Andres-Huayapam" },
          {
            name: "San Andres Ixtlahuaca",
            distinctId: "San-Andres-Ixtlahuaca",
          },
          { name: "San Andres Lagunas", distinctId: "San-Andres-Lagunas" },
          { name: "San Andres Nuxino", distinctId: "San-Andres-Nuxino" },
          { name: "San Andres Paxtlan", distinctId: "San-Andres-Paxtlan" },
          { name: "San Andres Sinaxtla", distinctId: "San-Andres-Sinaxtla" },
          { name: "San Andres Solaga", distinctId: "San-Andres-Solaga" },
          {
            name: "San Andres Teotilalpam",
            distinctId: "San-Andres-Teotilalpam",
          },
          { name: "San Andres Tepetlapa", distinctId: "San-Andres-Tepetlapa" },
          { name: "San Andres Yaa", distinctId: "San-Andres-Yaa" },
          { name: "San Andres Zabache", distinctId: "San-Andres-Zabache" },
          { name: "San Andres Zautla", distinctId: "San-Andres-Zautla" },
          {
            name: "San Antonino Castillo Velasco",
            distinctId: "San-Antonino-Castillo-Velasco",
          },
          {
            name: "San Antonino Monte Verde",
            distinctId: "San-Antonino-Monte-Verde",
          },
          { name: "San Antonino el Alto", distinctId: "San-Antonino-el-Alto" },
          { name: "San Antonio Acutla", distinctId: "San-Antonio-Acutla" },
          { name: "San Antonio Huitepec", distinctId: "San-Antonio-Huitepec" },
          {
            name: "San Antonio Nanahuatipam",
            distinctId: "San-Antonio-Nanahuatipam",
          },
          {
            name: "San Antonio Sinicahua",
            distinctId: "San-Antonio-Sinicahua",
          },
          {
            name: "San Antonio Tepetlapa",
            distinctId: "San-Antonio-Tepetlapa",
          },
          {
            name: "San Antonio de la Cal",
            distinctId: "San-Antonio-de-la-Cal",
          },
          {
            name: "San Baltazar Chichicapam",
            distinctId: "San-Baltazar-Chichicapam",
          },
          { name: "San Baltazar Loxicha", distinctId: "San-Baltazar-Loxicha" },
          {
            name: "San Baltazar Yatzachi el Bajo",
            distinctId: "San-Baltazar-Yatzachi-el-Bajo",
          },
          {
            name: "San Bartolo Coyotepec",
            distinctId: "San-Bartolo-Coyotepec",
          },
          {
            name: "San Bartolo Soyaltepec",
            distinctId: "San-Bartolo-Soyaltepec",
          },
          { name: "San Bartolo Yautepec", distinctId: "San-Bartolo-Yautepec" },
          {
            name: "San Bartolome Ayautla",
            distinctId: "San-Bartolome-Ayautla",
          },
          {
            name: "San Bartolome Loxicha",
            distinctId: "San-Bartolome-Loxicha",
          },
          {
            name: "San Bartolome Quialana",
            distinctId: "San-Bartolome-Quialana",
          },
          {
            name: "San Bartolome Yucuane",
            distinctId: "San-Bartolome-Yucuane",
          },
          {
            name: "San Bartolome Zoogocho",
            distinctId: "San-Bartolome-Zoogocho",
          },
          {
            name: "San Bernardo Mixtepec",
            distinctId: "San-Bernardo-Mixtepec",
          },
          { name: "San Blas Atempa", distinctId: "San-Blas-Atempa" },
          { name: "San Carlos Yautepec", distinctId: "San-Carlos-Yautepec" },
          {
            name: "San Cristobal Amatlan",
            distinctId: "San-Cristobal-Amatlan",
          },
          {
            name: "San Cristobal Amoltepec",
            distinctId: "San-Cristobal-Amoltepec",
          },
          {
            name: "San Cristobal Lachirioag",
            distinctId: "San-Cristobal-Lachirioag",
          },
          {
            name: "San Cristobal Suchixtlahuaca",
            distinctId: "San-Cristobal-Suchixtlahuaca",
          },
          {
            name: "San Dionisio Ocotepec",
            distinctId: "San-Dionisio-Ocotepec",
          },
          { name: "San Dionisio Ocotlan", distinctId: "San-Dionisio-Ocotlan" },
          { name: "San Dionisio del Mar", distinctId: "San-Dionisio-del-Mar" },
          {
            name: "San Esteban Atatlahuca",
            distinctId: "San-Esteban-Atatlahuca",
          },
          {
            name: "San Felipe Jalapa de Diaz",
            distinctId: "San-Felipe-Jalapa-de-Diaz",
          },
          { name: "San Felipe Tejalapam", distinctId: "San-Felipe-Tejalapam" },
          { name: "San Felipe Usila", distinctId: "San-Felipe-Usila" },
          {
            name: "San Francisco Cahuacua",
            distinctId: "San-Francisco-Cahuacua",
          },
          {
            name: "San Francisco Cajonos",
            distinctId: "San-Francisco-Cajonos",
          },
          {
            name: "San Francisco Chapulapa",
            distinctId: "San-Francisco-Chapulapa",
          },
          {
            name: "San Francisco Chindua",
            distinctId: "San-Francisco-Chindua",
          },
          {
            name: "San Francisco Huehuetlan",
            distinctId: "San-Francisco-Huehuetlan",
          },
          {
            name: "San Francisco Ixhuatan",
            distinctId: "San-Francisco-Ixhuatan",
          },
          {
            name: "San Francisco Jaltepetongo",
            distinctId: "San-Francisco-Jaltepetongo",
          },
          {
            name: "San Francisco Lachigolo",
            distinctId: "San-Francisco-Lachigolo",
          },
          {
            name: "San Francisco Logueche",
            distinctId: "San-Francisco-Logueche",
          },
          { name: "San Francisco Nuxano", distinctId: "San-Francisco-Nuxano" },
          {
            name: "San Francisco Ozolotepec",
            distinctId: "San-Francisco-Ozolotepec",
          },
          { name: "San Francisco Sola", distinctId: "San-Francisco-Sola" },
          {
            name: "San Francisco Telixtlahuaca",
            distinctId: "San-Francisco-Telixtlahuaca",
          },
          { name: "San Francisco Teopan", distinctId: "San-Francisco-Teopan" },
          {
            name: "San Francisco Tlapancingo",
            distinctId: "San-Francisco-Tlapancingo",
          },
          {
            name: "San Francisco del Mar",
            distinctId: "San-Francisco-del-Mar",
          },
          { name: "San Gabriel Mixtepec", distinctId: "San-Gabriel-Mixtepec" },
          {
            name: "San Ildefonso Amatlan",
            distinctId: "San-Ildefonso-Amatlan",
          },
          { name: "San Ildefonso Sola", distinctId: "San-Ildefonso-Sola" },
          {
            name: "San Ildefonso Villa Alta",
            distinctId: "San-Ildefonso-Villa-Alta",
          },
          { name: "San Jacinto Amilpas", distinctId: "San-Jacinto-Amilpas" },
          {
            name: "San Jacinto Tlacotepec",
            distinctId: "San-Jacinto-Tlacotepec",
          },
          { name: "San Jeronimo Coatlan", distinctId: "San-Jeronimo-Coatlan" },
          {
            name: "San Jeronimo Silacayoapilla",
            distinctId: "San-Jeronimo-Silacayoapilla",
          },
          { name: "San Jeronimo Sosola", distinctId: "San-Jeronimo-Sosola" },
          { name: "San Jeronimo Taviche", distinctId: "San-Jeronimo-Taviche" },
          { name: "San Jeronimo Tecoatl", distinctId: "San-Jeronimo-Tecoatl" },
          {
            name: "San Jeronimo Tlacochahuaya",
            distinctId: "San-Jeronimo-Tlacochahuaya",
          },
          { name: "San Jorge Nuchita", distinctId: "San-Jorge-Nuchita" },
          { name: "San Jose Ayuquila", distinctId: "San-Jose-Ayuquila" },
          { name: "San Jose Chiltepec", distinctId: "San-Jose-Chiltepec" },
          {
            name: "San Jose Estancia Grande",
            distinctId: "San-Jose-Estancia-Grande",
          },
          {
            name: "San Jose Independencia",
            distinctId: "San-Jose-Independencia",
          },
          { name: "San Jose Lachiguiri", distinctId: "San-Jose-Lachiguiri" },
          { name: "San Jose Tenango", distinctId: "San-Jose-Tenango" },
          { name: "San Jose del Penasco", distinctId: "San-Jose-del-Penasco" },
          {
            name: "San Jose del Progreso",
            distinctId: "San-Jose-del-Progreso",
          },
          { name: "San Juan Achiutla", distinctId: "San-Juan-Achiutla" },
          { name: "San Juan Atepec", distinctId: "San-Juan-Atepec" },
          {
            name: "San Juan Bautista Atatlahuca",
            distinctId: "San-Juan-Bautista-Atatlahuca",
          },
          {
            name: "San Juan Bautista Coixtlahuaca",
            distinctId: "San-Juan-Bautista-Coixtlahuaca",
          },
          {
            name: "San Juan Bautista Cuicatlan",
            distinctId: "San-Juan-Bautista-Cuicatlan",
          },
          {
            name: "San Juan Bautista Guelache",
            distinctId: "San-Juan-Bautista-Guelache",
          },
          {
            name: "San Juan Bautista Jayacatlan",
            distinctId: "San-Juan-Bautista-Jayacatlan",
          },
          {
            name: "San Juan Bautista Lo de Soto",
            distinctId: "San-Juan-Bautista-Lo-de-Soto",
          },
          {
            name: "San Juan Bautista Suchitepec",
            distinctId: "San-Juan-Bautista-Suchitepec",
          },
          {
            name: "San Juan Bautista Tlachichilco",
            distinctId: "San-Juan-Bautista-Tlachichilco",
          },
          {
            name: "San Juan Bautista Tlacoatzintepec",
            distinctId: "San-Juan-Bautista-Tlacoatzintepec",
          },
          {
            name: "San Juan Bautista Tuxtepec",
            distinctId: "San-Juan-Bautista-Tuxtepec",
          },
          {
            name: "San Juan Bautista Valle Nacional",
            distinctId: "San-Juan-Bautista-Valle-Nacional",
          },
          {
            name: "San Juan Cacahuatepec",
            distinctId: "San-Juan-Cacahuatepec",
          },
          {
            name: "San Juan Chicomezuchil",
            distinctId: "San-Juan-Chicomezuchil",
          },
          { name: "San Juan Chilateca", distinctId: "San-Juan-Chilateca" },
          { name: "San Juan Cieneguilla", distinctId: "San-Juan-Cieneguilla" },
          { name: "San Juan Coatzospam", distinctId: "San-Juan-Coatzospam" },
          { name: "San Juan Colorado", distinctId: "San-Juan-Colorado" },
          { name: "San Juan Comaltepec", distinctId: "San-Juan-Comaltepec" },
          { name: "San Juan Cotzocon", distinctId: "San-Juan-Cotzocon" },
          { name: "San Juan Diuxi", distinctId: "San-Juan-Diuxi" },
          {
            name: "San Juan Evangelista Analco",
            distinctId: "San-Juan-Evangelista-Analco",
          },
          { name: "San Juan Guelavia", distinctId: "San-Juan-Guelavia" },
          { name: "San Juan Guichicovi", distinctId: "San-Juan-Guichicovi" },
          { name: "San Juan Ihualtepec", distinctId: "San-Juan-Ihualtepec" },
          {
            name: "San Juan Juquila Mixes",
            distinctId: "San-Juan-Juquila-Mixes",
          },
          {
            name: "San Juan Juquila Vijanos",
            distinctId: "San-Juan-Juquila-Vijanos",
          },
          { name: "San Juan Lachao", distinctId: "San-Juan-Lachao" },
          { name: "San Juan Lachigalla", distinctId: "San-Juan-Lachigalla" },
          { name: "San Juan Lajarcia", distinctId: "San-Juan-Lajarcia" },
          { name: "San Juan Lalana", distinctId: "San-Juan-Lalana" },
          { name: "San Juan Mazatlan", distinctId: "San-Juan-Mazatlan" },
          { name: "San Juan Mixtepec", distinctId: "San-Juan-Mixtepec" },
          { name: "San Juan Numi", distinctId: "San-Juan-Numi" },
          { name: "San Juan Ozolotepec", distinctId: "San-Juan-Ozolotepec" },
          { name: "San Juan Petlapa", distinctId: "San-Juan-Petlapa" },
          { name: "San Juan Quiahije", distinctId: "San-Juan-Quiahije" },
          { name: "San Juan Quiotepec", distinctId: "San-Juan-Quiotepec" },
          { name: "San Juan Sayultepec", distinctId: "San-Juan-Sayultepec" },
          { name: "San Juan Tabaa", distinctId: "San-Juan-Tabaa" },
          { name: "San Juan Tamazola", distinctId: "San-Juan-Tamazola" },
          { name: "San Juan Teita", distinctId: "San-Juan-Teita" },
          { name: "San Juan Teitipac", distinctId: "San-Juan-Teitipac" },
          { name: "San Juan Tepeuxila", distinctId: "San-Juan-Tepeuxila" },
          { name: "San Juan Teposcolula", distinctId: "San-Juan-Teposcolula" },
          { name: "San Juan Yaee", distinctId: "San-Juan-Yaee" },
          { name: "San Juan Yatzona", distinctId: "San-Juan-Yatzona" },
          { name: "San Juan Yucuita", distinctId: "San-Juan-Yucuita" },
          { name: "San Juan de los Cues", distinctId: "San-Juan-de-los-Cues" },
          { name: "San Juan del Estado", distinctId: "San-Juan-del-Estado" },
          { name: "San Juan del Rio", distinctId: "San-Juan-del-Rio" },
          {
            name: "San Lorenzo Albarradas",
            distinctId: "San-Lorenzo-Albarradas",
          },
          {
            name: "San Lorenzo Cacaotepec",
            distinctId: "San-Lorenzo-Cacaotepec",
          },
          {
            name: "San Lorenzo Cuaunecuiltitla",
            distinctId: "San-Lorenzo-Cuaunecuiltitla",
          },
          {
            name: "San Lorenzo Texmelucan",
            distinctId: "San-Lorenzo-Texmelucan",
          },
          { name: "San Lorenzo Victoria", distinctId: "San-Lorenzo-Victoria" },
          { name: "San Lorenzo", distinctId: "San-Lorenzo" },
          { name: "San Lucas Camotlan", distinctId: "San-Lucas-Camotlan" },
          { name: "San Lucas Ojitlan", distinctId: "San-Lucas-Ojitlan" },
          { name: "San Lucas Quiavini", distinctId: "San-Lucas-Quiavini" },
          { name: "San Lucas Zoquiapam", distinctId: "San-Lucas-Zoquiapam" },
          { name: "San Luis Amatlan", distinctId: "San-Luis-Amatlan" },
          {
            name: "San Marcial Ozolotepec",
            distinctId: "San-Marcial-Ozolotepec",
          },
          { name: "San Marcos Arteaga", distinctId: "San-Marcos-Arteaga" },
          {
            name: "San Martin Huamelulpam",
            distinctId: "San-Martin-Huamelulpam",
          },
          { name: "San Martin Itunyoso", distinctId: "San-Martin-Itunyoso" },
          { name: "San Martin Lachila", distinctId: "San-Martin-Lachila" },
          { name: "San Martin Peras", distinctId: "San-Martin-Peras" },
          { name: "San Martin Tilcajete", distinctId: "San-Martin-Tilcajete" },
          { name: "San Martin Toxpalan", distinctId: "San-Martin-Toxpalan" },
          { name: "San Martin Zacatepec", distinctId: "San-Martin-Zacatepec" },
          {
            name: "San Martin de los Cansecos",
            distinctId: "San-Martin-de-los-Cansecos",
          },
          { name: "San Mateo Cajonos", distinctId: "San-Mateo-Cajonos" },
          { name: "San Mateo Etlatongo", distinctId: "San-Mateo-Etlatongo" },
          { name: "San Mateo Nejapam", distinctId: "San-Mateo-Nejapam" },
          { name: "San Mateo Penasco", distinctId: "San-Mateo-Penasco" },
          { name: "San Mateo Pinas", distinctId: "San-Mateo-Pinas" },
          { name: "San Mateo Rio Hondo", distinctId: "San-Mateo-Rio-Hondo" },
          { name: "San Mateo Sindihui", distinctId: "San-Mateo-Sindihui" },
          {
            name: "San Mateo Tlapiltepec",
            distinctId: "San-Mateo-Tlapiltepec",
          },
          {
            name: "San Mateo Yoloxochitlan",
            distinctId: "San-Mateo-Yoloxochitlan",
          },
          { name: "San Mateo Yucutindoo", distinctId: "San-Mateo-Yucutindoo" },
          { name: "San Mateo del Mar", distinctId: "San-Mateo-del-Mar" },
          { name: "San Melchor Betaza", distinctId: "San-Melchor-Betaza" },
          { name: "San Miguel Achiutla", distinctId: "San-Miguel-Achiutla" },
          {
            name: "San Miguel Ahuehuetitlan",
            distinctId: "San-Miguel-Ahuehuetitlan",
          },
          { name: "San Miguel Aloapam", distinctId: "San-Miguel-Aloapam" },
          { name: "San Miguel Amatitlan", distinctId: "San-Miguel-Amatitlan" },
          { name: "San Miguel Amatlan", distinctId: "San-Miguel-Amatlan" },
          { name: "San Miguel Chicahua", distinctId: "San-Miguel-Chicahua" },
          { name: "San Miguel Chimalapa", distinctId: "San-Miguel-Chimalapa" },
          { name: "San Miguel Coatlan", distinctId: "San-Miguel-Coatlan" },
          { name: "San Miguel Ejutla", distinctId: "San-Miguel-Ejutla" },
          { name: "San Miguel Huautla", distinctId: "San-Miguel-Huautla" },
          { name: "San Miguel Mixtepec", distinctId: "San-Miguel-Mixtepec" },
          {
            name: "San Miguel Panixtlahuaca",
            distinctId: "San-Miguel-Panixtlahuaca",
          },
          { name: "San Miguel Peras", distinctId: "San-Miguel-Peras" },
          { name: "San Miguel Piedras", distinctId: "San-Miguel-Piedras" },
          {
            name: "San Miguel Quetzaltepec",
            distinctId: "San-Miguel-Quetzaltepec",
          },
          {
            name: "San Miguel Santa Flor",
            distinctId: "San-Miguel-Santa-Flor",
          },
          {
            name: "San Miguel Soyaltepec",
            distinctId: "San-Miguel-Soyaltepec",
          },
          {
            name: "San Miguel Suchixtepec",
            distinctId: "San-Miguel-Suchixtepec",
          },
          {
            name: "San Miguel Tecomatlan",
            distinctId: "San-Miguel-Tecomatlan",
          },
          { name: "San Miguel Tenango", distinctId: "San-Miguel-Tenango" },
          {
            name: "San Miguel Tequixtepec",
            distinctId: "San-Miguel-Tequixtepec",
          },
          {
            name: "San Miguel Tilquiapam",
            distinctId: "San-Miguel-Tilquiapam",
          },
          { name: "San Miguel Tlacamama", distinctId: "San-Miguel-Tlacamama" },
          {
            name: "San Miguel Tlacotepec",
            distinctId: "San-Miguel-Tlacotepec",
          },
          {
            name: "San Miguel Tulancingo",
            distinctId: "San-Miguel-Tulancingo",
          },
          { name: "San Miguel Yotao", distinctId: "San-Miguel-Yotao" },
          {
            name: "San Miguel del Puerto",
            distinctId: "San-Miguel-del-Puerto",
          },
          { name: "San Miguel del Rio", distinctId: "San-Miguel-del-Rio" },
          { name: "San Miguel el Grande", distinctId: "San-Miguel-el-Grande" },
          { name: "San Nicolas Hidalgo", distinctId: "San-Nicolas-Hidalgo" },
          { name: "San Nicolas", distinctId: "San-Nicolas" },
          { name: "San Pablo Coatlan", distinctId: "San-Pablo-Coatlan" },
          {
            name: "San Pablo Cuatro Venados",
            distinctId: "San-Pablo-Cuatro-Venados",
          },
          { name: "San Pablo Etla", distinctId: "San-Pablo-Etla" },
          { name: "San Pablo Huitzo", distinctId: "San-Pablo-Huitzo" },
          { name: "San Pablo Huixtepec", distinctId: "San-Pablo-Huixtepec" },
          {
            name: "San Pablo Macuiltianguis",
            distinctId: "San-Pablo-Macuiltianguis",
          },
          { name: "San Pablo Tijaltepec", distinctId: "San-Pablo-Tijaltepec" },
          {
            name: "San Pablo Villa de Mitla",
            distinctId: "San-Pablo-Villa-de-Mitla",
          },
          { name: "San Pablo Yaganiza", distinctId: "San-Pablo-Yaganiza" },
          { name: "San Pedro Amuzgos", distinctId: "San-Pedro-Amuzgos" },
          { name: "San Pedro Apostol", distinctId: "San-Pedro-Apostol" },
          { name: "San Pedro Atoyac", distinctId: "San-Pedro-Atoyac" },
          { name: "San Pedro Cajonos", distinctId: "San-Pedro-Cajonos" },
          {
            name: "San Pedro Comitancillo",
            distinctId: "San-Pedro-Comitancillo",
          },
          {
            name: "San Pedro Coxcaltepec Cantaros",
            distinctId: "San-Pedro-Coxcaltepec-Cantaros",
          },
          { name: "San Pedro Huamelula", distinctId: "San-Pedro-Huamelula" },
          { name: "San Pedro Huilotepec", distinctId: "San-Pedro-Huilotepec" },
          { name: "San Pedro Ixcatlan", distinctId: "San-Pedro-Ixcatlan" },
          { name: "San Pedro Ixtlahuaca", distinctId: "San-Pedro-Ixtlahuaca" },
          {
            name: "San Pedro Jaltepetongo",
            distinctId: "San-Pedro-Jaltepetongo",
          },
          { name: "San Pedro Jicayan", distinctId: "San-Pedro-Jicayan" },
          { name: "San Pedro Jocotipac", distinctId: "San-Pedro-Jocotipac" },
          { name: "San Pedro Juchatengo", distinctId: "San-Pedro-Juchatengo" },
          {
            name: "San Pedro Martir Quiechapa",
            distinctId: "San-Pedro-Martir-Quiechapa",
          },
          {
            name: "San Pedro Martir Yucuxaco",
            distinctId: "San-Pedro-Martir-Yucuxaco",
          },
          { name: "San Pedro Martir", distinctId: "San-Pedro-Martir" },
          { name: "San Pedro Mixtepec", distinctId: "San-Pedro-Mixtepec" },
          { name: "San Pedro Molinos", distinctId: "San-Pedro-Molinos" },
          { name: "San Pedro Nopala", distinctId: "San-Pedro-Nopala" },
          {
            name: "San Pedro Ocopetatillo",
            distinctId: "San-Pedro-Ocopetatillo",
          },
          { name: "San Pedro Ocotepec", distinctId: "San-Pedro-Ocotepec" },
          { name: "San Pedro Pochutla", distinctId: "San-Pedro-Pochutla" },
          { name: "San Pedro Quiatoni", distinctId: "San-Pedro-Quiatoni" },
          { name: "San Pedro Sochiapam", distinctId: "San-Pedro-Sochiapam" },
          {
            name: "San Pedro Tapanatepec",
            distinctId: "San-Pedro-Tapanatepec",
          },
          { name: "San Pedro Taviche", distinctId: "San-Pedro-Taviche" },
          {
            name: "San Pedro Teozacoalco",
            distinctId: "San-Pedro-Teozacoalco",
          },
          { name: "San Pedro Teutila", distinctId: "San-Pedro-Teutila" },
          { name: "San Pedro Tidaa", distinctId: "San-Pedro-Tidaa" },
          { name: "San Pedro Topiltepec", distinctId: "San-Pedro-Topiltepec" },
          { name: "San Pedro Totolapam", distinctId: "San-Pedro-Totolapam" },
          { name: "San Pedro Yaneri", distinctId: "San-Pedro-Yaneri" },
          { name: "San Pedro Yolox", distinctId: "San-Pedro-Yolox" },
          { name: "San Pedro Yucunama", distinctId: "San-Pedro-Yucunama" },
          { name: "San Pedro el Alto", distinctId: "San-Pedro-el-Alto" },
          {
            name: "San Pedro y San Pablo Ayutla",
            distinctId: "San-Pedro-y-San-Pablo-Ayutla",
          },
          {
            name: "San Pedro y San Pablo Teposcolula",
            distinctId: "San-Pedro-y-San-Pablo-Teposcolula",
          },
          {
            name: "San Pedro y San Pablo Tequixtepec",
            distinctId: "San-Pedro-y-San-Pablo-Tequixtepec",
          },
          { name: "San Raymundo Jalpan", distinctId: "San-Raymundo-Jalpan" },
          {
            name: "San Sebastian Abasolo",
            distinctId: "San-Sebastian-Abasolo",
          },
          {
            name: "San Sebastian Coatlan",
            distinctId: "San-Sebastian-Coatlan",
          },
          { name: "San Sebastian Ixcapa", distinctId: "San-Sebastian-Ixcapa" },
          {
            name: "San Sebastian Nicananduta",
            distinctId: "San-Sebastian-Nicananduta",
          },
          {
            name: "San Sebastian Rio Hondo",
            distinctId: "San-Sebastian-Rio-Hondo",
          },
          {
            name: "San Sebastian Tecomaxtlahuaca",
            distinctId: "San-Sebastian-Tecomaxtlahuaca",
          },
          {
            name: "San Sebastian Teitipac",
            distinctId: "San-Sebastian-Teitipac",
          },
          { name: "San Sebastian Tutla", distinctId: "San-Sebastian-Tutla" },
          { name: "San Simon Almolongas", distinctId: "San-Simon-Almolongas" },
          { name: "San Simon Zahuatlan", distinctId: "San-Simon-Zahuatlan" },
          { name: "San Vicente Coatlan", distinctId: "San-Vicente-Coatlan" },
          { name: "San Vicente Lachixio", distinctId: "San-Vicente-Lachixio" },
          { name: "San Vicente Nunu", distinctId: "San-Vicente-Nunu" },
          {
            name: "Santa Ana Ateixtlahuaca",
            distinctId: "Santa-Ana-Ateixtlahuaca",
          },
          { name: "Santa Ana Cuauhtemoc", distinctId: "Santa-Ana-Cuauhtemoc" },
          { name: "Santa Ana Tavela", distinctId: "Santa-Ana-Tavela" },
          { name: "Santa Ana Tlapacoyan", distinctId: "Santa-Ana-Tlapacoyan" },
          { name: "Santa Ana Yareni", distinctId: "Santa-Ana-Yareni" },
          { name: "Santa Ana Zegache", distinctId: "Santa-Ana-Zegache" },
          { name: "Santa Ana del Valle", distinctId: "Santa-Ana-del-Valle" },
          { name: "Santa Ana", distinctId: "Santa-Ana" },
          {
            name: "Santa Catalina Quieri",
            distinctId: "Santa-Catalina-Quieri",
          },
          {
            name: "Santa Catarina Cuixtla",
            distinctId: "Santa-Catarina-Cuixtla",
          },
          {
            name: "Santa Catarina Ixtepeji",
            distinctId: "Santa-Catarina-Ixtepeji",
          },
          {
            name: "Santa Catarina Juquila",
            distinctId: "Santa-Catarina-Juquila",
          },
          {
            name: "Santa Catarina Lachatao",
            distinctId: "Santa-Catarina-Lachatao",
          },
          {
            name: "Santa Catarina Loxicha",
            distinctId: "Santa-Catarina-Loxicha",
          },
          {
            name: "Santa Catarina Mechoacan",
            distinctId: "Santa-Catarina-Mechoacan",
          },
          { name: "Santa Catarina Minas", distinctId: "Santa-Catarina-Minas" },
          {
            name: "Santa Catarina Quiane",
            distinctId: "Santa-Catarina-Quiane",
          },
          {
            name: "Santa Catarina Quioquitani",
            distinctId: "Santa-Catarina-Quioquitani",
          },
          {
            name: "Santa Catarina Tayata",
            distinctId: "Santa-Catarina-Tayata",
          },
          { name: "Santa Catarina Ticua", distinctId: "Santa-Catarina-Ticua" },
          {
            name: "Santa Catarina Yosonotu",
            distinctId: "Santa-Catarina-Yosonotu",
          },
          {
            name: "Santa Catarina Zapoquila",
            distinctId: "Santa-Catarina-Zapoquila",
          },
          { name: "Santa Cruz Acatepec", distinctId: "Santa-Cruz-Acatepec" },
          { name: "Santa Cruz Amilpas", distinctId: "Santa-Cruz-Amilpas" },
          { name: "Santa Cruz Itundujia", distinctId: "Santa-Cruz-Itundujia" },
          { name: "Santa Cruz Mixtepec", distinctId: "Santa-Cruz-Mixtepec" },
          { name: "Santa Cruz Nundaco", distinctId: "Santa-Cruz-Nundaco" },
          { name: "Santa Cruz Papalutla", distinctId: "Santa-Cruz-Papalutla" },
          {
            name: "Santa Cruz Tacache de Mina",
            distinctId: "Santa-Cruz-Tacache-de-Mina",
          },
          { name: "Santa Cruz Tacahua", distinctId: "Santa-Cruz-Tacahua" },
          { name: "Santa Cruz Tayata", distinctId: "Santa-Cruz-Tayata" },
          { name: "Santa Cruz Xitla", distinctId: "Santa-Cruz-Xitla" },
          {
            name: "Santa Cruz Xoxocotlan",
            distinctId: "Santa-Cruz-Xoxocotlan",
          },
          {
            name: "Santa Cruz Zenzontepec",
            distinctId: "Santa-Cruz-Zenzontepec",
          },
          { name: "Santa Cruz de Bravo", distinctId: "Santa-Cruz-de-Bravo" },
          { name: "Santa Gertrudis", distinctId: "Santa-Gertrudis" },
          { name: "Santa Ines Yatzeche", distinctId: "Santa-Ines-Yatzeche" },
          {
            name: "Santa Ines de Zaragoza",
            distinctId: "Santa-Ines-de-Zaragoza",
          },
          { name: "Santa Ines del Monte", distinctId: "Santa-Ines-del-Monte" },
          {
            name: "Santa Lucia Miahuatlan",
            distinctId: "Santa-Lucia-Miahuatlan",
          },
          {
            name: "Santa Lucia Monteverde",
            distinctId: "Santa-Lucia-Monteverde",
          },
          { name: "Santa Lucia Ocotlan", distinctId: "Santa-Lucia-Ocotlan" },
          {
            name: "Santa Lucia del Camino",
            distinctId: "Santa-Lucia-del-Camino",
          },
          {
            name: "Santa Magdalena Jicotlan",
            distinctId: "Santa-Magdalena-Jicotlan",
          },
          { name: "Santa Maria Alotepec", distinctId: "Santa-Maria-Alotepec" },
          { name: "Santa Maria Apazco", distinctId: "Santa-Maria-Apazco" },
          { name: "Santa Maria Atzompa", distinctId: "Santa-Maria-Atzompa" },
          { name: "Santa Maria Camotlan", distinctId: "Santa-Maria-Camotlan" },
          {
            name: "Santa Maria Chachoapam",
            distinctId: "Santa-Maria-Chachoapam",
          },
          {
            name: "Santa Maria Chilchotla",
            distinctId: "Santa-Maria-Chilchotla",
          },
          {
            name: "Santa Maria Chimalapa",
            distinctId: "Santa-Maria-Chimalapa",
          },
          {
            name: "Santa Maria Colotepec",
            distinctId: "Santa-Maria-Colotepec",
          },
          { name: "Santa Maria Cortijo", distinctId: "Santa-Maria-Cortijo" },
          {
            name: "Santa Maria Coyotepec",
            distinctId: "Santa-Maria-Coyotepec",
          },
          { name: "Santa Maria Ecatepec", distinctId: "Santa-Maria-Ecatepec" },
          { name: "Santa Maria Guelace", distinctId: "Santa-Maria-Guelace" },
          {
            name: "Santa Maria Guienagati",
            distinctId: "Santa-Maria-Guienagati",
          },
          { name: "Santa Maria Huatulco", distinctId: "Santa-Maria-Huatulco" },
          {
            name: "Santa Maria Huazolotitlan",
            distinctId: "Santa-Maria-Huazolotitlan",
          },
          { name: "Santa Maria Ipalapa", distinctId: "Santa-Maria-Ipalapa" },
          { name: "Santa Maria Ixcatlan", distinctId: "Santa-Maria-Ixcatlan" },
          {
            name: "Santa Maria Jacatepec",
            distinctId: "Santa-Maria-Jacatepec",
          },
          {
            name: "Santa Maria Jalapa del Marques",
            distinctId: "Santa-Maria-Jalapa-del-Marques",
          },
          {
            name: "Santa Maria Jaltianguis",
            distinctId: "Santa-Maria-Jaltianguis",
          },
          { name: "Santa Maria Lachixio", distinctId: "Santa-Maria-Lachixio" },
          {
            name: "Santa Maria Mixtequilla",
            distinctId: "Santa-Maria-Mixtequilla",
          },
          {
            name: "Santa Maria Nativitas",
            distinctId: "Santa-Maria-Nativitas",
          },
          { name: "Santa Maria Nduayaco", distinctId: "Santa-Maria-Nduayaco" },
          {
            name: "Santa Maria Ozolotepec",
            distinctId: "Santa-Maria-Ozolotepec",
          },
          { name: "Santa Maria Papalo", distinctId: "Santa-Maria-Papalo" },
          { name: "Santa Maria Penoles", distinctId: "Santa-Maria-Penoles" },
          { name: "Santa Maria Petapa", distinctId: "Santa-Maria-Petapa" },
          {
            name: "Santa Maria Quiegolani",
            distinctId: "Santa-Maria-Quiegolani",
          },
          { name: "Santa Maria Sola", distinctId: "Santa-Maria-Sola" },
          {
            name: "Santa Maria Tataltepec",
            distinctId: "Santa-Maria-Tataltepec",
          },
          {
            name: "Santa Maria Tecomavaca",
            distinctId: "Santa-Maria-Tecomavaca",
          },
          {
            name: "Santa Maria Temaxcalapa",
            distinctId: "Santa-Maria-Temaxcalapa",
          },
          {
            name: "Santa Maria Temaxcaltepec",
            distinctId: "Santa-Maria-Temaxcaltepec",
          },
          { name: "Santa Maria Teopoxco", distinctId: "Santa-Maria-Teopoxco" },
          {
            name: "Santa Maria Tepantlali",
            distinctId: "Santa-Maria-Tepantlali",
          },
          {
            name: "Santa Maria Texcatitlan",
            distinctId: "Santa-Maria-Texcatitlan",
          },
          {
            name: "Santa Maria Tlahuitoltepec",
            distinctId: "Santa-Maria-Tlahuitoltepec",
          },
          {
            name: "Santa Maria Tlalixtac",
            distinctId: "Santa-Maria-Tlalixtac",
          },
          { name: "Santa Maria Tonameca", distinctId: "Santa-Maria-Tonameca" },
          {
            name: "Santa Maria Totolapilla",
            distinctId: "Santa-Maria-Totolapilla",
          },
          { name: "Santa Maria Xadani", distinctId: "Santa-Maria-Xadani" },
          { name: "Santa Maria Yalina", distinctId: "Santa-Maria-Yalina" },
          { name: "Santa Maria Yavesia", distinctId: "Santa-Maria-Yavesia" },
          {
            name: "Santa Maria Yolotepec",
            distinctId: "Santa-Maria-Yolotepec",
          },
          { name: "Santa Maria Yosoyua", distinctId: "Santa-Maria-Yosoyua" },
          { name: "Santa Maria Yucuhiti", distinctId: "Santa-Maria-Yucuhiti" },
          {
            name: "Santa Maria Zacatepec",
            distinctId: "Santa-Maria-Zacatepec",
          },
          { name: "Santa Maria Zaniza", distinctId: "Santa-Maria-Zaniza" },
          {
            name: "Santa Maria Zoquitlan",
            distinctId: "Santa-Maria-Zoquitlan",
          },
          {
            name: "Santa Maria del Rosario",
            distinctId: "Santa-Maria-del-Rosario",
          },
          { name: "Santa Maria del Tule", distinctId: "Santa-Maria-del-Tule" },
          {
            name: "Santa Maria la Asuncion",
            distinctId: "Santa-Maria-la-Asuncion",
          },
          { name: "Santiago Amoltepec", distinctId: "Santiago-Amoltepec" },
          { name: "Santiago Apoala", distinctId: "Santiago-Apoala" },
          { name: "Santiago Apostol", distinctId: "Santiago-Apostol" },
          { name: "Santiago Astata", distinctId: "Santiago-Astata" },
          { name: "Santiago Atitlan", distinctId: "Santiago-Atitlan" },
          { name: "Santiago Ayuquililla", distinctId: "Santiago-Ayuquililla" },
          {
            name: "Santiago Cacaloxtepec",
            distinctId: "Santiago-Cacaloxtepec",
          },
          { name: "Santiago Camotlan", distinctId: "Santiago-Camotlan" },
          { name: "Santiago Chazumba", distinctId: "Santiago-Chazumba" },
          { name: "Santiago Choapam", distinctId: "Santiago-Choapam" },
          { name: "Santiago Comaltepec", distinctId: "Santiago-Comaltepec" },
          {
            name: "Santiago Huajolotitlan",
            distinctId: "Santiago-Huajolotitlan",
          },
          { name: "Santiago Huauclilla", distinctId: "Santiago-Huauclilla" },
          {
            name: "Santiago Ihuitlan Plumas",
            distinctId: "Santiago-Ihuitlan-Plumas",
          },
          { name: "Santiago Ixcuintepec", distinctId: "Santiago-Ixcuintepec" },
          { name: "Santiago Ixtayutla", distinctId: "Santiago-Ixtayutla" },
          { name: "Santiago Jamiltepec", distinctId: "Santiago-Jamiltepec" },
          { name: "Santiago Jocotepec", distinctId: "Santiago-Jocotepec" },
          { name: "Santiago Juxtlahuaca", distinctId: "Santiago-Juxtlahuaca" },
          { name: "Santiago Lachiguiri", distinctId: "Santiago-Lachiguiri" },
          { name: "Santiago Lalopa", distinctId: "Santiago-Lalopa" },
          { name: "Santiago Laollaga", distinctId: "Santiago-Laollaga" },
          { name: "Santiago Laxopa", distinctId: "Santiago-Laxopa" },
          {
            name: "Santiago Llano Grande",
            distinctId: "Santiago-Llano-Grande",
          },
          { name: "Santiago Matatlan", distinctId: "Santiago-Matatlan" },
          { name: "Santiago Miltepec", distinctId: "Santiago-Miltepec" },
          { name: "Santiago Minas", distinctId: "Santiago-Minas" },
          { name: "Santiago Nacaltepec", distinctId: "Santiago-Nacaltepec" },
          { name: "Santiago Nejapilla", distinctId: "Santiago-Nejapilla" },
          { name: "Santiago Niltepec", distinctId: "Santiago-Niltepec" },
          { name: "Santiago Nundiche", distinctId: "Santiago-Nundiche" },
          { name: "Santiago Nuyoo", distinctId: "Santiago-Nuyoo" },
          {
            name: "Santiago Pinotepa Nacional",
            distinctId: "Santiago-Pinotepa-Nacional",
          },
          {
            name: "Santiago Suchilquitongo",
            distinctId: "Santiago-Suchilquitongo",
          },
          { name: "Santiago Tamazola", distinctId: "Santiago-Tamazola" },
          { name: "Santiago Tapextla", distinctId: "Santiago-Tapextla" },
          { name: "Santiago Tenango", distinctId: "Santiago-Tenango" },
          { name: "Santiago Tepetlapa", distinctId: "Santiago-Tepetlapa" },
          { name: "Santiago Tetepec", distinctId: "Santiago-Tetepec" },
          { name: "Santiago Texcalcingo", distinctId: "Santiago-Texcalcingo" },
          { name: "Santiago Textitlan", distinctId: "Santiago-Textitlan" },
          { name: "Santiago Tilantongo", distinctId: "Santiago-Tilantongo" },
          { name: "Santiago Tillo", distinctId: "Santiago-Tillo" },
          {
            name: "Santiago Tlazoyaltepec",
            distinctId: "Santiago-Tlazoyaltepec",
          },
          { name: "Santiago Xanica", distinctId: "Santiago-Xanica" },
          { name: "Santiago Xiacui", distinctId: "Santiago-Xiacui" },
          { name: "Santiago Yaitepec", distinctId: "Santiago-Yaitepec" },
          { name: "Santiago Yaveo", distinctId: "Santiago-Yaveo" },
          { name: "Santiago Yolomecatl", distinctId: "Santiago-Yolomecatl" },
          { name: "Santiago Yosondua", distinctId: "Santiago-Yosondua" },
          { name: "Santiago Yucuyachi", distinctId: "Santiago-Yucuyachi" },
          { name: "Santiago Zacatepec", distinctId: "Santiago-Zacatepec" },
          { name: "Santiago Zoochila", distinctId: "Santiago-Zoochila" },
          { name: "Santiago del Rio", distinctId: "Santiago-del-Rio" },
          {
            name: "Santo Domingo Albarradas",
            distinctId: "Santo-Domingo-Albarradas",
          },
          {
            name: "Santo Domingo Armenta",
            distinctId: "Santo-Domingo-Armenta",
          },
          {
            name: "Santo Domingo Chihuitan",
            distinctId: "Santo-Domingo-Chihuitan",
          },
          {
            name: "Santo Domingo Ingenio",
            distinctId: "Santo-Domingo-Ingenio",
          },
          {
            name: "Santo Domingo Ixcatlan",
            distinctId: "Santo-Domingo-Ixcatlan",
          },
          { name: "Santo Domingo Nuxaa", distinctId: "Santo-Domingo-Nuxaa" },
          {
            name: "Santo Domingo Ozolotepec",
            distinctId: "Santo-Domingo-Ozolotepec",
          },
          { name: "Santo Domingo Petapa", distinctId: "Santo-Domingo-Petapa" },
          {
            name: "Santo Domingo Roayaga",
            distinctId: "Santo-Domingo-Roayaga",
          },
          {
            name: "Santo Domingo Tehuantepec",
            distinctId: "Santo-Domingo-Tehuantepec",
          },
          {
            name: "Santo Domingo Teojomulco",
            distinctId: "Santo-Domingo-Teojomulco",
          },
          {
            name: "Santo Domingo Tepuxtepec",
            distinctId: "Santo-Domingo-Tepuxtepec",
          },
          {
            name: "Santo Domingo Tlatayapam",
            distinctId: "Santo-Domingo-Tlatayapam",
          },
          {
            name: "Santo Domingo Tomaltepec",
            distinctId: "Santo-Domingo-Tomaltepec",
          },
          { name: "Santo Domingo Tonala", distinctId: "Santo-Domingo-Tonala" },
          {
            name: "Santo Domingo Tonaltepec",
            distinctId: "Santo-Domingo-Tonaltepec",
          },
          {
            name: "Santo Domingo Xagacia",
            distinctId: "Santo-Domingo-Xagacia",
          },
          {
            name: "Santo Domingo Yanhuitlan",
            distinctId: "Santo-Domingo-Yanhuitlan",
          },
          {
            name: "Santo Domingo Yodohino",
            distinctId: "Santo-Domingo-Yodohino",
          },
          {
            name: "Santo Domingo Zanatepec",
            distinctId: "Santo-Domingo-Zanatepec",
          },
          {
            name: "Santo Domingo de Morelos",
            distinctId: "Santo-Domingo-de-Morelos",
          },
          { name: "Santo Tomas Jalieza", distinctId: "Santo-Tomas-Jalieza" },
          {
            name: "Santo Tomas Mazaltepec",
            distinctId: "Santo-Tomas-Mazaltepec",
          },
          { name: "Santo Tomas Ocotepec", distinctId: "Santo-Tomas-Ocotepec" },
          {
            name: "Santo Tomas Tamazulapan",
            distinctId: "Santo-Tomas-Tamazulapan",
          },
          { name: "Santos Reyes Nopala", distinctId: "Santos-Reyes-Nopala" },
          { name: "Santos Reyes Papalo", distinctId: "Santos-Reyes-Papalo" },
          {
            name: "Santos Reyes Tepejillo",
            distinctId: "Santos-Reyes-Tepejillo",
          },
          { name: "Santos Reyes Yucuna", distinctId: "Santos-Reyes-Yucuna" },
          { name: "Silacayoapam", distinctId: "Silacayoapam" },
          { name: "Sitio de Xitlapehua", distinctId: "Sitio-de-Xitlapehua" },
          { name: "Soledad Etla", distinctId: "Soledad-Etla" },
          {
            name: "Tamazulapam del Espiritu Santo",
            distinctId: "Tamazulapam-del-Espiritu-Santo",
          },
          { name: "Tanetze de Zaragoza", distinctId: "Tanetze-de-Zaragoza" },
          { name: "Taniche", distinctId: "Taniche" },
          { name: "Tataltepec de Valdes", distinctId: "Tataltepec-de-Valdes" },
          {
            name: "Teococuilco de Marcos Perez",
            distinctId: "Teococuilco-de-Marcos-Perez",
          },
          {
            name: "Teotitlan de Flores Magon",
            distinctId: "Teotitlan-de-Flores-Magon",
          },
          { name: "Teotitlan del Valle", distinctId: "Teotitlan-del-Valle" },
          { name: "Teotongo", distinctId: "Teotongo" },
          {
            name: "Tepelmeme Villa de Morelos",
            distinctId: "Tepelmeme-Villa-de-Morelos",
          },
          {
            name: "Tlacolula de Matamoros",
            distinctId: "Tlacolula-de-Matamoros",
          },
          { name: "Tlacotepec Plumas", distinctId: "Tlacotepec-Plumas" },
          { name: "Tlalixtac de Cabrera", distinctId: "Tlalixtac-de-Cabrera" },
          {
            name: "Totontepec Villa de Morelos",
            distinctId: "Totontepec-Villa-de-Morelos",
          },
          { name: "Trinidad Zaachila", distinctId: "Trinidad-Zaachila" },
          { name: "Union Hidalgo", distinctId: "Union-Hidalgo" },
          { name: "Valerio Trujano", distinctId: "Valerio-Trujano" },
          { name: "Villa Diaz Ordaz", distinctId: "Villa-Diaz-Ordaz" },
          { name: "Villa Hidalgo", distinctId: "Villa-Hidalgo" },
          { name: "Villa Sola de Vega", distinctId: "Villa-Sola-de-Vega" },
          {
            name: "Villa Talea de Castro",
            distinctId: "Villa-Talea-de-Castro",
          },
          {
            name: "Villa Tejupam de la Union",
            distinctId: "Villa-Tejupam-de-la-Union",
          },
          {
            name: "Villa de Chilapa de Diaz",
            distinctId: "Villa-de-Chilapa-de-Diaz",
          },
          { name: "Villa de Etla", distinctId: "Villa-de-Etla" },
          {
            name: "Villa de Tamazulapam del Progreso",
            distinctId: "Villa-de-Tamazulapam-del-Progreso",
          },
          { name: "Villa de Tututepec", distinctId: "Villa-de-Tututepec" },
          { name: "Villa de Zaachila", distinctId: "Villa-de-Zaachila" },
          { name: "Yaxe", distinctId: "Yaxe" },
          { name: "Yogana", distinctId: "Yogana" },
          {
            name: "Yutanduchi de Guerrero",
            distinctId: "Yutanduchi-de-Guerrero",
          },
          { name: "Zapotitlan Lagunas", distinctId: "Zapotitlan-Lagunas" },
          { name: "Zapotitlan Palmas", distinctId: "Zapotitlan-Palmas" },
          { name: "Zimatlan de Alvarez", distinctId: "Zimatlan-de-Alvarez" },
        ],
      },
      {
        name: "Puebla",
        distinctId: "Puebla",
        subregions: [
          { name: "Acajete", distinctId: "Acajete" },
          { name: "Acateno", distinctId: "Acateno" },
          { name: "Acatlan", distinctId: "Acatlan" },
          { name: "Acatzingo", distinctId: "Acatzingo" },
          { name: "Acteopan", distinctId: "Acteopan" },
          { name: "Ahuacatlan", distinctId: "Ahuacatlan" },
          { name: "Ahuatlan", distinctId: "Ahuatlan" },
          { name: "Ahuazotepec", distinctId: "Ahuazotepec" },
          { name: "Ahuehuetitla", distinctId: "Ahuehuetitla" },
          { name: "Ajalpan", distinctId: "Ajalpan" },
          { name: "Albino Zertuche", distinctId: "Albino-Zertuche" },
          { name: "Aljojuca", distinctId: "Aljojuca" },
          { name: "Altepexi", distinctId: "Altepexi" },
          { name: "Amixtlan", distinctId: "Amixtlan" },
          { name: "Amozoc", distinctId: "Amozoc" },
          { name: "Aquixtla", distinctId: "Aquixtla" },
          { name: "Atempan", distinctId: "Atempan" },
          { name: "Atexcal", distinctId: "Atexcal" },
          { name: "Atlequizayan", distinctId: "Atlequizayan" },
          { name: "Atlixco", distinctId: "Atlixco" },
          { name: "Atoyatempan", distinctId: "Atoyatempan" },
          { name: "Atzala", distinctId: "Atzala" },
          { name: "Atzitzihuacan", distinctId: "Atzitzihuacan" },
          { name: "Atzitzintla", distinctId: "Atzitzintla" },
          { name: "Axutla", distinctId: "Axutla" },
          { name: "Ayotoxco de Guerrero", distinctId: "Ayotoxco-de-Guerrero" },
          { name: "Calpan", distinctId: "Calpan" },
          { name: "Caltepec", distinctId: "Caltepec" },
          { name: "Camocuautla", distinctId: "Camocuautla" },
          { name: "Canada Morelos", distinctId: "Canada-Morelos" },
          { name: "Caxhuacan", distinctId: "Caxhuacan" },
          {
            name: "Chalchicomula de Sesma",
            distinctId: "Chalchicomula-de-Sesma",
          },
          { name: "Chapulco", distinctId: "Chapulco" },
          { name: "Chiautla", distinctId: "Chiautla" },
          { name: "Chiautzingo", distinctId: "Chiautzingo" },
          { name: "Chichiquila", distinctId: "Chichiquila" },
          { name: "Chiconcuautla", distinctId: "Chiconcuautla" },
          { name: "Chietla", distinctId: "Chietla" },
          { name: "Chigmecatitlan", distinctId: "Chigmecatitlan" },
          { name: "Chignahuapan", distinctId: "Chignahuapan" },
          { name: "Chignautla", distinctId: "Chignautla" },
          { name: "Chila de la Sal", distinctId: "Chila-de-la-Sal" },
          { name: "Chila", distinctId: "Chila" },
          { name: "Chilchotla", distinctId: "Chilchotla" },
          { name: "Chinantla", distinctId: "Chinantla" },
          { name: "Coatepec", distinctId: "Coatepec" },
          { name: "Coatzingo", distinctId: "Coatzingo" },
          { name: "Cohetzala", distinctId: "Cohetzala" },
          { name: "Cohuecan", distinctId: "Cohuecan" },
          { name: "Coronango", distinctId: "Coronango" },
          { name: "Coxcatlan", distinctId: "Coxcatlan" },
          { name: "Coyomeapan", distinctId: "Coyomeapan" },
          { name: "Coyotepec", distinctId: "Coyotepec" },
          { name: "Cuapiaxtla de Madero", distinctId: "Cuapiaxtla-de-Madero" },
          { name: "Cuautempan", distinctId: "Cuautempan" },
          { name: "Cuautinchan", distinctId: "Cuautinchan" },
          { name: "Cuautlancingo", distinctId: "Cuautlancingo" },
          { name: "Cuayuca de Andrade", distinctId: "Cuayuca-de-Andrade" },
          {
            name: "Cuetzalan del Progreso",
            distinctId: "Cuetzalan-del-Progreso",
          },
          { name: "Cuyoaco", distinctId: "Cuyoaco" },
          { name: "Domingo Arenas", distinctId: "Domingo-Arenas" },
          { name: "Eloxochitlan", distinctId: "Eloxochitlan" },
          { name: "Epatlan", distinctId: "Epatlan" },
          { name: "Esperanza", distinctId: "Esperanza" },
          { name: "Francisco Z. Mena", distinctId: "Francisco-Z.-Mena" },
          {
            name: "General Felipe Angeles",
            distinctId: "General-Felipe-Angeles",
          },
          { name: "Guadalupe Victoria", distinctId: "Guadalupe-Victoria" },
          { name: "Guadalupe", distinctId: "Guadalupe" },
          { name: "Hermenegildo Galeana", distinctId: "Hermenegildo-Galeana" },
          { name: "Honey", distinctId: "Honey" },
          { name: "Huaquechula", distinctId: "Huaquechula" },
          { name: "Huatlatlauca", distinctId: "Huatlatlauca" },
          { name: "Huauchinango", distinctId: "Huauchinango" },
          { name: "Huehuetla", distinctId: "Huehuetla" },
          { name: "Huehuetlan el Chico", distinctId: "Huehuetlan-el-Chico" },
          { name: "Huehuetlan el Grande", distinctId: "Huehuetlan-el-Grande" },
          { name: "Huejotzingo", distinctId: "Huejotzingo" },
          { name: "Hueyapan", distinctId: "Hueyapan" },
          { name: "Hueytamalco", distinctId: "Hueytamalco" },
          { name: "Hueytlalpan", distinctId: "Hueytlalpan" },
          { name: "Huitzilan de Serdan", distinctId: "Huitzilan-de-Serdan" },
          { name: "Huitziltepec", distinctId: "Huitziltepec" },
          {
            name: "Ixcamilpa de Guerrero",
            distinctId: "Ixcamilpa-de-Guerrero",
          },
          { name: "Ixcaquixtla", distinctId: "Ixcaquixtla" },
          { name: "Ixtacamaxtitlan", distinctId: "Ixtacamaxtitlan" },
          { name: "Ixtepec", distinctId: "Ixtepec" },
          { name: "Izucar de Matamoros", distinctId: "Izucar-de-Matamoros" },
          { name: "Jalpan", distinctId: "Jalpan" },
          { name: "Jolalpan", distinctId: "Jolalpan" },
          { name: "Jonotla", distinctId: "Jonotla" },
          { name: "Jopala", distinctId: "Jopala" },
          { name: "Juan C. Bonilla", distinctId: "Juan-C.-Bonilla" },
          { name: "Juan Galindo", distinctId: "Juan-Galindo" },
          { name: "Juan N. Mendez", distinctId: "Juan-N.-Mendez" },
          {
            name: "La Magdalena Tlatlauquitepec",
            distinctId: "La-Magdalena-Tlatlauquitepec",
          },
          { name: "Lafragua", distinctId: "Lafragua" },
          { name: "Libres", distinctId: "Libres" },
          { name: "Los Reyes de Juarez", distinctId: "Los-Reyes-de-Juarez" },
          {
            name: "Mazapiltepec de Juarez",
            distinctId: "Mazapiltepec-de-Juarez",
          },
          { name: "Mixtla", distinctId: "Mixtla" },
          { name: "Molcaxac", distinctId: "Molcaxac" },
          { name: "Naupan", distinctId: "Naupan" },
          { name: "Nauzontla", distinctId: "Nauzontla" },
          { name: "Nealtican", distinctId: "Nealtican" },
          { name: "Nicolas Bravo", distinctId: "Nicolas-Bravo" },
          { name: "Nopalucan", distinctId: "Nopalucan" },
          { name: "Ocotepec", distinctId: "Ocotepec" },
          { name: "Ocoyucan", distinctId: "Ocoyucan" },
          { name: "Olintla", distinctId: "Olintla" },
          { name: "Oriental", distinctId: "Oriental" },
          { name: "Pahuatlan", distinctId: "Pahuatlan" },
          { name: "Palmar de Bravo", distinctId: "Palmar-de-Bravo" },
          { name: "Pantepec", distinctId: "Pantepec" },
          { name: "Petlalcingo", distinctId: "Petlalcingo" },
          { name: "Piaxtla", distinctId: "Piaxtla" },
          { name: "Puebla", distinctId: "Puebla" },
          { name: "Quecholac", distinctId: "Quecholac" },
          { name: "Quimixtlan", distinctId: "Quimixtlan" },
          { name: "Rafael Lara Grajales", distinctId: "Rafael-Lara-Grajales" },
          { name: "San Andres Cholula", distinctId: "San-Andres-Cholula" },
          { name: "San Antonio Canada", distinctId: "San-Antonio-Canada" },
          {
            name: "San Diego la Mesa Tochimiltzingo",
            distinctId: "San-Diego-la-Mesa-Tochimiltzingo",
          },
          {
            name: "San Felipe Teotlalcingo",
            distinctId: "San-Felipe-Teotlalcingo",
          },
          { name: "San Felipe Tepatlan", distinctId: "San-Felipe-Tepatlan" },
          { name: "San Gabriel Chilac", distinctId: "San-Gabriel-Chilac" },
          { name: "San Gregorio Atzompa", distinctId: "San-Gregorio-Atzompa" },
          {
            name: "San Jeronimo Tecuanipan",
            distinctId: "San-Jeronimo-Tecuanipan",
          },
          {
            name: "San Jeronimo Xayacatlan",
            distinctId: "San-Jeronimo-Xayacatlan",
          },
          { name: "San Jose Chiapa", distinctId: "San-Jose-Chiapa" },
          { name: "San Jose Miahuatlan", distinctId: "San-Jose-Miahuatlan" },
          { name: "San Juan Atenco", distinctId: "San-Juan-Atenco" },
          { name: "San Juan Atzompa", distinctId: "San-Juan-Atzompa" },
          {
            name: "San Martin Texmelucan",
            distinctId: "San-Martin-Texmelucan",
          },
          {
            name: "San Martin Totoltepec",
            distinctId: "San-Martin-Totoltepec",
          },
          {
            name: "San Matias Tlalancaleca",
            distinctId: "San-Matias-Tlalancaleca",
          },
          { name: "San Miguel Ixitlan", distinctId: "San-Miguel-Ixitlan" },
          { name: "San Miguel Xoxtla", distinctId: "San-Miguel-Xoxtla" },
          {
            name: "San Nicolas Buenos Aires",
            distinctId: "San-Nicolas-Buenos-Aires",
          },
          {
            name: "San Nicolas de los Ranchos",
            distinctId: "San-Nicolas-de-los-Ranchos",
          },
          { name: "San Pablo Anicano", distinctId: "San-Pablo-Anicano" },
          { name: "San Pedro Cholula", distinctId: "San-Pedro-Cholula" },
          {
            name: "San Pedro Yeloixtlahuaca",
            distinctId: "San-Pedro-Yeloixtlahuaca",
          },
          {
            name: "San Salvador Huixcolotla",
            distinctId: "San-Salvador-Huixcolotla",
          },
          { name: "San Salvador el Seco", distinctId: "San-Salvador-el-Seco" },
          {
            name: "San Salvador el Verde",
            distinctId: "San-Salvador-el-Verde",
          },
          {
            name: "San Sebastian Tlacotepec",
            distinctId: "San-Sebastian-Tlacotepec",
          },
          {
            name: "Santa Catarina Tlaltempan",
            distinctId: "Santa-Catarina-Tlaltempan",
          },
          {
            name: "Santa Ines Ahuatempan",
            distinctId: "Santa-Ines-Ahuatempan",
          },
          { name: "Santa Isabel Cholula", distinctId: "Santa-Isabel-Cholula" },
          { name: "Santiago Miahuatlan", distinctId: "Santiago-Miahuatlan" },
          {
            name: "Santo Tomas Hueyotlipan",
            distinctId: "Santo-Tomas-Hueyotlipan",
          },
          { name: "Soltepec", distinctId: "Soltepec" },
          { name: "Tecali de Herrera", distinctId: "Tecali-de-Herrera" },
          { name: "Tecamachalco", distinctId: "Tecamachalco" },
          { name: "Tecomatlan", distinctId: "Tecomatlan" },
          { name: "Tehuacan", distinctId: "Tehuacan" },
          { name: "Tehuitzingo", distinctId: "Tehuitzingo" },
          { name: "Tenampulco", distinctId: "Tenampulco" },
          { name: "Teopantlan", distinctId: "Teopantlan" },
          { name: "Teotlalco", distinctId: "Teotlalco" },
          { name: "Tepanco de Lopez", distinctId: "Tepanco-de-Lopez" },
          { name: "Tepango de Rodriguez", distinctId: "Tepango-de-Rodriguez" },
          {
            name: "Tepatlaxco de Hidalgo",
            distinctId: "Tepatlaxco-de-Hidalgo",
          },
          { name: "Tepeaca", distinctId: "Tepeaca" },
          { name: "Tepemaxalco", distinctId: "Tepemaxalco" },
          { name: "Tepeojuma", distinctId: "Tepeojuma" },
          { name: "Tepetzintla", distinctId: "Tepetzintla" },
          { name: "Tepexco", distinctId: "Tepexco" },
          { name: "Tepexi de Rodriguez", distinctId: "Tepexi-de-Rodriguez" },
          {
            name: "Tepeyahualco de Cuauhtemoc",
            distinctId: "Tepeyahualco-de-Cuauhtemoc",
          },
          { name: "Tepeyahualco", distinctId: "Tepeyahualco" },
          { name: "Tetela de Ocampo", distinctId: "Tetela-de-Ocampo" },
          {
            name: "Teteles de Avila Castillo",
            distinctId: "Teteles-de-Avila-Castillo",
          },
          { name: "Teziutlan", distinctId: "Teziutlan" },
          { name: "Tianguismanalco", distinctId: "Tianguismanalco" },
          { name: "Tilapa", distinctId: "Tilapa" },
          { name: "Tlachichuca", distinctId: "Tlachichuca" },
          {
            name: "Tlacotepec de Benito Juarez",
            distinctId: "Tlacotepec-de-Benito-Juarez",
          },
          { name: "Tlacuilotepec", distinctId: "Tlacuilotepec" },
          { name: "Tlahuapan", distinctId: "Tlahuapan" },
          { name: "Tlaltenango", distinctId: "Tlaltenango" },
          { name: "Tlanepantla", distinctId: "Tlanepantla" },
          { name: "Tlaola", distinctId: "Tlaola" },
          { name: "Tlapacoya", distinctId: "Tlapacoya" },
          { name: "Tlapanala", distinctId: "Tlapanala" },
          { name: "Tlatlauquitepec", distinctId: "Tlatlauquitepec" },
          { name: "Tlaxco", distinctId: "Tlaxco" },
          { name: "Tochimilco", distinctId: "Tochimilco" },
          { name: "Tochtepec", distinctId: "Tochtepec" },
          {
            name: "Totoltepec de Guerrero",
            distinctId: "Totoltepec-de-Guerrero",
          },
          { name: "Tulcingo", distinctId: "Tulcingo" },
          { name: "Tuzamapan de Galeana", distinctId: "Tuzamapan-de-Galeana" },
          { name: "Tzicatlacoyan", distinctId: "Tzicatlacoyan" },
          { name: "Venustiano Carranza", distinctId: "Venustiano-Carranza" },
          { name: "Vicente Guerrero", distinctId: "Vicente-Guerrero" },
          { name: "Xayacatlan de Bravo", distinctId: "Xayacatlan-de-Bravo" },
          { name: "Xicotepec", distinctId: "Xicotepec" },
          { name: "Xicotlan", distinctId: "Xicotlan" },
          { name: "Xiutetelco", distinctId: "Xiutetelco" },
          { name: "Xochiapulco", distinctId: "Xochiapulco" },
          { name: "Xochiltepec", distinctId: "Xochiltepec" },
          {
            name: "Xochitlan Todos Santos",
            distinctId: "Xochitlan-Todos-Santos",
          },
          {
            name: "Xochitlan de Vicente Suarez",
            distinctId: "Xochitlan-de-Vicente-Suarez",
          },
          { name: "Yaonahuac", distinctId: "Yaonahuac" },
          { name: "Yehualtepec", distinctId: "Yehualtepec" },
          { name: "Zacapala", distinctId: "Zacapala" },
          { name: "Zacapoaxtla", distinctId: "Zacapoaxtla" },
          { name: "Zacatlan", distinctId: "Zacatlan" },
          { name: "Zapotitlan de Mendez", distinctId: "Zapotitlan-de-Mendez" },
          { name: "Zapotitlan", distinctId: "Zapotitlan" },
          { name: "Zaragoza", distinctId: "Zaragoza" },
          { name: "Zautla", distinctId: "Zautla" },
          { name: "Zihuateutla", distinctId: "Zihuateutla" },
          { name: "Zinacatepec", distinctId: "Zinacatepec" },
          { name: "Zongozotla", distinctId: "Zongozotla" },
          { name: "Zoquiapan", distinctId: "Zoquiapan" },
          { name: "Zoquitlan", distinctId: "Zoquitlan" },
        ],
      },
      {
        name: "Queretaro",
        distinctId: "Queretaro",
        subregions: [
          { name: "Amealco de Bonfil", distinctId: "Amealco-de-Bonfil" },
          { name: "Arroyo Seco", distinctId: "Arroyo-Seco" },
          { name: "Cadereyta de Montes", distinctId: "Cadereyta-de-Montes" },
          { name: "Colon", distinctId: "Colon" },
          { name: "Corregidora", distinctId: "Corregidora" },
          { name: "El Marques", distinctId: "El-Marques" },
          { name: "Ezequiel Montes", distinctId: "Ezequiel-Montes" },
          { name: "Huimilpan", distinctId: "Huimilpan" },
          { name: "Jalpan de Serra", distinctId: "Jalpan-de-Serra" },
          { name: "Landa de Matamoros", distinctId: "Landa-de-Matamoros" },
          { name: "Pedro Escobedo", distinctId: "Pedro-Escobedo" },
          { name: "Penamiller", distinctId: "Penamiller" },
          { name: "Pinal de Amoles", distinctId: "Pinal-de-Amoles" },
          { name: "Queretaro", distinctId: "Queretaro" },
          { name: "San Joaquin", distinctId: "San-Joaquin" },
          { name: "San Juan del Rio", distinctId: "San-Juan-del-Rio" },
          { name: "Tequisquiapan", distinctId: "Tequisquiapan" },
          { name: "Toliman", distinctId: "Toliman" },
        ],
      },
      {
        name: "Quintana Roo",
        distinctId: "Quintana-Roo",
        subregions: [
          { name: "Bacalar", distinctId: "Bacalar" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Cozumel", distinctId: "Cozumel" },
          {
            name: "Felipe Carrillo Puerto",
            distinctId: "Felipe-Carrillo-Puerto",
          },
          { name: "Isla Mujeres", distinctId: "Isla-Mujeres" },
          { name: "Jose Maria Morelos", distinctId: "Jose-Maria-Morelos" },
          { name: "Lazaro Cardenas", distinctId: "Lazaro-Cardenas" },
          { name: "Othon P. Blanco", distinctId: "Othon-P.-Blanco" },
          { name: "Puerto Morelos", distinctId: "Puerto-Morelos" },
          { name: "Solidaridad", distinctId: "Solidaridad" },
          { name: "Tulum", distinctId: "Tulum" },
        ],
      },
      {
        name: "San Luis Potosi",
        distinctId: "San-Luis-Potosi",
        subregions: [
          { name: "Ahualulco", distinctId: "Ahualulco" },
          { name: "Alaquines", distinctId: "Alaquines" },
          { name: "Aquismon", distinctId: "Aquismon" },
          {
            name: "Armadillo de los Infante",
            distinctId: "Armadillo-de-los-Infante",
          },
          { name: "Axtla de Terrazas", distinctId: "Axtla-de-Terrazas" },
          { name: "Cardenas", distinctId: "Cardenas" },
          { name: "Catorce", distinctId: "Catorce" },
          { name: "Cedral", distinctId: "Cedral" },
          { name: "Cerritos", distinctId: "Cerritos" },
          { name: "Cerro de San Pedro", distinctId: "Cerro-de-San-Pedro" },
          { name: "Charcas", distinctId: "Charcas" },
          { name: "Ciudad Fernandez", distinctId: "Ciudad-Fernandez" },
          { name: "Ciudad Valles", distinctId: "Ciudad-Valles" },
          { name: "Ciudad del Maiz", distinctId: "Ciudad-del-Maiz" },
          { name: "Coxcatlan", distinctId: "Coxcatlan" },
          { name: "Ebano", distinctId: "Ebano" },
          { name: "El Naranjo", distinctId: "El-Naranjo" },
          { name: "Guadalcazar", distinctId: "Guadalcazar" },
          { name: "Huehuetlan", distinctId: "Huehuetlan" },
          { name: "Lagunillas", distinctId: "Lagunillas" },
          { name: "Matehuala", distinctId: "Matehuala" },
          { name: "Matlapa", distinctId: "Matlapa" },
          { name: "Mexquitic de Carmona", distinctId: "Mexquitic-de-Carmona" },
          { name: "Moctezuma", distinctId: "Moctezuma" },
          { name: "Rayon", distinctId: "Rayon" },
          { name: "Rioverde", distinctId: "Rioverde" },
          { name: "Salinas", distinctId: "Salinas" },
          { name: "San Antonio", distinctId: "San-Antonio" },
          { name: "San Ciro de Acosta", distinctId: "San-Ciro-de-Acosta" },
          { name: "San Luis Potosi", distinctId: "San-Luis-Potosi" },
          {
            name: "San Martin Chalchicuautla",
            distinctId: "San-Martin-Chalchicuautla",
          },
          {
            name: "San Nicolas Tolentino",
            distinctId: "San-Nicolas-Tolentino",
          },
          {
            name: "San Vicente Tancuayalab",
            distinctId: "San-Vicente-Tancuayalab",
          },
          { name: "Santa Catarina", distinctId: "Santa-Catarina" },
          { name: "Santa Maria del Rio", distinctId: "Santa-Maria-del-Rio" },
          { name: "Santo Domingo", distinctId: "Santo-Domingo" },
          {
            name: "Soledad de Graciano Sanchez",
            distinctId: "Soledad-de-Graciano-Sanchez",
          },
          { name: "Tamasopo", distinctId: "Tamasopo" },
          { name: "Tamazunchale", distinctId: "Tamazunchale" },
          { name: "Tampacan", distinctId: "Tampacan" },
          { name: "Tampamolon Corona", distinctId: "Tampamolon-Corona" },
          { name: "Tamuin", distinctId: "Tamuin" },
          { name: "Tancanhuitz", distinctId: "Tancanhuitz" },
          { name: "Tanlajas", distinctId: "Tanlajas" },
          { name: "Tanquian de Escobedo", distinctId: "Tanquian-de-Escobedo" },
          { name: "Tierra Nueva", distinctId: "Tierra-Nueva" },
          { name: "Vanegas", distinctId: "Vanegas" },
          { name: "Venado", distinctId: "Venado" },
          { name: "Villa Hidalgo", distinctId: "Villa-Hidalgo" },
          { name: "Villa Juarez", distinctId: "Villa-Juarez" },
          { name: "Villa de Arista", distinctId: "Villa-de-Arista" },
          { name: "Villa de Arriaga", distinctId: "Villa-de-Arriaga" },
          { name: "Villa de Guadalupe", distinctId: "Villa-de-Guadalupe" },
          { name: "Villa de Ramos", distinctId: "Villa-de-Ramos" },
          { name: "Villa de Reyes", distinctId: "Villa-de-Reyes" },
          { name: "Villa de la Paz", distinctId: "Villa-de-la-Paz" },
          { name: "Xilitla", distinctId: "Xilitla" },
          { name: "Zaragoza", distinctId: "Zaragoza" },
        ],
      },
      {
        name: "Sinaloa",
        distinctId: "Sinaloa",
        subregions: [
          { name: "Ahome", distinctId: "Ahome" },
          { name: "Angostura", distinctId: "Angostura" },
          { name: "Badiraguato", distinctId: "Badiraguato" },
          { name: "Choix", distinctId: "Choix" },
          { name: "Concordia", distinctId: "Concordia" },
          { name: "Cosala", distinctId: "Cosala" },
          { name: "Culiacan", distinctId: "Culiacan" },
          { name: "El Fuerte", distinctId: "El-Fuerte" },
          { name: "Elota", distinctId: "Elota" },
          { name: "Escuinapa", distinctId: "Escuinapa" },
          { name: "Guasave", distinctId: "Guasave" },
          { name: "Mazatlan", distinctId: "Mazatlan" },
          { name: "Mocorito", distinctId: "Mocorito" },
          { name: "Navolato", distinctId: "Navolato" },
          { name: "Rosario", distinctId: "Rosario" },
          { name: "Salvador Alvarado", distinctId: "Salvador-Alvarado" },
          { name: "San Ignacio", distinctId: "San-Ignacio" },
          { name: "Sinaloa", distinctId: "Sinaloa" },
        ],
      },
      {
        name: "Sonora",
        distinctId: "Sonora",
        subregions: [
          { name: "Aconchi", distinctId: "Aconchi" },
          { name: "Agua Prieta", distinctId: "Agua-Prieta" },
          { name: "Alamos", distinctId: "Alamos" },
          { name: "Altar", distinctId: "Altar" },
          { name: "Arivechi", distinctId: "Arivechi" },
          { name: "Arizpe", distinctId: "Arizpe" },
          { name: "Atil", distinctId: "Atil" },
          { name: "Bacadehuachi", distinctId: "Bacadehuachi" },
          { name: "Bacanora", distinctId: "Bacanora" },
          { name: "Bacerac", distinctId: "Bacerac" },
          { name: "Bacoachi", distinctId: "Bacoachi" },
          { name: "Bacum", distinctId: "Bacum" },
          { name: "Banamichi", distinctId: "Banamichi" },
          { name: "Baviacora", distinctId: "Baviacora" },
          { name: "Bavispe", distinctId: "Bavispe" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Benjamin Hill", distinctId: "Benjamin-Hill" },
          { name: "Caborca", distinctId: "Caborca" },
          { name: "Cajeme", distinctId: "Cajeme" },
          { name: "Cananea", distinctId: "Cananea" },
          { name: "Carbo", distinctId: "Carbo" },
          { name: "Cucurpe", distinctId: "Cucurpe" },
          { name: "Cumpas", distinctId: "Cumpas" },
          { name: "Divisaderos", distinctId: "Divisaderos" },
          { name: "Empalme", distinctId: "Empalme" },
          { name: "Etchojoa", distinctId: "Etchojoa" },
          { name: "Fronteras", distinctId: "Fronteras" },
          {
            name: "General Plutarco Elias Calles",
            distinctId: "General-Plutarco-Elias-Calles",
          },
          { name: "Granados", distinctId: "Granados" },
          { name: "Guaymas", distinctId: "Guaymas" },
          { name: "Hermosillo", distinctId: "Hermosillo" },
          { name: "Huachinera", distinctId: "Huachinera" },
          { name: "Huasabas", distinctId: "Huasabas" },
          { name: "Huatabampo", distinctId: "Huatabampo" },
          { name: "Huepac", distinctId: "Huepac" },
          { name: "Imuris", distinctId: "Imuris" },
          { name: "La Colorada", distinctId: "La-Colorada" },
          { name: "Magdalena", distinctId: "Magdalena" },
          { name: "Mazatan", distinctId: "Mazatan" },
          { name: "Moctezuma", distinctId: "Moctezuma" },
          { name: "Naco", distinctId: "Naco" },
          { name: "Nacori Chico", distinctId: "Nacori-Chico" },
          { name: "Nacozari de Garcia", distinctId: "Nacozari-de-Garcia" },
          { name: "Navojoa", distinctId: "Navojoa" },
          { name: "Nogales", distinctId: "Nogales" },
          { name: "Onavas", distinctId: "Onavas" },
          { name: "Opodepe", distinctId: "Opodepe" },
          { name: "Oquitoa", distinctId: "Oquitoa" },
          { name: "Pitiquito", distinctId: "Pitiquito" },
          { name: "Puerto Penasco", distinctId: "Puerto-Penasco" },
          { name: "Quiriego", distinctId: "Quiriego" },
          { name: "Rayon", distinctId: "Rayon" },
          { name: "Rosario", distinctId: "Rosario" },
          { name: "Sahuaripa", distinctId: "Sahuaripa" },
          { name: "San Felipe de Jesus", distinctId: "San-Felipe-de-Jesus" },
          {
            name: "San Ignacio Rio Muerto",
            distinctId: "San-Ignacio-Rio-Muerto",
          },
          { name: "San Javier", distinctId: "San-Javier" },
          {
            name: "San Luis Rio Colorado",
            distinctId: "San-Luis-Rio-Colorado",
          },
          {
            name: "San Miguel de Horcasitas",
            distinctId: "San-Miguel-de-Horcasitas",
          },
          {
            name: "San Pedro de la Cueva",
            distinctId: "San-Pedro-de-la-Cueva",
          },
          { name: "Santa Ana", distinctId: "Santa-Ana" },
          { name: "Santa Cruz", distinctId: "Santa-Cruz" },
          { name: "Saric", distinctId: "Saric" },
          { name: "Soyopa", distinctId: "Soyopa" },
          { name: "Suaqui Grande", distinctId: "Suaqui-Grande" },
          { name: "Tepache", distinctId: "Tepache" },
          { name: "Trincheras", distinctId: "Trincheras" },
          { name: "Tubutama", distinctId: "Tubutama" },
          { name: "Ures", distinctId: "Ures" },
          { name: "Villa Hidalgo", distinctId: "Villa-Hidalgo" },
          { name: "Villa Pesqueira", distinctId: "Villa-Pesqueira" },
          { name: "Yecora", distinctId: "Yecora" },
        ],
      },
      {
        name: "Tabasco",
        distinctId: "Tabasco",
        subregions: [
          { name: "Balancan", distinctId: "Balancan" },
          { name: "Cardenas", distinctId: "Cardenas" },
          { name: "Centla", distinctId: "Centla" },
          { name: "Centro", distinctId: "Centro" },
          { name: "Comalcalco", distinctId: "Comalcalco" },
          { name: "Cunduacan", distinctId: "Cunduacan" },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Huimanguillo", distinctId: "Huimanguillo" },
          { name: "Jalapa", distinctId: "Jalapa" },
          { name: "Jalpa de Mendez", distinctId: "Jalpa-de-Mendez" },
          { name: "Jonuta", distinctId: "Jonuta" },
          { name: "Macuspana", distinctId: "Macuspana" },
          { name: "Nacajuca", distinctId: "Nacajuca" },
          { name: "Paraiso", distinctId: "Paraiso" },
          { name: "Tacotalpa", distinctId: "Tacotalpa" },
          { name: "Teapa", distinctId: "Teapa" },
          { name: "Tenosique", distinctId: "Tenosique" },
        ],
      },
      {
        name: "Tamaulipas",
        distinctId: "Tamaulipas",
        subregions: [
          { name: "Abasolo", distinctId: "Abasolo" },
          { name: "Aldama", distinctId: "Aldama" },
          { name: "Altamira", distinctId: "Altamira" },
          { name: "Antiguo Morelos", distinctId: "Antiguo-Morelos" },
          { name: "Burgos", distinctId: "Burgos" },
          { name: "Bustamante", distinctId: "Bustamante" },
          { name: "Camargo", distinctId: "Camargo" },
          { name: "Casas", distinctId: "Casas" },
          { name: "Ciudad Madero", distinctId: "Ciudad-Madero" },
          { name: "Cruillas", distinctId: "Cruillas" },
          { name: "El Mante", distinctId: "El-Mante" },
          { name: "Gomez Farias", distinctId: "Gomez-Farias" },
          { name: "Gonzalez", distinctId: "Gonzalez" },
          { name: "Guemez", distinctId: "Guemez" },
          { name: "Guerrero", distinctId: "Guerrero" },
          { name: "Gustavo Diaz Ordaz", distinctId: "Gustavo-Diaz-Ordaz" },
          { name: "Hidalgo", distinctId: "Hidalgo" },
          { name: "Jaumave", distinctId: "Jaumave" },
          { name: "Jimenez", distinctId: "Jimenez" },
          { name: "Llera", distinctId: "Llera" },
          { name: "Mainero", distinctId: "Mainero" },
          { name: "Matamoros", distinctId: "Matamoros" },
          { name: "Mendez", distinctId: "Mendez" },
          { name: "Mier", distinctId: "Mier" },
          { name: "Miguel Aleman", distinctId: "Miguel-Aleman" },
          { name: "Miquihuana", distinctId: "Miquihuana" },
          { name: "Nuevo Laredo", distinctId: "Nuevo-Laredo" },
          { name: "Nuevo Morelos", distinctId: "Nuevo-Morelos" },
          { name: "Ocampo", distinctId: "Ocampo" },
          { name: "Padilla", distinctId: "Padilla" },
          { name: "Palmillas", distinctId: "Palmillas" },
          { name: "Reynosa", distinctId: "Reynosa" },
          { name: "Rio Bravo", distinctId: "Rio-Bravo" },
          { name: "San Carlos", distinctId: "San-Carlos" },
          { name: "San Fernando", distinctId: "San-Fernando" },
          { name: "San Nicolas", distinctId: "San-Nicolas" },
          { name: "Soto la Marina", distinctId: "Soto-la-Marina" },
          { name: "Tampico", distinctId: "Tampico" },
          { name: "Tula", distinctId: "Tula" },
          { name: "Valle Hermoso", distinctId: "Valle-Hermoso" },
          { name: "Victoria", distinctId: "Victoria" },
          { name: "Villagran", distinctId: "Villagran" },
          { name: "Xicotencatl", distinctId: "Xicotencatl" },
        ],
      },
      {
        name: "Tlaxcala",
        distinctId: "Tlaxcala",
        subregions: [
          {
            name: "Acuamanala de Miguel Hidalgo",
            distinctId: "Acuamanala-de-Miguel-Hidalgo",
          },
          { name: "Amaxac de Guerrero", distinctId: "Amaxac-de-Guerrero" },
          {
            name: "Apetatitlan de Antonio Carvajal",
            distinctId: "Apetatitlan-de-Antonio-Carvajal",
          },
          { name: "Apizaco", distinctId: "Apizaco" },
          { name: "Atlangatepec", distinctId: "Atlangatepec" },
          { name: "Atltzayanca", distinctId: "Atltzayanca" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Calpulalpan", distinctId: "Calpulalpan" },
          { name: "Chiautempan", distinctId: "Chiautempan" },
          {
            name: "Contla de Juan Cuamatzi",
            distinctId: "Contla-de-Juan-Cuamatzi",
          },
          { name: "Cuapiaxtla", distinctId: "Cuapiaxtla" },
          { name: "Cuaxomulco", distinctId: "Cuaxomulco" },
          {
            name: "El Carmen Tequexquitla",
            distinctId: "El-Carmen-Tequexquitla",
          },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Espanita", distinctId: "Espanita" },
          { name: "Huamantla", distinctId: "Huamantla" },
          { name: "Hueyotlipan", distinctId: "Hueyotlipan" },
          {
            name: "Ixtacuixtla de Mariano Matamoros",
            distinctId: "Ixtacuixtla-de-Mariano-Matamoros",
          },
          { name: "Ixtenco", distinctId: "Ixtenco" },
          {
            name: "La Magdalena Tlaltelulco",
            distinctId: "La-Magdalena-Tlaltelulco",
          },
          { name: "Lazaro Cardenas", distinctId: "Lazaro-Cardenas" },
          {
            name: "Mazatecochco de Jose Maria Morelos",
            distinctId: "Mazatecochco-de-Jose-Maria-Morelos",
          },
          {
            name: "Munoz de Domingo Arenas",
            distinctId: "Munoz-de-Domingo-Arenas",
          },
          {
            name: "Nanacamilpa de Mariano Arista",
            distinctId: "Nanacamilpa-de-Mariano-Arista",
          },
          { name: "Nativitas", distinctId: "Nativitas" },
          { name: "Panotla", distinctId: "Panotla" },
          {
            name: "Papalotla de Xicohtencatl",
            distinctId: "Papalotla-de-Xicohtencatl",
          },
          { name: "San Damian Texoloc", distinctId: "San-Damian-Texoloc" },
          {
            name: "San Francisco Tetlanohcan",
            distinctId: "San-Francisco-Tetlanohcan",
          },
          {
            name: "San Jeronimo Zacualpan",
            distinctId: "San-Jeronimo-Zacualpan",
          },
          { name: "San Jose Teacalco", distinctId: "San-Jose-Teacalco" },
          { name: "San Juan Huactzinco", distinctId: "San-Juan-Huactzinco" },
          {
            name: "San Lorenzo Axocomanitla",
            distinctId: "San-Lorenzo-Axocomanitla",
          },
          { name: "San Lucas Tecopilco", distinctId: "San-Lucas-Tecopilco" },
          { name: "San Pablo del Monte", distinctId: "San-Pablo-del-Monte" },
          {
            name: "Sanctorum de Lazaro Cardenas",
            distinctId: "Sanctorum-de-Lazaro-Cardenas",
          },
          { name: "Santa Ana Nopalucan", distinctId: "Santa-Ana-Nopalucan" },
          {
            name: "Santa Apolonia Teacalco",
            distinctId: "Santa-Apolonia-Teacalco",
          },
          {
            name: "Santa Catarina Ayometla",
            distinctId: "Santa-Catarina-Ayometla",
          },
          { name: "Santa Cruz Quilehtla", distinctId: "Santa-Cruz-Quilehtla" },
          { name: "Santa Cruz Tlaxcala", distinctId: "Santa-Cruz-Tlaxcala" },
          {
            name: "Santa Isabel Xiloxoxtla",
            distinctId: "Santa-Isabel-Xiloxoxtla",
          },
          { name: "Tenancingo", distinctId: "Tenancingo" },
          { name: "Teolocholco", distinctId: "Teolocholco" },
          {
            name: "Tepetitla de Lardizabal",
            distinctId: "Tepetitla-de-Lardizabal",
          },
          { name: "Tepeyanco", distinctId: "Tepeyanco" },
          { name: "Terrenate", distinctId: "Terrenate" },
          {
            name: "Tetla de la Solidaridad",
            distinctId: "Tetla-de-la-Solidaridad",
          },
          { name: "Tetlatlahuca", distinctId: "Tetlatlahuca" },
          { name: "Tlaxcala", distinctId: "Tlaxcala" },
          { name: "Tlaxco", distinctId: "Tlaxco" },
          { name: "Tocatlan", distinctId: "Tocatlan" },
          { name: "Totolac", distinctId: "Totolac" },
          { name: "Tzompantepec", distinctId: "Tzompantepec" },
          { name: "Xaloztoc", distinctId: "Xaloztoc" },
          { name: "Xaltocan", distinctId: "Xaltocan" },
          { name: "Xicohtzinco", distinctId: "Xicohtzinco" },
          { name: "Yauhquemehcan", distinctId: "Yauhquemehcan" },
          { name: "Zacatelco", distinctId: "Zacatelco" },
          {
            name: "Ziltlaltepec de Trinidad Sanchez Santos",
            distinctId: "Ziltlaltepec-de-Trinidad-Sanchez-Santos",
          },
        ],
      },
      {
        name: "Veracruz",
        distinctId: "Veracruz",
        subregions: [
          { name: "Acajete", distinctId: "Acajete" },
          { name: "Acatlan", distinctId: "Acatlan" },
          { name: "Acayucan", distinctId: "Acayucan" },
          { name: "Actopan", distinctId: "Actopan" },
          { name: "Acula", distinctId: "Acula" },
          { name: "Acultzingo", distinctId: "Acultzingo" },
          { name: "Agua Dulce", distinctId: "Agua-Dulce" },
          { name: "Alamo Temapache", distinctId: "Alamo-Temapache" },
          { name: "Alpatlahuac", distinctId: "Alpatlahuac" },
          {
            name: "Alto Lucero de Gutierrez Barrios",
            distinctId: "Alto-Lucero-de-Gutierrez-Barrios",
          },
          { name: "Altotonga", distinctId: "Altotonga" },
          { name: "Alvarado", distinctId: "Alvarado" },
          { name: "Amatitlan", distinctId: "Amatitlan" },
          { name: "Amatlan de los Reyes", distinctId: "Amatlan-de-los-Reyes" },
          { name: "Angel R. Cabada", distinctId: "Angel-R.-Cabada" },
          { name: "Apazapan", distinctId: "Apazapan" },
          { name: "Aquila", distinctId: "Aquila" },
          { name: "Astacinga", distinctId: "Astacinga" },
          { name: "Atlahuilco", distinctId: "Atlahuilco" },
          { name: "Atoyac", distinctId: "Atoyac" },
          { name: "Atzacan", distinctId: "Atzacan" },
          { name: "Atzalan", distinctId: "Atzalan" },
          { name: "Ayahualulco", distinctId: "Ayahualulco" },
          { name: "Banderilla", distinctId: "Banderilla" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Boca del Rio", distinctId: "Boca-del-Rio" },
          { name: "Calcahualco", distinctId: "Calcahualco" },
          { name: "Camaron de Tejeda", distinctId: "Camaron-de-Tejeda" },
          { name: "Camerino Z. Mendoza", distinctId: "Camerino-Z.-Mendoza" },
          { name: "Carlos A. Carrillo", distinctId: "Carlos-A.-Carrillo" },
          { name: "Carrillo Puerto", distinctId: "Carrillo-Puerto" },
          { name: "Castillo de Teayo", distinctId: "Castillo-de-Teayo" },
          { name: "Catemaco", distinctId: "Catemaco" },
          { name: "Cazones de Herrera", distinctId: "Cazones-de-Herrera" },
          { name: "Cerro Azul", distinctId: "Cerro-Azul" },
          { name: "Chacaltianguis", distinctId: "Chacaltianguis" },
          { name: "Chalma", distinctId: "Chalma" },
          { name: "Chiconamel", distinctId: "Chiconamel" },
          { name: "Chiconquiaco", distinctId: "Chiconquiaco" },
          { name: "Chicontepec", distinctId: "Chicontepec" },
          { name: "Chinameca", distinctId: "Chinameca" },
          {
            name: "Chinampa de Gorostiza",
            distinctId: "Chinampa-de-Gorostiza",
          },
          { name: "Chocaman", distinctId: "Chocaman" },
          { name: "Chontla", distinctId: "Chontla" },
          { name: "Chumatlan", distinctId: "Chumatlan" },
          { name: "Citlaltepetl", distinctId: "Citlaltepetl" },
          { name: "Coacoatzintla", distinctId: "Coacoatzintla" },
          { name: "Coahuitlan", distinctId: "Coahuitlan" },
          { name: "Coatepec", distinctId: "Coatepec" },
          { name: "Coatzacoalcos", distinctId: "Coatzacoalcos" },
          { name: "Coatzintla", distinctId: "Coatzintla" },
          { name: "Coetzala", distinctId: "Coetzala" },
          { name: "Colipa", distinctId: "Colipa" },
          { name: "Comapa", distinctId: "Comapa" },
          { name: "Cordoba", distinctId: "Cordoba" },
          {
            name: "Cosamaloapan de Carpio",
            distinctId: "Cosamaloapan-de-Carpio",
          },
          {
            name: "Cosautlan de Carvajal",
            distinctId: "Cosautlan-de-Carvajal",
          },
          { name: "Coscomatepec", distinctId: "Coscomatepec" },
          { name: "Cosoleacaque", distinctId: "Cosoleacaque" },
          { name: "Cotaxtla", distinctId: "Cotaxtla" },
          { name: "Coxquihui", distinctId: "Coxquihui" },
          { name: "Coyutla", distinctId: "Coyutla" },
          { name: "Cuichapa", distinctId: "Cuichapa" },
          { name: "Cuitlahuac", distinctId: "Cuitlahuac" },
          { name: "El Higo", distinctId: "El-Higo" },
          { name: "Emiliano Zapata", distinctId: "Emiliano-Zapata" },
          { name: "Espinal", distinctId: "Espinal" },
          { name: "Filomeno Mata", distinctId: "Filomeno-Mata" },
          { name: "Fortin", distinctId: "Fortin" },
          { name: "Gutierrez Zamora", distinctId: "Gutierrez-Zamora" },
          { name: "Hidalgotitlan", distinctId: "Hidalgotitlan" },
          { name: "Huatusco", distinctId: "Huatusco" },
          { name: "Huayacocotla", distinctId: "Huayacocotla" },
          { name: "Hueyapan de Ocampo", distinctId: "Hueyapan-de-Ocampo" },
          {
            name: "Huiloapan de Cuauhtemoc",
            distinctId: "Huiloapan-de-Cuauhtemoc",
          },
          { name: "Ignacio de la Llave", distinctId: "Ignacio-de-la-Llave" },
          { name: "Ilamatlan", distinctId: "Ilamatlan" },
          { name: "Isla", distinctId: "Isla" },
          { name: "Ixcatepec", distinctId: "Ixcatepec" },
          {
            name: "Ixhuacan de los Reyes",
            distinctId: "Ixhuacan-de-los-Reyes",
          },
          { name: "Ixhuatlan de Madero", distinctId: "Ixhuatlan-de-Madero" },
          { name: "Ixhuatlan del Cafe", distinctId: "Ixhuatlan-del-Cafe" },
          {
            name: "Ixhuatlan del Sureste",
            distinctId: "Ixhuatlan-del-Sureste",
          },
          { name: "Ixhuatlancillo", distinctId: "Ixhuatlancillo" },
          { name: "Ixmatlahuacan", distinctId: "Ixmatlahuacan" },
          { name: "Ixtaczoquitlan", distinctId: "Ixtaczoquitlan" },
          { name: "Jalacingo", distinctId: "Jalacingo" },
          { name: "Jalcomulco", distinctId: "Jalcomulco" },
          { name: "Jaltipan", distinctId: "Jaltipan" },
          { name: "Jamapa", distinctId: "Jamapa" },
          { name: "Jesus Carranza", distinctId: "Jesus-Carranza" },
          { name: "Jilotepec", distinctId: "Jilotepec" },
          { name: "Jose Azueta", distinctId: "Jose-Azueta" },
          { name: "Juan Rodriguez Clara", distinctId: "Juan-Rodriguez-Clara" },
          { name: "Juchique de Ferrer", distinctId: "Juchique-de-Ferrer" },
          { name: "La Antigua", distinctId: "La-Antigua" },
          { name: "La Perla", distinctId: "La-Perla" },
          { name: "Landero y Coss", distinctId: "Landero-y-Coss" },
          { name: "Las Choapas", distinctId: "Las-Choapas" },
          { name: "Las Minas", distinctId: "Las-Minas" },
          { name: "Las Vigas de Ramirez", distinctId: "Las-Vigas-de-Ramirez" },
          { name: "Lerdo de Tejada", distinctId: "Lerdo-de-Tejada" },
          { name: "Los Reyes", distinctId: "Los-Reyes" },
          { name: "Magdalena", distinctId: "Magdalena" },
          { name: "Maltrata", distinctId: "Maltrata" },
          {
            name: "Manlio Fabio Altamirano",
            distinctId: "Manlio-Fabio-Altamirano",
          },
          { name: "Mariano Escobedo", distinctId: "Mariano-Escobedo" },
          { name: "Martinez de la Torre", distinctId: "Martinez-de-la-Torre" },
          { name: "Mecatlan", distinctId: "Mecatlan" },
          { name: "Mecayapan", distinctId: "Mecayapan" },
          { name: "Medellin de Bravo", distinctId: "Medellin-de-Bravo" },
          { name: "Miahuatlan", distinctId: "Miahuatlan" },
          { name: "Minatitlan", distinctId: "Minatitlan" },
          { name: "Misantla", distinctId: "Misantla" },
          { name: "Mixtla de Altamirano", distinctId: "Mixtla-de-Altamirano" },
          { name: "Moloacan", distinctId: "Moloacan" },
          {
            name: "Nanchital de Lazaro Cardenas del Rio",
            distinctId: "Nanchital-de-Lazaro-Cardenas-del-Rio",
          },
          { name: "Naolinco", distinctId: "Naolinco" },
          { name: "Naranjal", distinctId: "Naranjal" },
          { name: "Naranjos Amatlan", distinctId: "Naranjos-Amatlan" },
          { name: "Nautla", distinctId: "Nautla" },
          { name: "Nogales", distinctId: "Nogales" },
          { name: "Oluta", distinctId: "Oluta" },
          { name: "Omealca", distinctId: "Omealca" },
          { name: "Orizaba", distinctId: "Orizaba" },
          { name: "Otatitlan", distinctId: "Otatitlan" },
          { name: "Oteapan", distinctId: "Oteapan" },
          {
            name: "Ozuluama de Mascarenas",
            distinctId: "Ozuluama-de-Mascarenas",
          },
          { name: "Pajapan", distinctId: "Pajapan" },
          { name: "Panuco", distinctId: "Panuco" },
          { name: "Papantla", distinctId: "Papantla" },
          { name: "Paso de Ovejas", distinctId: "Paso-de-Ovejas" },
          { name: "Paso del Macho", distinctId: "Paso-del-Macho" },
          { name: "Perote", distinctId: "Perote" },
          { name: "Platon Sanchez", distinctId: "Platon-Sanchez" },
          { name: "Playa Vicente", distinctId: "Playa-Vicente" },
          { name: "Poza Rica de Hidalgo", distinctId: "Poza-Rica-de-Hidalgo" },
          { name: "Pueblo Viejo", distinctId: "Pueblo-Viejo" },
          { name: "Puente Nacional", distinctId: "Puente-Nacional" },
          { name: "Rafael Delgado", distinctId: "Rafael-Delgado" },
          { name: "Rafael Lucio", distinctId: "Rafael-Lucio" },
          { name: "Rio Blanco", distinctId: "Rio-Blanco" },
          { name: "Saltabarranca", distinctId: "Saltabarranca" },
          { name: "San Andres Tenejapan", distinctId: "San-Andres-Tenejapan" },
          { name: "San Andres Tuxtla", distinctId: "San-Andres-Tuxtla" },
          { name: "San Juan Evangelista", distinctId: "San-Juan-Evangelista" },
          { name: "San Rafael", distinctId: "San-Rafael" },
          { name: "Santiago Sochiapan", distinctId: "Santiago-Sochiapan" },
          { name: "Santiago Tuxtla", distinctId: "Santiago-Tuxtla" },
          { name: "Sayula de Aleman", distinctId: "Sayula-de-Aleman" },
          { name: "Sochiapa", distinctId: "Sochiapa" },
          { name: "Soconusco", distinctId: "Soconusco" },
          { name: "Soledad Atzompa", distinctId: "Soledad-Atzompa" },
          { name: "Soledad de Doblado", distinctId: "Soledad-de-Doblado" },
          { name: "Soteapan", distinctId: "Soteapan" },
          { name: "Tamalin", distinctId: "Tamalin" },
          { name: "Tamiahua", distinctId: "Tamiahua" },
          { name: "Tampico Alto", distinctId: "Tampico-Alto" },
          { name: "Tancoco", distinctId: "Tancoco" },
          { name: "Tantima", distinctId: "Tantima" },
          { name: "Tantoyuca", distinctId: "Tantoyuca" },
          {
            name: "Tatahuicapan de Juarez",
            distinctId: "Tatahuicapan-de-Juarez",
          },
          { name: "Tatatila", distinctId: "Tatatila" },
          { name: "Tecolutla", distinctId: "Tecolutla" },
          { name: "Tehuipango", distinctId: "Tehuipango" },
          { name: "Tempoal", distinctId: "Tempoal" },
          { name: "Tenampa", distinctId: "Tenampa" },
          { name: "Tenochtitlan", distinctId: "Tenochtitlan" },
          { name: "Teocelo", distinctId: "Teocelo" },
          { name: "Tepatlaxco", distinctId: "Tepatlaxco" },
          { name: "Tepetlan", distinctId: "Tepetlan" },
          { name: "Tepetzintla", distinctId: "Tepetzintla" },
          { name: "Tequila", distinctId: "Tequila" },
          { name: "Texcatepec", distinctId: "Texcatepec" },
          { name: "Texhuacan", distinctId: "Texhuacan" },
          { name: "Texistepec", distinctId: "Texistepec" },
          { name: "Tezonapa", distinctId: "Tezonapa" },
          { name: "Tierra Blanca", distinctId: "Tierra-Blanca" },
          { name: "Tihuatlan", distinctId: "Tihuatlan" },
          { name: "Tlachichilco", distinctId: "Tlachichilco" },
          { name: "Tlacojalpan", distinctId: "Tlacojalpan" },
          { name: "Tlacolulan", distinctId: "Tlacolulan" },
          { name: "Tlacotalpan", distinctId: "Tlacotalpan" },
          { name: "Tlacotepec de Mejia", distinctId: "Tlacotepec-de-Mejia" },
          { name: "Tlalixcoyan", distinctId: "Tlalixcoyan" },
          { name: "Tlalnelhuayocan", distinctId: "Tlalnelhuayocan" },
          { name: "Tlaltetela", distinctId: "Tlaltetela" },
          { name: "Tlapacoyan", distinctId: "Tlapacoyan" },
          { name: "Tlaquilpa", distinctId: "Tlaquilpa" },
          { name: "Tlilapan", distinctId: "Tlilapan" },
          { name: "Tomatlan", distinctId: "Tomatlan" },
          { name: "Tonayan", distinctId: "Tonayan" },
          { name: "Totutla", distinctId: "Totutla" },
          { name: "Tres Valles", distinctId: "Tres-Valles" },
          { name: "Tuxpan", distinctId: "Tuxpan" },
          { name: "Tuxtilla", distinctId: "Tuxtilla" },
          { name: "Ursulo Galvan", distinctId: "Ursulo-Galvan" },
          { name: "Uxpanapa", distinctId: "Uxpanapa" },
          { name: "Vega de Alatorre", distinctId: "Vega-de-Alatorre" },
          { name: "Veracruz", distinctId: "Veracruz" },
          { name: "Villa Aldama", distinctId: "Villa-Aldama" },
          { name: "Xalapa", distinctId: "Xalapa" },
          { name: "Xico", distinctId: "Xico" },
          { name: "Xoxocotla", distinctId: "Xoxocotla" },
          { name: "Yanga", distinctId: "Yanga" },
          { name: "Yecuatla", distinctId: "Yecuatla" },
          { name: "Zacualpan", distinctId: "Zacualpan" },
          { name: "Zaragoza", distinctId: "Zaragoza" },
          { name: "Zentla", distinctId: "Zentla" },
          { name: "Zongolica", distinctId: "Zongolica" },
          {
            name: "Zontecomatlan de Lopez y Fuentes",
            distinctId: "Zontecomatlan-de-Lopez-y-Fuentes",
          },
          { name: "Zozocolco de Hidalgo", distinctId: "Zozocolco-de-Hidalgo" },
        ],
      },
      {
        name: "Yucatan",
        distinctId: "Yucatan",
        subregions: [
          { name: "Abala", distinctId: "Abala" },
          { name: "Acanceh", distinctId: "Acanceh" },
          { name: "Akil", distinctId: "Akil" },
          { name: "Baca", distinctId: "Baca" },
          { name: "Bokoba", distinctId: "Bokoba" },
          { name: "Buctzotz", distinctId: "Buctzotz" },
          { name: "Cacalchen", distinctId: "Cacalchen" },
          { name: "Calotmul", distinctId: "Calotmul" },
          { name: "Cansahcab", distinctId: "Cansahcab" },
          { name: "Cantamayec", distinctId: "Cantamayec" },
          { name: "Celestun", distinctId: "Celestun" },
          { name: "Cenotillo", distinctId: "Cenotillo" },
          { name: "Chacsinkin", distinctId: "Chacsinkin" },
          { name: "Chankom", distinctId: "Chankom" },
          { name: "Chapab", distinctId: "Chapab" },
          { name: "Chemax", distinctId: "Chemax" },
          { name: "Chichimila", distinctId: "Chichimila" },
          { name: "Chicxulub Pueblo", distinctId: "Chicxulub-Pueblo" },
          { name: "Chikindzonot", distinctId: "Chikindzonot" },
          { name: "Chochola", distinctId: "Chochola" },
          { name: "Chumayel", distinctId: "Chumayel" },
          { name: "Conkal", distinctId: "Conkal" },
          { name: "Cuncunul", distinctId: "Cuncunul" },
          { name: "Cuzama", distinctId: "Cuzama" },
          { name: "Dzan", distinctId: "Dzan" },
          { name: "Dzemul", distinctId: "Dzemul" },
          { name: "Dzidzantun", distinctId: "Dzidzantun" },
          { name: "Dzilam Gonzalez", distinctId: "Dzilam-Gonzalez" },
          { name: "Dzilam de Bravo", distinctId: "Dzilam-de-Bravo" },
          { name: "Dzitas", distinctId: "Dzitas" },
          { name: "Dzoncauich", distinctId: "Dzoncauich" },
          { name: "Espita", distinctId: "Espita" },
          { name: "Halacho", distinctId: "Halacho" },
          { name: "Hocaba", distinctId: "Hocaba" },
          { name: "Hoctun", distinctId: "Hoctun" },
          { name: "Homun", distinctId: "Homun" },
          { name: "Huhi", distinctId: "Huhi" },
          { name: "Hunucma", distinctId: "Hunucma" },
          { name: "Ixil", distinctId: "Ixil" },
          { name: "Izamal", distinctId: "Izamal" },
          { name: "Kanasin", distinctId: "Kanasin" },
          { name: "Kantunil", distinctId: "Kantunil" },
          { name: "Kaua", distinctId: "Kaua" },
          { name: "Kinchil", distinctId: "Kinchil" },
          { name: "Kopoma", distinctId: "Kopoma" },
          { name: "Mama", distinctId: "Mama" },
          { name: "Mani", distinctId: "Mani" },
          { name: "Maxcanu", distinctId: "Maxcanu" },
          { name: "Mayapan", distinctId: "Mayapan" },
          { name: "Merida", distinctId: "Merida" },
          { name: "Mococha", distinctId: "Mococha" },
          { name: "Motul", distinctId: "Motul" },
          { name: "Muna", distinctId: "Muna" },
          { name: "Muxupip", distinctId: "Muxupip" },
          { name: "Opichen", distinctId: "Opichen" },
          { name: "Oxkutzcab", distinctId: "Oxkutzcab" },
          { name: "Panaba", distinctId: "Panaba" },
          { name: "Peto", distinctId: "Peto" },
          { name: "Progreso", distinctId: "Progreso" },
          { name: "Quintana Roo", distinctId: "Quintana-Roo" },
          { name: "Rio Lagartos", distinctId: "Rio-Lagartos" },
          { name: "Sacalum", distinctId: "Sacalum" },
          { name: "Samahil", distinctId: "Samahil" },
          { name: "San Felipe", distinctId: "San-Felipe" },
          { name: "Sanahcat", distinctId: "Sanahcat" },
          { name: "Santa Elena", distinctId: "Santa-Elena" },
          { name: "Seye", distinctId: "Seye" },
          { name: "Sinanche", distinctId: "Sinanche" },
          { name: "Sotuta", distinctId: "Sotuta" },
          { name: "Sucila", distinctId: "Sucila" },
          { name: "Sudzal", distinctId: "Sudzal" },
          { name: "Suma", distinctId: "Suma" },
          { name: "Tahdziu", distinctId: "Tahdziu" },
          { name: "Tahmek", distinctId: "Tahmek" },
          { name: "Teabo", distinctId: "Teabo" },
          { name: "Tecoh", distinctId: "Tecoh" },
          { name: "Tekal de Venegas", distinctId: "Tekal-de-Venegas" },
          { name: "Tekanto", distinctId: "Tekanto" },
          { name: "Tekax", distinctId: "Tekax" },
          { name: "Tekit", distinctId: "Tekit" },
          { name: "Tekom", distinctId: "Tekom" },
          { name: "Telchac Pueblo", distinctId: "Telchac-Pueblo" },
          { name: "Telchac Puerto", distinctId: "Telchac-Puerto" },
          { name: "Temax", distinctId: "Temax" },
          { name: "Temozon", distinctId: "Temozon" },
          { name: "Tepakan", distinctId: "Tepakan" },
          { name: "Tetiz", distinctId: "Tetiz" },
          { name: "Teya", distinctId: "Teya" },
          { name: "Ticul", distinctId: "Ticul" },
          { name: "Timucuy", distinctId: "Timucuy" },
          { name: "Tinum", distinctId: "Tinum" },
          { name: "Tixcacalcupul", distinctId: "Tixcacalcupul" },
          { name: "Tixkokob", distinctId: "Tixkokob" },
          { name: "Tixmehuac", distinctId: "Tixmehuac" },
          { name: "Tixpehual", distinctId: "Tixpehual" },
          { name: "Tizimin", distinctId: "Tizimin" },
          { name: "Tunkas", distinctId: "Tunkas" },
          { name: "Tzucacab", distinctId: "Tzucacab" },
          { name: "Uayma", distinctId: "Uayma" },
          { name: "Ucu", distinctId: "Ucu" },
          { name: "Uman", distinctId: "Uman" },
          { name: "Valladolid", distinctId: "Valladolid" },
          { name: "Xocchel", distinctId: "Xocchel" },
          { name: "Yaxcaba", distinctId: "Yaxcaba" },
          { name: "Yaxkukul", distinctId: "Yaxkukul" },
          { name: "Yobain", distinctId: "Yobain" },
        ],
      },
      {
        name: "Zacatecas",
        distinctId: "Zacatecas",
        subregions: [
          { name: "Apozol", distinctId: "Apozol" },
          { name: "Apulco", distinctId: "Apulco" },
          { name: "Atolinga", distinctId: "Atolinga" },
          { name: "Benito Juarez", distinctId: "Benito-Juarez" },
          { name: "Calera", distinctId: "Calera" },
          {
            name: "Canitas de Felipe Pescador",
            distinctId: "Canitas-de-Felipe-Pescador",
          },
          { name: "Chalchihuites", distinctId: "Chalchihuites" },
          { name: "Concepcion del Oro", distinctId: "Concepcion-del-Oro" },
          { name: "Cuauhtemoc", distinctId: "Cuauhtemoc" },
          {
            name: "El Plateado de Joaquin Amaro",
            distinctId: "El-Plateado-de-Joaquin-Amaro",
          },
          { name: "El Salvador", distinctId: "El-Salvador" },
          { name: "Fresnillo", distinctId: "Fresnillo" },
          { name: "Genaro Codina", distinctId: "Genaro-Codina" },
          {
            name: "General Enrique Estrada",
            distinctId: "General-Enrique-Estrada",
          },
          {
            name: "General Francisco R. Murguia",
            distinctId: "General-Francisco-R.-Murguia",
          },
          {
            name: "General Panfilo Natera",
            distinctId: "General-Panfilo-Natera",
          },
          { name: "Guadalupe", distinctId: "Guadalupe" },
          { name: "Huanusco", distinctId: "Huanusco" },
          { name: "Jalpa", distinctId: "Jalpa" },
          { name: "Jerez", distinctId: "Jerez" },
          { name: "Jimenez del Teul", distinctId: "Jimenez-del-Teul" },
          { name: "Juan Aldama", distinctId: "Juan-Aldama" },
          { name: "Juchipila", distinctId: "Juchipila" },
          { name: "Loreto", distinctId: "Loreto" },
          { name: "Luis Moya", distinctId: "Luis-Moya" },
          { name: "Mazapil", distinctId: "Mazapil" },
          { name: "Melchor Ocampo", distinctId: "Melchor-Ocampo" },
          { name: "Mezquital del Oro", distinctId: "Mezquital-del-Oro" },
          { name: "Miguel Auza", distinctId: "Miguel-Auza" },
          { name: "Momax", distinctId: "Momax" },
          { name: "Monte Escobedo", distinctId: "Monte-Escobedo" },
          { name: "Morelos", distinctId: "Morelos" },
          { name: "Moyahua de Estrada", distinctId: "Moyahua-de-Estrada" },
          { name: "Nochistlan de Mejia", distinctId: "Nochistlan-de-Mejia" },
          { name: "Noria de Angeles", distinctId: "Noria-de-Angeles" },
          { name: "Ojocaliente", distinctId: "Ojocaliente" },
          { name: "Panuco", distinctId: "Panuco" },
          { name: "Pinos", distinctId: "Pinos" },
          { name: "Rio Grande", distinctId: "Rio-Grande" },
          { name: "Sain Alto", distinctId: "Sain-Alto" },
          {
            name: "Santa Maria de la Paz",
            distinctId: "Santa-Maria-de-la-Paz",
          },
          { name: "Sombrerete", distinctId: "Sombrerete" },
          { name: "Susticacan", distinctId: "Susticacan" },
          { name: "Tabasco", distinctId: "Tabasco" },
          { name: "Tepechitlan", distinctId: "Tepechitlan" },
          { name: "Tepetongo", distinctId: "Tepetongo" },
          {
            name: "Teul de Gonzalez Ortega",
            distinctId: "Teul-de-Gonzalez-Ortega",
          },
          {
            name: "Tlaltenango de Sanchez Roman",
            distinctId: "Tlaltenango-de-Sanchez-Roman",
          },
          { name: "Trancoso", distinctId: "Trancoso" },
          {
            name: "Trinidad Garcia de la Cadena",
            distinctId: "Trinidad-Garcia-de-la-Cadena",
          },
          { name: "Valparaiso", distinctId: "Valparaiso" },
          { name: "Vetagrande", distinctId: "Vetagrande" },
          { name: "Villa Garcia", distinctId: "Villa-Garcia" },
          {
            name: "Villa Gonzalez Ortega",
            distinctId: "Villa-Gonzalez-Ortega",
          },
          { name: "Villa Hidalgo", distinctId: "Villa-Hidalgo" },
          { name: "Villa de Cos", distinctId: "Villa-de-Cos" },
          { name: "Villanueva", distinctId: "Villanueva" },
          { name: "Zacatecas", distinctId: "Zacatecas" },
        ],
      },
    ],
  },
];
