export const AsyncForEach = async (
  array: Array<any>,
  callback: any
): Promise<void> => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getSlug = function(projectName: string) {
  let trim = projectName;
  if(projectName.length > 40) trim = projectName.substring( 0, 40 );
  return trim.toLowerCase().replace(/ /g, '-');
};
