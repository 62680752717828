import { AlertMessage } from '../../../common/components/alert-message'

const SchoolsEmpty = () => (
  <AlertMessage
    title="Primeros pasos en Nutrento"
    description="Para poder participar como evaluador de ambientes alimentarios necesitas vincular la(s) escuela(s) en la(s) que eres docente. Usa el botón vincular escuela para buscar y vincular tus escuelas."
  />
)

export default SchoolsEmpty
