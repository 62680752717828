import { getData } from '../services/local-storage.service';
import { API_URL, AUTH_USER_TOKEN_KEY, SCHOOLS_KEY } from '../utils/constants';
import { ServerResponse, Schools, School } from '../utils/models'

async function getMySchools (): Promise<Schools> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${API_URL}/school/my-schools`, fetchOpts);
  const response: ServerResponse<Schools> = await fetchResponse.json();
  return response.data;
}

async function getAllSchools (): Promise<Schools> {
  const mySchools: Array<any> = getData(SCHOOLS_KEY, true);
  const myids = mySchools.map((s:School) => { return s._id})
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${API_URL}/school?conditions={"status": "approved", "archived": "false", "_id": {"$nin": ${JSON.stringify(myids)}}}`, fetchOpts);
  const response: ServerResponse<Schools> = await fetchResponse.json();
  return response.data;
}

async function createSchool (school: School): Promise<boolean> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(school)
  };
  const fetchResponse: Response = await fetch(`${API_URL}/school`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

export {
  getMySchools,
  getAllSchools,
  createSchool
}