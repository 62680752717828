import { getData, saveData } from '../services/local-storage.service';
import { API_URL, AUTH_USER_TOKEN_KEY, USER_KEY } from '../utils/constants';
import { ServerResponse, User } from '../utils/models'
import { logout } from './auth.service';

async function addToMySchools (schools: string[]): Promise<boolean> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({schools})
  };
  const fetchResponse: Response = await fetch(`${API_URL}/users-management/add-schools`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function removeFromMySchools (schools: string[]): Promise<boolean> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({schools})
  };
  const fetchResponse: Response = await fetch(`${API_URL}/users-management/remove-schools`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function getMyUser (): Promise<User | undefined> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
  const fetchResponse: Response = await fetch(`${API_URL}/users-management/my-user`, fetchOpts);
  const response: ServerResponse<User> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  if (response.data) return response.data
  return undefined;
}

async function updateUser (information: any, acceptTerms?: boolean): Promise<boolean> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const user: User = JSON.parse(getData(USER_KEY));
  const body = acceptTerms ? {acceptTerms} : {information}
  if(user?._id){
    const fetchOpts: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    };
    const fetchResponse: Response = await fetch(`${API_URL}/users-management/update/${user._id}`, fetchOpts);
    const response: ServerResponse<any> = await fetchResponse.json();
    if (response.errorCode) throw new Error(response.message.es);
    const updateData:any = {
      information
    }
    if (acceptTerms) updateData["acceptTerms"] = acceptTerms
    saveData(USER_KEY, Object.assign(user, updateData), true)
    return true;
  } else {
    logout();
    return false
  }
}

async function deleteUser (email: string): Promise<ServerResponse<any>> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({email})
  };
  const fetchResponse: Response = await fetch(`${API_URL}/users-management/unsubscribe`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  return response;
}

export {
  addToMySchools,
  removeFromMySchools,
  getMyUser,
  updateUser,
  deleteUser
}