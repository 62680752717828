import { SurveyConfig } from '../services/response';
import { SupportedLanguages } from '../utils/common-types';
import nutrentoSurveyConfig from './nutrento-survey.json';

interface Param {
  key: string;
  value: any;
}

interface RegisteredSurvey {
  id: string;
  label: string;
  config: SurveyConfig;
  params?: Array<Param>;
}

const defaultData = {
}

const defaultComplete = {
  title: {
    default: 'Thank you!',
    es: '¡Gracias!'
  },
  message: {
    default: '<p>We got your opinion.</p><p>All the team at <b>Nutrento</b> will work to give you a better experience.</p>',
    es: '<p>Hemos recibido tu evaluación.</p><p>El equipo <b>Nutrento</b> trabajará para sacar el mayor provecho a estos datos.</p>'
  },
}

const defaultPrivacyLinks = {
  default: "https://www.nutrento.com/en-us/legal/privacy-notice",
  es: "https://www.nutrento.com/es-mx/legal/aviso-de-privacidad",
}

export const registeredSurveys: Array<RegisteredSurvey> = [
  {
    id: 'nutrento',
    label: 'Nutrento',
    config: {
      surveyDef: Object.assign({ _id: 'nutrento' }, nutrentoSurveyConfig),
      data: defaultData,
      preferredLanguage: SupportedLanguages.es,
      complete: defaultComplete,
      privacyPolicy: defaultPrivacyLinks,
    },
    params: []
  }
]
