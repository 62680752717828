type ValidationType = 'email' | 'password' | 'name';

function  validateEmail (email: string): void {
  if (!email) throw new Error('Ingresa tu email.');
  if (email.length < 3) throw new Error('Email incorrecto.');
  if (!email.includes('@')) throw new Error('Email incorrecto.');
  if (!email.includes('.')) throw new Error('Email incorrecto.');
}

function  validatePassword (password: string): void {
  if (!password) throw new Error('Ingresa tu contraseña.');
}

function  validateName (name: string): void {
  if (!name) throw new Error('Ingresa tu nombre.');
}

const fn: any = {
  email: validateEmail,
  password: validatePassword,
  name: validateName
};

function validate (validationType: ValidationType, value: any): void {
  fn[validationType](value);
}

function validateCheck(value: boolean, field: string) {
  if(!value) throw new Error(`Debes aceptar ${field} para poder continuar`);
}

function validateEmpty (value: string, field?:string): void {
  if (field){
    if (value === undefined || value === '' || value ==="<p class=\"editor-paragraph\"><br></p>") throw new Error(`${field} es requerido para continuar`);
  } else{
    if (value === undefined || value === '' || value ==="<p class=\"editor-paragraph\"><br></p>") throw new Error('Todos los campos son requeridos, para continuar.');
  }
}

export {
  validate,
  validateCheck,
  validateEmpty
}