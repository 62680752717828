import { FC } from 'react';
import { Experience } from '../../../utils/models';
import { getSlug } from '../../../utils/functions';
import { SERVER_URL } from '../../../utils/constants';

interface ExperienceCardProps extends Experience {
}

const ExperienceCard: FC<ExperienceCardProps> = ({
  _id,
  projectName,
  projectManagers,
  schoolName,
  location,
  description,
  status
}) => {

  const approved = status === 'approved' ? true : false;

  return (
    <div className="school-card">
      <div style={{textAlign: "end" , fontWeight: 'bold'}}>{approved ? 'Aprobada' : 'En revisión'}</div>
      <h4>{projectName}</h4>
      <br/>
      <p style={{fontSize: '14px'}}><b>Escuelas:</b> {schoolName}</p>
      <p style={{fontSize: '14px'}}><b>Ubicación:</b> {location}</p>
      <p style={{fontSize: '14px'}}><b>Responsables:</b> {projectManagers}</p>
      <div style={{fontSize: '14px'}} dangerouslySetInnerHTML={{ __html: description }}></div>
      <div className="school-card-buttons">
        {approved && 
          <a
            href={`${SERVER_URL}/detalle/${getSlug(projectName)}-${_id}`}
            target="_blank"
            rel="noreferrer"
            className="primary-button"
          >
            Ver publicación
          </a>
        }
      </div>
    </div>
  );
}

export default ExperienceCard;
