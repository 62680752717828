export type NumberSource = any;

const toNumber = (n: NumberSource): string => n === undefined || isNaN(+n) ? '#' : n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const toPercentage = (n: NumberSource, precision: number = 0): string => n === undefined || isNaN(+n) ? '-' : `${(+n).toFixed(precision)}%`;

const toDelta = (n: NumberSource) => !!n ? n > 0 ? `+${n}` : `${n}` : '0';

const roundNumber = (n: NumberSource): number =>
  n === undefined || typeof n === 'string' || isNaN(+n) ? 0 : Math.round(n);

const toFixed = (n: NumberSource, fractionDigits: number = 1): number =>
  n === undefined || isNaN(+n) ? 0 : +(+n).toFixed(fractionDigits);

export {
  toPercentage,
  toNumber,
  roundNumber,
  toFixed,
  toDelta,
}