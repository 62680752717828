import { useCallback, useState } from 'react';
import { getAllSchools } from '../../services/schools.services';
import { Schools } from '../../utils/models';
import { Option } from 'react-multi-select-component';

type UseAllSchoolsHook = () => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  Schools,
  Option[] | undefined
];

const useAllSchools: UseAllSchoolsHook = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [schools, setSchools] = useState<Schools>();
  const [options, setOptions] = useState<Option[]>();

  const loadAllSchools = useCallback(
    async () => {
      try {
        setError(undefined);
        setSchools(undefined);
        setLoading(true);
        const schoolsList = await getAllSchools();
        if (schoolsList) {
          const options: Option[] = schoolsList.map(s => {
            return { 
              value: s._id,
              label: s.name + ' - Clave ' + s.schoolKey + ' - CP ' + s.zipCode + '  ('+ s.country +'-'+ s.region + '-'+ s.subregion + '-' + s.locality + ')',
              key: s._id
            }
          })
          setSchools(schoolsList);
          setOptions(options);
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return [
    loadAllSchools,
    loading,
    error,
    schools,
    options,
  ]

}

export default useAllSchools;