import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { verifyCode } from "../../../services/auth.service";
import { TextInput } from "../../../common/components/text-input";
import { validate, validateEmpty } from "../../../utils/validation";
import { Modal } from "../../../common/components/modal";

const VerifyForm = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [errors, setErrors] = useState(null);
  const [hasParams, setParams] = useState(false);
  const location: any = useLocation();
  const history = useHistory();
  const search = location.search;
  const data:any = {};

  if (search.length > 0) {
    const params = search.split(/[?&]/);
    params.forEach((p:any) => {
      if (!p.length) return;
      const [key, value] = p.split('=');
      data[key] = decodeURI(value);
    })
  }

  useEffect(() => {
    if (data.mail) {
      setEmail(data.mail);
      setParams(true);
    }
  }, [data.mail]);

  const fn: any = {
    email: setEmail,
    code: setCode,
  };

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value.trim();
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  async function verify(e: React.FormEvent) {
    e.preventDefault();
    try {
      validateEmpty(code, "Código");
      validate("email", email);
      setErrors(null);
      setLoading(true);
      const result = await verifyCode(email.trim(), code.trim());
      if (result) {
        setLoading(false);
        setTextModal(
          "¡Listo! Tu cuenta se ha verificado correctamente. Por favor, inicia sesión con el correo y contraseña registrados."
        );
        setLoadingModal2(true);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("err", err);
      if (err?.message) {
        if (err?.message.includes("Unauthorized")) {
          const error: any = "Tu cuenta no pudo ser autenticada, por favor verifica tus datos";
          setErrors(error);
        } 
        else if (err?.message.includes('Failed to fetch')) {
          const e : any ='Se ha producido un error al enviar tu petición, por favor verifica tu conexión a internet e intenta de nuevo'
          setErrors(e);
        }
        else {
          setErrors(err?.message);
        }
      } else {
        setTextModal("Tu cuenta no pudo ser autenticada, verifica tus datos");
        setLoadingModal2(true);
      }
    }
  }

  function clickModal() {
    if (
      textModal ===
      "¡Listo! Tu cuenta se ha verificado correctamente. Por favor, inicia sesión con el correo y contraseña registrados."
    ) {
      history.replace("/auth/login");
    } else {
      setLoadingModal2(false);
    }
  }

  return (
    <>
      <Modal
        isLoading={loading}
        showLoadingAnimation={true}
        text="Procesando..."
      />
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
      <form
        style={{ width: "100%", marginBottom: "12px" }}
        noValidate
        onSubmit={verify}
      >
        <p className="auth-guide">{location.state?.guideMessage}</p>
        {hasParams 
          ? <p className="auth-description">Se verificará el correo {email}</p>
          : <TextInput
              label="Email"
              fieldName="email"
              autoComplete="username"
              value={email}
              onChange={onValueChange}
            />
          }
        <TextInput
          label="Código"
          type="number"
          fieldName="code"
          value={code}
          onChange={onValueChange}
        />
        <input
          type="submit"
          value="Verificar"
          className="form-submit-button w-button"
        />
        <p style={{ margin: "8px 0", color: "red" }}>{errors}</p>
      </form>
    </>
  );
};

export default VerifyForm;
export { VerifyForm };
