import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../common/components/main.layout';
import { TextInput } from '../../common/components/text-input';
import { validateEmpty } from '../../utils/validation';
import { shedules, scopes, countries } from './catalogs';
import { createSchool } from '../../services/schools.services';
import Select from 'react-dropdown-select';
import { AlertMessage } from '../../common/components/alert-message'
import { Modal } from '../../common/components/modal';

const NewSchoolScreen = () => {
  const init: any[] = [];
  const history = useHistory();
  const [name, setName] = useState('');
  const [schoolKey, setSchoolKey] = useState('');
  const [country, setCountry] = useState(init);
  const [region, setRegion] = useState(init);
  const [regions, setRegions] = useState(init);
  const [subregion, setSubregion] = useState(init);
  const [subregions, setSubregions] = useState(init);
  const [locality, setLocality] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [shedule, setShedule] = useState(init);
  const [scope, setScope] = useState(init);
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [errors, setErrors] = useState(null);

  const fn: any = {
    name: setName,
    schoolKey: setSchoolKey,
    locality: setLocality,
    zipCode: setZipCode
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function onDropdownChange(fieldName:string, newValue:any){
    switch (fieldName) {
      case 'country':
        setCountry(newValue);
        setRegion([]);
        setSubregion([]);
        if(newValue.length > 0)
          setRegions(newValue[0].regions);
        else
          setRegions([]);
          setSubregions([]);
        break;
      case 'region':
        setRegion(newValue);
        setSubregion([]);
        if(newValue.length > 0)
          setSubregions(newValue[0].subregions);
        else
          setSubregions([]);
        break;
      case 'subregion':
        setSubregion(newValue);
        break;
      case 'shedule':
        setShedule(newValue);
        break;
      case 'scope':
        setScope(newValue);
        break;
      default:
        break;
    }
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      const countryValue =country[0]?.distinctId;
      const regionValue =region[0]?.distinctId;
      const subregionValue =subregion[0]?.distinctId;
      const sheduleValue =shedule[0]?.distinctId;
      const scopeValue =scope[0]?.distinctId;
      setLoading(true);
      setErrors(null);
      validateEmpty(name);
      validateEmpty(schoolKey);
      validateEmpty(countryValue);
      validateEmpty(regionValue);
      validateEmpty(subregionValue);
      validateEmpty(locality);
      validateEmpty(zipCode);
      validateEmpty(sheduleValue);
      validateEmpty(scopeValue);

      const succes = await createSchool({
        name,
        schoolKey,
        country: countryValue,
        region: regionValue,
        subregion: subregionValue,
        locality,
        zipCode:parseInt(zipCode),
        shedule:sheduleValue,
        scope: scopeValue
      })
      if (succes){
        setTextModal('Tu petición para crera una nueva escuela ya se encuentra en proceso, una vez que se apruebe podras agregarla a tus escuelas en la pantalla vincular escuelas');
      } else{
        setTextModal('No se pudo enviar tu solicitud, revisa tu conexión')
      }
      setLoadingModal2(true)
      setLoading(false);
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      setErrors(err?.message);
      setLoading(false);
    }
  }

  function clickModal () {
    if (textModal === 'No se pudo enviar tu solicitud, revisa tu conexión') {
      setLoadingModal2(false)
    } else{
      history.push("/start");
    }
  }

  return (
    <>
      <PageHeader screenTitle="Nueva escuela" />
      <Modal isLoading={loading} showLoadingAnimation={true} text='Procesando...'/>
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
      <div className="screen-container">
        <AlertMessage
          title="Cuidamos tu privacidad"
          containerStyle={{ marginBottom: '1rem' }}
          description={`
            Puedes solicitar el alta de una nueva escuela ingresando la siguiente información. 
            Los datos que nos proporciones se usarán solo para segmentar la información, 
            en ningún momento vincularemos tus datos a los de tu escuela.
          `}
        />
        <form
          className="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <TextInput
            label="Nombre de la escuela"
            fieldName="name"
            value={name}
            onChange={onValueChange}
          />
          <TextInput
            label="Clave escolar"
            fieldName="schoolKey"
            value={schoolKey}
            onChange={onValueChange}
          />
          <label className="input-label">Pais</label>
          <Select
            className='select-dropdown'
            options={countries}
            values={country}
            valueField='distinctId'
            labelField='name'
            searchBy='name'
            placeholder=''
            onChange={(value) => onDropdownChange('country', value)}
          />
          <label className="input-label">Región</label>
          <Select
            className='select-dropdown'
            options={regions}
            values={region}
            valueField='distinctId'
            labelField='name'
            searchBy='name'
            placeholder=''
            disabled= {!(regions.length > 0)}
            onChange={(value) => onDropdownChange('region', value)}
          />
          <label className="input-label">SubRegión</label>
          <Select
            className='select-dropdown'
            options={subregions}
            values={subregion}
            valueField='distinctId'
            labelField='name'
            searchBy='name'
            placeholder=''
            disabled= {!(subregions.length > 0)}
            onChange={(value) => onDropdownChange('subregion', value)}
          />
          <TextInput
            label="Colonia"
            fieldName="locality"
            value={locality}
            onChange={onValueChange}
          />
          <TextInput
            label="Codigo Postal"
            fieldName="zipCode"
            type='number'
            value={zipCode}
            onChange={onValueChange}
          />
          <label className="input-label">Horario</label>
          <Select
            className='select-dropdown'
            options={shedules}
            values={shedule}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('shedule', value)}
          />
          <label className="input-label">Ambito</label>
          <Select
            className='select-dropdown'
            options={scopes}
            values={scope}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('scope', value)}
          />
          <input
            disabled={loading}
            type="submit"
            value={loading ? 'Procesando...' : 'Solicitar alta'}
            className="form-submit-button w-button"
          />
          <p style={{margin: '8px 0', color: 'red'}}>
            {errors}
          </p>
        </form>
      </div>
    </>
  );
};

export default NewSchoolScreen;
