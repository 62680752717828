export const shedulesUser = [
  {
    distinctId: "matutino",
    display: {
      es: "Matutino",
      default: "Morning",
    },
  },
  {
    distinctId: "vespertino",
    display: {
      es: "Vespertino",
      default: "Evening",
    },
  },
  {
    distinctId: "ambos",
    display: {
      es: "Ambos",
      default: "Both",
    },
  },
];

export const grades = [
  {
    distinctId: "1",
    display: {
      es: "1",
      default: "1",
    },
  },
  {
    distinctId: "2",
    display: {
      es: "2",
      default: "2",
    },
  },
  {
    distinctId: "3",
    display: {
      es: "3",
      default: "3",
    },
  },
  {
    distinctId: "4",
    display: {
      es: "4",
      default: "4",
    },
  },
  {
    distinctId: "5",
    display: {
      es: "5",
      default: "5",
    },
  },
  {
    distinctId: "6",
    display: {
      es: "6",
      default: "6",
    },
  },
  {
    distinctId: "98",
    display: {
      es: "Multigrado",
      default: "Multigrado",
    },
  },
  {
    distinctId: "99",
    display: {
      es: "Director",
      default: "Director",
    },
  },
];

export const genders = [
  {
    distinctId: "mujer",
    display: {
      es: "Mujer",
      default: "Female",
    },
  },
  {
    distinctId: "hombre",
    display: {
      es: "Hombre",
      default: "Male",
    },
  },
];

export const maritalStatusCatalog = [
  {
    distinctId: "casado",
    display: {
      es: "Casad@",
      default: "Married",
    },
  },
  {
    distinctId: "soltero",
    display: {
      es: "Solter@",
      default: "Single",
    },
  },
  {
    distinctId: "divorciado",
    display: {
      es: "Divorciad@",
      default: "Divorced",
    },
  },
  {
    distinctId: "union_libre",
    display: {
      es: "Unión Libre",
      default: "Cohabitation",
    },
  },
]

export const p1_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "Sin Instrucción o Preescolar",
      default: "Sin Instrucción o Preescolar",
    },
  },
  {
    distinctId: 6,
    display: {
      es: "Primaria Incompleta",
      default: "Primaria Incompleta",
    },
  },
  {
    distinctId: 11,
    display: {
      es: "Primaria Completa",
      default: "Primaria Completa",
    },
  },
  {
    distinctId: 12,
    display: {
      es: "Secundaria Incompleta",
      default: "Secundaria Incompleta",
    },
  },
  {
    distinctId: 18,
    display: {
      es: "Secundaria Completa",
      default: "Secundaria Completa",
    },
  },
  {
    distinctId: 23,
    display: {
      es: "Preparatoria Incompleta",
      default: "Preparatoria Incompleta",
    },
  },
  {
    distinctId: 27,
    display: {
      es: "Preparatoria Completa",
      default: "Preparatoria Completa",
    },
  },
  {
    distinctId: 36,
    display: {
      es: "Licenciatura Incompleta",
      default: "Licenciatura Incompleta",
    },
  },
  {
    distinctId: 59,
    display: {
      es: "Licenciatura Completa",
      default: "Licenciatura Completa",
    },
  },
  {
    distinctId: 85,
    display: {
      es: "Posgrado",
      default: "Posgrado",
    },
  },
];

export const p2_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "0",
      default: "0",
    },
  },
  {
    distinctId: 24,
    display: {
      es: "1",
      default: "1",
    },
  },
  {
    distinctId: 47,
    display: {
      es: "2 ó más",
      default: "2 ó más",
    },
  },
]

export const p3_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "0",
      default: "0",
    },
  },
  {
    distinctId: 22,
    display: {
      es: "1",
      default: "1",
    },
  },
  {
    distinctId: 43,
    display: {
      es: "2 ó más",
      default: "2 ó más",
    },
  },
]

export const p4_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "No tiene",
      default: "No tiene",
    },
  },
  {
    distinctId: 32,
    display: {
      es: "Sí tiene",
      default: "Sí tiene",
    },
  },
]

export const p5_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "0",
      default: "0",
    },
  },
  {
    distinctId: 15,
    display: {
      es: "1",
      default: "1",
    },
  },
  {
    distinctId: 31,
    display: {
      es: "2",
      default: "2",
    },
  },
  {
    distinctId: 46,
    display: {
      es: "3",
      default: "3",
    },
  },
  {
    distinctId: 61,
    display: {
      es: "4 ó más",
      default: "4 ó más",
    },
  },
]

export const p6_nss_answers = [
  {
    distinctId: 0,
    display: {
      es: "0",
      default: "0",
    },
  },
  {
    distinctId: 8,
    display: {
      es: "1",
      default: "1",
    },
  },
  {
    distinctId: 16,
    display: {
      es: "2",
      default: "2",
    },
  },
  {
    distinctId: 24,
    display: {
      es: "3",
      default: "3",
    },
  },
  {
    distinctId: 32,
    display: {
      es: "4 ó más",
      default: "4 ó más",
    },
  },
]

export const degrees = [
  {
    distinctId: "normalista",
    display: {
      es: "Normalista",
      default: "Normalista",
    },
  },
  {
    distinctId: "licenciatura_institucion_publica",
    display: {
      es: "Licenciatura en educación en institución pública",
      default: "Licenciatura en educación en institución pública",
    },
  },
  {
    distinctId: "licenciatura_institucion_privada",
    display: {
      es: "Licenciatura en educación en institución privaba",
      default: "Licenciatura en educación en institución privaba",
    },
  },
  {
    distinctId: "psicologia",
    display: {
      es: "Psicología",
      default: "Psicología",
    },
  },
  {
    distinctId: "otra",
    display: {
      es: "Otra",
      default: "Otra",
    },
  },
]

export const educationalLevels = [
  {
    distinctId: "nivel_tecnico",
    display: {
      es: "Nivel técnico",
      default: "Nivel técnico",
    },
  },
  {
    distinctId: "licenciatura",
    display: {
      es: "Licenciatura",
      default: "Licenciatura",
    },
  },
  {
    distinctId: "especialidad",
    display: {
      es: "Especialidad",
      default: "Especialidad",
    },
  },
  {
    distinctId: "maestria",
    display: {
      es: "Maestría",
      default: "Maestría",
    },
  },
  {
    distinctId: "doctorado",
    display: {
      es: "Doctorado",
      default: "Doctorado",
    },
  },
]

export const contracts = [
  {
    distinctId: "fijo",
    display: {
      es: "Base o contrato fijo",
      default: "Base o contrato fijo",
    },
  },
  {
    distinctId: "temporal",
    display: {
      es: "Contrato temporal",
      default: "Contrato temporal",
    },
  },
]

export const lastDegree = [
  {
    distinctId: true,
    display: {
      es: "Sí",
      default: "Sí",
    },
  },
  {
    distinctId: false,
    display: {
      es: "No",
      default: "No",
    },
  },
]