import React, { FC } from 'react';
import { School } from '../../../utils/models';

interface SchoolCardProps extends School {
  onClick?: any;
  gotoEvaluations?: any;
  isOnline: boolean;
}

const SchoolCard: FC<SchoolCardProps> = ({
  _id,
  name,
  onClick,
  gotoEvaluations,
  isOnline,
}) => {

  return (
    <div className="school-card">
      <p style={{ fontWeight: 'bold' }}>{name}</p>
      <div className="school-card-buttons">
        <input
          type="button"
          style={{
            backgroundColor: !isOnline ? 'gray' : '#6D31AC',
            cursor: !isOnline ? 'auto': 'pointer',
            marginRight: '0.5rem'
          }}
          value={'Ver mis evaluaciones'}
          disabled={!isOnline}
          className="primary-button"
          onClick={() => gotoEvaluations && gotoEvaluations(_id)}
        />
        <input
          type="button"
          value={'Evaluar'}
          className="primary-button"
          onClick={() => onClick && onClick(_id)}
        />
      </div>
    </div>
  );
}

export default SchoolCard;
