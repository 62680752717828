import React, {FC, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {TextInput} from '../../../common/components/text-input';
import {register} from '../../../services/auth.service';
import {validate, validateCheck} from '../../../utils/validation';
import PasswordChecklist from "react-password-checklist";
import { Modal } from '../../../common/components/modal';
import { SERVER_URL } from '../../../utils/constants';

type RegisterFormProps = {};

const RegisterForm: FC<RegisterFormProps> = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [spaces, setSpaces] = useState(false);
  const history = useHistory();

  const fn: any = {
    name: setName,
    email: setEmail,
    password: setPassword,
  };

  function validateSpaces(value: string){
    const res = /\s/.test(value);
    if (res) setSpaces(true);
    else setSpaces(false);
  }

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const fieldName: string = e.target.name;
    let newValue: string;
    if (fieldName === 'email') newValue = e.target.value.trim();
    else newValue = e.target.value;
    fn[fieldName](newValue);
    if (fieldName === 'password') validateSpaces(newValue)
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>): void {
    setPrivacy(e.target.checked);
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      validate('name', name);
      validate('email', email);
      validate('password', password);
      validateCheck(privacy, "el aviso de privacidad");
      setErrors(null);
      const {message, data} = await register(name, email, password);

      if (data) {
        setTextModal(`¡Listo! Ya tienes una cuenta Nutrento. Por favor, verifica tu correo mediante el codigo de confirmación que mandamos al correo ${email}`)
        setLoading(false);
        setLoadingModal2(true)
      } else {
        const err: any = message && message.es;
        setErrors(err);
        setLoading(false);
      }
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      if (err?.message.includes('Failed to fetch')){
        const e : any ='Se ha producido un error al enviar tu petición, por favor verifica tu conexión a internet e intenta de nuevo'
        setErrors(e);
      }
      else {
        setErrors(err?.message);
      }
      setLoading(false);
    }
  }

  function clickModal () {
    if (textModal === 'Error al registrarse') {
      setLoadingModal2(false)
    } else{
      history.replace(`/auth/verify?mail=${email}`);
    }
  }

  return (
    <>
      <Modal isLoading={loading} showLoadingAnimation={true} text='Procesando...'/>
      <Modal 
        isLoading={loadingModal2} 
        showLoadingAnimation={false} 
        text={textModal} 
        onClick={() => clickModal()}
      />
      <form
        style={{width: '100%'}}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Nombre completo"
          fieldName="name"
          type="text"
          value={name}
          onChange={onValueChange}
        />
        <TextInput
          label="Email"
          fieldName="email"
          type="text"
          autoComplete="username"
          value={email}
          onChange={onValueChange}
        />
        <TextInput
          label="Contraseña"
          fieldName="password"
          type="password"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="current-password"
          value={password}
          onChange={onValueChange}
        />
        <div style={{fontSize: '12px'}}>
          Asegúrate de cumplir con los aspectos de una contraseña segura:
        </div>
        <PasswordChecklist
          className='password-check-list'
          iconSize={8}
          rules={[
            "capital",
            "specialChar",
            "minLength",
            "lowercase",
            "number",
          ]}
          minLength={8}
          value={password}
          messages={{
            minLength: "8 caracteres.",
            specialChar:
              "Al menos un caracter especial.",
            number: "Al menos un número",
            capital: "Al menos una letra mayúscula.",
            lowercase: "Al menos una letra minúscula.",
          }}
        />
        <ul className="password-check-list" style={{ padding: 0, marginBottom: "14px"}}><li className="sc-gTRrQi checklist-li">
          { spaces ?
            <svg className="sc-ewnqHT checklist-svg checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 512 512"><path fill="#FF0033" d="M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z"></path></svg>
            : <svg className="sc-ewnqHT checklist-svg checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 512 512"><path fill="#4BCA81" d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path></svg>
          }
          <span style={{opacity: spaces ? 0.5 : 1}}>No debe contener espacios en blanco.</span></li>
        </ul>
        <div className="privacy-box">
          <input type="checkbox"
           id="privacy" 
           name="privacy" 
           className='input-privacy' 
           onChange={handleCheck}
          />
          <label htmlFor="privacy" className='label-privacy'>He leído y acepto el </label>
          <a href={`${SERVER_URL}/aviso-de-privacidad`} target="_blank" rel="noreferrer" className="form-link">
            Aviso de Privacidad
          </a>
        </div>
        <input
          disabled={loading}
          type="submit"
          value={loading ? 'Procesando...' : 'Registrarse'}
          className="form-submit-button w-button"
        />
        <p style={{margin: '8px 0', color: 'red'}}>
          {errors}
        </p>
      </form>
      <div className="flex-spacer show-md"/>
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link" replace>
          Regresar a inicio de sesión
        </Link>
      </div>
    </>
  );
}

export default RegisterForm;
export {
  RegisterForm,
};
