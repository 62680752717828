import { FC } from "react";
import { useLocation, Link } from "react-router-dom";

type HelpProps = {};

const Help: FC<HelpProps> = () => {
  const location: any = useLocation();

  return (
    <div style={{ width: "100%" }}>
      <p className="auth-guide">{location.state?.guideMessage}</p>
      <p className="label-privacy">
        Descarga el manual de la aplicación{" "}
        <a
          href="https://assets.nutrento.org/files/Manual.pdf"
          target="_blank"
          rel="noreferrer"
          className="form-link"
        >
          aqui
        </a>
      </p>
      <br />
      <p className="label-privacy">Si tienes más dudas puedes contactarnos:</p>
      <br />
      <p className="label-privacy">
        Teléfono{" "}
        <a
          href="tel:+527717172000"
          target="_blank"
          rel="noreferrer"
          className="form-link"
        >
          (52+) 771 71 72000 Ext. 4312
        </a>
      </p>
      <p className="label-privacy">
        Correo{" "}
        <a
          href="mailto:observatorio_nutricionales@uaeh.edu.mx"
          target="_blank"
          rel="noreferrer"
          className="form-link"
        >
          observatorio_nutricionales@uaeh.edu.mx
        </a>
      </p>
      <br />
      <p className="label-privacy">Coordinación Técnica NUTRENTO</p>
      <p className="label-privacy">
        Instituto de Ciencias de la Salud de la Universidad Autónoma del Estado
        de Hidalgo
      </p>
      <p className="label-privacy">Ex Hacienda la Concepción s/n.</p>
      <p className="label-privacy">Carr. Pachuca - Tilcuautla C.P.</p>
      <p className="label-privacy">42060. Tilcuautla, Hgo.</p>
      
      <br />
      <br />
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link" replace>
          Regresar a inicio de sesión
        </Link>
      </div>
    </div>
  );
};

export default Help;
export { Help };
