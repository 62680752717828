import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from '../../services/auth.service';
import { useAppDispatch } from '../../store/hooks';
import { setOnline } from '../../store/slices/online';
import './main.layout.css'

interface PageHeaderProps {
  screenTitle?: string
}

export const PageHeader: FC<PageHeaderProps> = ({
  screenTitle = '',
  children,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation()
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const isHome = location.pathname === '/start' || location.pathname === '/terms'

  function logOut() {
    logout();
    history.replace('/auth/login');
  }

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
      dispatch(setOnline(true));
    }
    function offlineHandler() {
      setIsOnline(false);
      dispatch(setOnline(false));
    }
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, [dispatch]);

  return(
    <>
    <header className="app-navbar">
      { isHome ? (
        <img src="/brand/favicon.png" className="app-navbar-icon" alt='favicon'/>
      ) : (
        <img style={{cursor:'pointer'}} src="/images/back-icon.png" className="app-navbar-icon" alt='back-icon' onClick={() => history.goBack()} />
      ) }
      <h2 className="app-navbar-screen-title">{screenTitle}</h2>
      {isHome && (
        <img style={{cursor:'pointer'}} src="/images/logout-icon.png" className="app-navbar-icon" alt='logout-icon' onClick={logOut}/>
      )}
      {children}
    </header>
    <div className='status-connection-container'>
      {isOnline ? (
        <div style={{backgroundColor: 'green', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"}}>En linea</div>
      ) : (
        <div style={{backgroundColor: 'red', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"}}>No hay conexión a internet.</div>
      )}
    </div>
</>
  );
}

export const MainLayout: FC = ({ children = undefined }) => (
  <main className="survey-main">
    <div className="container">
      {children}
    </div>
  </main>
);
