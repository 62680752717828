import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { School } from '../../utils/models';

// Define a type for the slice state
interface SchoolsState {
  available: School[];
  selected?: School;
}

// Define the initial state using that type
const initialState: SchoolsState = {
  available: [],
};

export const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailable: (state, action: PayloadAction<School[]>) => {
      state.available = action.payload;
    },
    setSchool: (state, action: PayloadAction<string>) => {
      const nextSchool = state.available.find(st => st._id === action.payload);
      if (nextSchool) {
        state.selected = nextSchool;
      }
    },
  },
})

export const { setAvailable, setSchool } = schoolsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSlice = (state: RootState) => state.schools;
export const selectSchools = (state: RootState) => state.schools.available;

export default schoolsSlice.reducer;