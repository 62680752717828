import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../common/components/main.layout';
import { TextInput, TextAreaInput } from '../../common/components/text-input';
import { Modal } from '../../common/components/modal';
import { validateEmpty } from '../../utils/validation';
import { Experience } from '../../utils/models';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { $generateHtmlFromNodes } from "@lexical/html";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import  ToolbarPlugin from "./widgets/toolbar.plugin"
import theme from "./widgets/theme"
import { EditorState, LexicalEditor} from "lexical"
import { createExperience } from '../../services/experience.service';
import { AlertMessage } from '../../common/components/alert-message'
import { FilePond, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import AutoLinkPlugin from "./widgets/autolink.plugin";
import { SERVER_URL } from '../../utils/constants';
import Select from 'react-dropdown-select';
import { categories } from '../schools/catalogs';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType)

const exampleProjectImages = [
  "image1",
  "image2",
  "image3",
  "image4",
]

const exampleProductionImages = [
  "image6",
  "image9",
  "image7",
  "image8",
]

const ExperienceScreen = () => {
  const initial:any =[];
  const history = useHistory();
  const [projectName, setProjectName] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [projectManagers, setProjectManagers] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [projectImages, setProjectImages] = useState(initial);
  const [productionImages, setProductionImages] = useState(initial);
  const [projectNetworks, setProjectNetworks] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const [imagesExample, setImagesExample] = useState(initial);
  const [errors, setErrors] = useState(null);
  const [category, setCategory] = useState(initial);

  const fn: any = {
    projectName: setProjectName,
    schoolName: setSchoolName,
    projectManagers: setProjectManagers,
    location: setLocation,
    description: setDescription,
    projectNetworks: setProjectNetworks,
    category: setCategory
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function onTextAreaChange (e: React.ChangeEvent<HTMLTextAreaElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function onChange(editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      fn['description'](htmlString);
    });
  }

  function onDropdownChange(fieldName:string, newValue:any){
    fn[fieldName](newValue);
  }

  function onError(error:any) {
    console.error(error);
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      setErrors(null);
      const categoryValue = category[0]?.distinctId;
      validateEmpty(projectName, "Nombre del proyecto o programa");
      validateEmpty(schoolName, "Nombre de escuela(s) en dónde se ejecuta el proyecto");
      validateEmpty(projectManagers, "Nombre del Responsable(s) del proyecto o grupo de docentes");
      validateEmpty(location, "Localidad/Municipio/Región o Estado/País");
      validateEmpty(description, "Descripción");
      validateEmpty(categoryValue, "Categoría");

      const experience:Experience = {
        projectName,
        schoolName,
        projectManagers,
        location,
        description,
        category: categoryValue
      }
      if (projectNetworks !== '') experience.projectNetworks = projectNetworks;
      if (projectImages.length > 0) {
        const images = projectImages.map((image: any) => {return image.file})
        experience.projectImages = images;
      }
      if (productionImages.length > 0) {
        const images = productionImages.map((image: any) => {return image.file})
        experience.productionImages = images;
      }
      const res = await createExperience(experience);
      if (res){
        setTextModal(`Gracias por compartir tu experiencia, la revisaremos y si es aprobada se publicará en la página: ${SERVER_URL}/red-de-vinculacion`);
      } else{
        setTextModal('No se pudo enviar tu experiencia, revisa tu conexión')
      }
      setLoadingModal2(true)
      setLoading(false);
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      setErrors(err?.message);
      setLoading(false);
    }
  }

  function clickModal () {
    if (textModal === 'No se pudo enviar tu experiencia, revisa tu conexión' || textModal === 'Ejemplos de carga:') {
      setLoadingModal2(false)
      setShowHelp(false)
      setTextModal('')
    } else{
      history.push("/start");
    }
  }

  function toogleHelp (type: string) {
    setTextModal('Ejemplos de carga:')
    setLoadingModal2(true)
    setShowHelp(true)
    if (type === 'exampleProjectImages') setImagesExample(exampleProjectImages)
    else setImagesExample(exampleProductionImages)
  }

  const initialConfig = {
    namespace: 'Editor',
    theme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  return (
    <>
      <PageHeader screenTitle="Compartir Experiencia" />
      <Modal isLoading={loading} showLoadingAnimation={true} text='Subiendo experiencia'/>
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      >
        { showHelp && (
            <div className='container-image-experience'>
              {imagesExample.map((image:any) => (
                 <img className='image-experience' src={`/images/${image}.png`} alt="" />
              ))}
            </div>
          )
        }
      </Modal>
      <div className="screen-container md-wide">
        <AlertMessage
          title=""
          containerStyle={{ marginBottom: '1rem' }}
          webLink={{ display: 'Red de vinculación Nutrento', route:`${SERVER_URL}/red-de-vinculacion`}}
          description={`
            Únete a nuestra red de vinculación compartiendo tu proyecto, con cada iniciativa, 
            aportarás información vital para crear investigaciones regionales que ayuden a 
            coaccionar estrategias de mejora, pues tu intervención, magnificarán las tareas 
            de cambio dentro de la oferta alimentaria. Una vez que envíes tu experiencia esta 
            será revisada y si es aprobada se publicará en la página principal:
          `}
        />
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextInput
            label="Nombre del proyecto o programa"
            fieldName="projectName"
            value={projectName}
            onChange={onValueChange}
          />
          <TextInput
            label="Nombre de escuela(s) en dónde se ejecuta el proyecto"
            fieldName="schoolName"
            value={schoolName}
            onChange={onValueChange}
          />
          <TextInput
            label="Localidad/Municipio/Región o Estado/País"
            fieldName="location"
            value={location}
            onChange={onValueChange}
          />
          <TextAreaInput
            label="Nombre del Responsable(s) del proyecto o grupo de docentes"
            fieldName="projectManagers"
            value={projectManagers}
            onChange={onTextAreaChange}
          />
          <label className="input-label">Descripción</label>
          <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
              <ToolbarPlugin />
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input" />}
                  placeholder={<div className="editor-placeholder">Descripción de la experiencia
                  (objetivo, qué hizo, para qué es la experiencia, resultados) Proporciona la página web y/o redes sociales del proyecto si cuenta con ellas
                  </div>}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin onChange={onChange} ignoreSelectionChange/>
                <HistoryPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin />
              </div>
            </div>
          </LexicalComposer>
          <label className="input-label">Categoría</label>
          <Select
            className='select-dropdown'
            options={categories}
            values={category}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('category', value)}
          />
          <p className="text-example-experience">Elige la categoría que corresponda mejor con tu experiencia</p>
          <FilePond
            files={projectImages}
            onupdatefiles={setProjectImages}
            allowMultiple={true}
            allowProcess={false}
            allowFileTypeValidation={true}
            allowDrop={false}
            acceptedFileTypes={['image/*']}
            credits={false}
            name="projectImages"
            labelIdle='Si cuenta con algún material, compártenos una fotografía o referencia <span class="filepond--label-action">Elegir imagenes</span>'
          />
          <p className="text-example-experience" onClick={() => toogleHelp('exampleProjectImages')}>No incluir imagenes borrosas. <span style={{fontWeight: 'bold'}}> Presiona aqui para ver ejemplos</span>:</p>
          <FilePond
            files={productionImages}
            onupdatefiles={setProductionImages}
            allowMultiple={true}
            allowProcess={false}
            allowFileTypeValidation={true}
            allowDrop={false}
            acceptedFileTypes={['image/*']}
            credits={false}
            name="productionImages"
            labelIdle='¿Tiene fotografías de las producciones de sus alumnos/sugestión? Si incluye fotos de niños, favor de guardar el anonimato <span class="filepond--label-action">Elegir imagenes</span>'
          />
          <p className="text-example-experience" onClick={() => toogleHelp('exampleProductionImages')}>No mostrar las caras de los niños deberán ser censuradas. <span style={{fontWeight: 'bold'}}> Presiona aqui para ver ejemplos</span>:</p>
          {/*<TextInput
            label="Proporciona la página web y/o redes sociales del proyecto si cuenta con ellas"
            fieldName="projectNetworks"
            value={projectNetworks}
            onChange={onValueChange}
          />*/}

          <input
            disabled={loading}
            type="submit"
            value={loading ? 'Procesando...' : 'Compartir'}
            className="form-submit-button w-button"
          />
          <p style={{color: 'red'}}>
            {errors}
          </p>
        </form>
      </div>
    </>
  );
};

export default ExperienceScreen;
