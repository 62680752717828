import { useEffect, useState } from "react";
import { PageHeader } from "../../common/components/main.layout";
import { getMyExperiences } from "../../services/experience.service";
import { Experience } from "../../utils/models";
import { AlertMessage } from "../../common/components/alert-message";
import ExperienceCard from "./widgets/experience.card";

const ExperiencesListScreen = () => {
  const [loading, setLoading] = useState(true);
  const [experiences, setExperiences] = useState<Experience[] | undefined>(undefined);
  
  useEffect(() => {
    async function loadExperiences() {
      try {
        setLoading(true);
        const result = await getMyExperiences();
        setExperiences(result);
      } catch (err: any) {
        console.log("Error al obtener datos de experiencias: " + err?.message);
      } finally {
        setLoading(false);
      }
    }
    loadExperiences();
  }, []);

  return (
    <>
      <PageHeader screenTitle="Mis Experiencias" />
      {!loading && (
        <div className="screen-container md-wide">
          <AlertMessage
            title="Bienvenido a nuestra red de vinculación"
            containerStyle={{ marginBottom: "1rem" }}
            description={`
            Comparte tu proyecto, con cada iniciativa, aportarás información vital para crear investigaciones regionales que ayuden a 
            coaccionar estrategias de mejora, pues tu intervención, magnificarán las tareas de cambio dentro de la oferta alimentaria. Todos los proyectos deben pasar por un periodo de aprobacion para poder aparecer en nuestra página nutrento.org, aqui podras visualizar todos tus proyectos que has decidido compartir y si estos ya fueron aprobados o no, si aun no tienes proyectos usa el link compartir experiencia para comenzar a participar en nuestra red.
            `}
            link={{
              display: 'Compartir experiencia',
              route: '/create-experience'
            }}
          />
          <br/>
          {loading ? (
            <p>Cargando datos...</p>
          ) : experiences?.length === 0 ? (
            null
          ) : (
            experiences?.map((exp: Experience) => (
              <ExperienceCard key={exp._id} {...exp}/>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default ExperiencesListScreen;
