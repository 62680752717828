import { AlertMessage } from '../../../common/components/alert-message'

const EvaluationsEmpty = () => (
  <AlertMessage
    title=""
    description="Aún no tienes evaluaciones completadas para esta escuela, completa una evaluacion para ver tus resultados."
    link={{
      display: 'Regresar',
      route: '/start'
    }}
  />
)

export default EvaluationsEmpty;
