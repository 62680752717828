import { LocalizedText, ServerResponse, SupportedLanguages } from '../utils/common-types';
import { API_URL, AUTH_USER_TOKEN_KEY, RESPONSES_KEY, SURVEYID, USER_KEY } from '../utils/constants';
import { User } from '../utils/models';
import { logout } from './auth.service';
import { getData, saveData } from './local-storage.service';

interface AnyQuestion {
  type: string;
  name: string;
  title?: any;
  valueName?: string;
  isRequired?: boolean;
}

interface Page {
  name: string;
  elements: Array<AnyQuestion>
}

export interface SurveyDef {
  _id: string;
  pages: Array<Page>;
}

interface RemoteSurveyConfig {
  answer: any;
  config: SurveyDef;
  language: SupportedLanguages;
  privacyPolicy: LocalizedText;
  thankyouMessage: {
    title: LocalizedText;
    message: LocalizedText;
  };
}

export interface SurveyConfig {
  answerId?: string;
  surveyDef?: SurveyDef;
  data?: any;
  preferredLanguage?: SupportedLanguages;
  complete?: Record<string, LocalizedText>;
  privacyPolicy?: LocalizedText;
}

interface ServerError {
  errorCode: string;
  message: LocalizedText;
}

interface SaveResponse {
  answerId: string;
  data: any;
  rawResponse: any;
  language: SupportedLanguages
}

class ResponseService {

  loadSurveyDef = async (schoolId: string): Promise<SurveyConfig> => {
    const token = getData(AUTH_USER_TOKEN_KEY);
    const fetchOpts: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({schoolId, surveyId: SURVEYID})
    };
    let URL = `${API_URL}/survey/get-config`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    if (fetchResponse.status >= 400) {
      let errorMessage: LocalizedText = {
        es: 'Lo sentimos. Ocurrió un problema al cargar la encuesta.',
        default: 'Sorry! We had a problem loading your survey.'
      };
      const errorDetails: ServerError = await fetchResponse.json();
      if (errorDetails.message) {
        errorMessage = errorDetails.message;
      }
      // @ts-ignore
      throw new Error('ERROR_RETREIVING_SURVEY_DEF', { cause: errorMessage });
    }
    const response: ServerResponse<RemoteSurveyConfig> = await fetchResponse.json();
    const { answer, config, language, thankyouMessage, privacyPolicy } = response.data

    return {
      answerId: answer._id,
      surveyDef: config,
      data: answer.data,
      preferredLanguage: language,
      complete: thankyouMessage,
      privacyPolicy
    };
  };

  formatResponse = (response: any): any => {
    let multipleQuestions = [];
    let finalResponse: any = {};
    for (const k in response) {
      if (Array.isArray(response[k])) {
        multipleQuestions.push(k);
      } else if (typeof response[k] === 'object') {
        for (const name in response[k]) {
          finalResponse[name] = response[k][name];
        }
      } else {
        finalResponse[k] = response[k];
      }
    }
    multipleQuestions.forEach(k => {
      response[k].forEach((answerId: string) => {
        finalResponse[`${k}_${answerId}`] = true;
      });
    })
    return finalResponse;
  }

  saveResponse = async (responseId: string, response: any, rawResponse: any, language: SupportedLanguages): Promise<any> => {
    const formattedResponse = this.formatResponse(response);
    const body = JSON.stringify({
      data: formattedResponse,
      rawResponse,
      language
    });
    console.log('Saving response', body);
    const token = getData(AUTH_USER_TOKEN_KEY);
    const fetchOpts: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    };
    let URL = `${API_URL}/answer/save/${responseId}`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    const updateResponse = await fetchResponse.json();
    return updateResponse;
  };

  saveResponseLocal (response: SaveResponse) {
    let responses: any = getData(RESPONSES_KEY, true);
    const user: User = getData(USER_KEY, true);
    if (responses) {
      if (user) {
        if(responses[user._id]) {
          const index = responses[user._id].findIndex((r:any) => r.answerId === response.answerId);
          console.log(index)
          if (index >= 0){
            responses[user._id].splice(index,1)
          }
          responses[user._id].push(response);
        }
        else 
          responses[user._id] = [response];
      }
      else logout();
    } else {
      if (user) responses = {[user._id]: [response]};
      else logout();
    }
    console.log(responses)
    saveData(RESPONSES_KEY, responses, true);
  }

}

export default new ResponseService();