import { useState, useEffect } from 'react';
import * as SurveyJS from 'survey-react';
import surveyClasses from './surveyjs-classes.json';
import ResponseService, { SurveyConfig } from '../../services/response';
import { registeredSurveys } from '../../surveys/index';
import { PageHeader, MainLayout } from '../../common/components/main.layout';
import { RouteComponentProps } from 'react-router-dom';
import { SupportedLanguages } from '../../utils/common-types';
import { useAppSelector } from '../../store/hooks';
import { selectOnlineSlice } from '../../store/slices/online';
import { getData } from '../../services/local-storage.service';
import { RESPONSES_KEY, SURVEYS_KEY, USER_KEY } from '../../utils/constants';

SurveyJS.surveyLocalization.locales['es'] = Object.assign(SurveyJS.surveyLocalization.locales['es'], {
  requiredError: "Por favor responde la pregunta.",
  otherRequiredError: "Por favor agrega el valor de Otro.",
  requiredInAllRowsError: 'Selecciona una respuesta para cada una de las filas.',
});

const setCssClasses = (survey: any, options: any) => {
  switch (options.question.getType()) {
    case 'rating':
      if (options.question.rateMax === 10) {
        options.cssClasses.item = 'nps-button';
        options.cssClasses.itemText = 'nps-label';
      }
      if (options.question.rateMax === 5) {
        options.cssClasses.item = 'radio-button';
        options.cssClasses.itemText = 'radio-button-label';
      }
      break;

    case 'matrix':
      if (options.question.visibleRows?.length === 1) {
        options.cssClasses.root += ' one-row-matrix';
        options.cssClasses.content += ' wide';
      }
      if (options.question.columns?.length > 4) {
        options.cssClasses.root += ' full-matrix';
        options.cssClasses.content += ' wide';
      }
      break;

    case 'html':
      console.log(options.question)
      if (options.question.html?.includes('<h3>')) {
        options.cssClasses.mainRoot += ' top-label'
      }
      break;

    default:
      break;
  }
}

const processLocalizedStrings = (survey: SurveyJS.SurveyModel, options: any) => {
  if (typeof options.value === 'object') {
    options.value = options.value[survey.locale]
  }
}

enum SurveyFixedValueT {
  NONEVALUE = '98',
  OTHERVALUE = '99',
  NONETEXT = 'none',
  OTHERTEXT = 'other',
  COMMENT = '-Comment'
}

enum CxmFixedValueT {
  FIRSTCHOICEVALUE = '_first_choice_value',
  FISRTCHOICETEXT = '_first_choice_text',
  OTHER = '_other',
  SELECTEDREASONSNUMBER = '_selected_reasons_number'
}

export function Survey (props: RouteComponentProps<{ id: string }>) {
  const id: string = props?.match?.params?.id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [rawResponse, setRawResponse] = useState({});
  const [surveyConfig, setSurveyConfig] = useState<SurveyConfig>({});
  const [selectedLocale, setSelectedLocale] = useState(SupportedLanguages.es);
  const [page, setPage] = useState(0);
  const isOnline = useAppSelector(selectOnlineSlice);

  function getPrefilledFields (base: Record<string, any> = {}) {
    const search: string = props?.history?.location?.search;
    let prefilled: Record<string, any> = Object.assign(base);
    if (search.length > 0) {
      const params = search.split(/[?&]/);
      params.forEach(p => {
        if (!p.length) return;
        const [key, value] = p.split('=');
        prefilled[key] = decodeURI(value);
      })
    }

    return prefilled;
  }

  async function loadSurveyConfig (id: string) {
    setLoading(true);
    let surveyConfig: any = undefined;
    const prefilled = getPrefilledFields();
    try {
      if (String(props?.match?.url).includes('test')) {
        const testSurveyConfig = registeredSurveys.find(s => s.id === id);
        surveyConfig = testSurveyConfig?.config;
      } else {
        if(isOnline){
          const remoteSurveyConfig: SurveyConfig = await ResponseService.loadSurveyDef(id);
          surveyConfig = remoteSurveyConfig;
        } else {
          let surveys = getData(SURVEYS_KEY, true);
          if (surveys.hasOwnProperty(id)){
            if(surveys[id].hasOwnProperty('cause'))
              props?.history?.replace('/oops', {
                title: {
                  es: '¡Ooops!',
                  default: 'Ooops!'
                },
                // @ts-ignore
                message: surveys[id]['cause'],
              })
              else{
                const responses: any = getData(RESPONSES_KEY, true);
                const user: any = getData(USER_KEY, true);
                if(responses && responses[user._id]) {
                  const previousData = responses[user._id].find((e:any) => e.answerId === surveys[id].answerId)?.data
                  const data = previousData ? previousData : {};
                  surveyConfig = Object.assign(
                    surveys[id],
                    {data}
                  );
                } else
                  surveyConfig = surveys[id];
              }

          }
        }
      }
      if (surveyConfig) {
        setSelectedLocale(surveyConfig.preferredLanguage || SupportedLanguages.es);
        setSurveyConfig({ ...surveyConfig, data: { ...surveyConfig.data , ...prefilled } });
        if (surveyConfig.data){
          try {
            const pages = [...surveyConfig.surveyDef?.pages];
            pages.reverse().forEach((page: any, idx: number) => {
              const elements = [...page.elements];
              elements.reverse().forEach((element: any)=> {
                if(surveyConfig.data.hasOwnProperty(element.valueName)){
                  throw idx
                }
              })
            })
          } catch (idx: any) {
            const page = surveyConfig.surveyDef?.pages.length - (idx + 1);
            setPage(page + 1);
          }
        }
      }
    } catch (err) {
      if (err instanceof Error)
      props?.history?.replace('/oops', {
        title: {
          es: '¡Ooops!',
          default: 'Ooops!'
        },
        // @ts-ignore
        message: err.cause,
      })
    } finally {
      setLoading(false);
    }
  }

  async function saveResponse (response: any, rawResponse: any, language: SupportedLanguages) {
    if (!surveyConfig.answerId) return console.log('Response: ', response);
    try {
      return await ResponseService.saveResponse(surveyConfig.answerId, response, rawResponse, language);
    } catch (error) {
      console.log('Ocurrió un error al guardar.', error);
      if (error instanceof Error) {
        return await ResponseService.saveResponseLocal({
          answerId: surveyConfig.answerId,
          data:response,
          rawResponse,
          language
        })
      }
    }
  }

  async function onValidateQuestion (survey: any) {
    const rawResponse = survey?.valuesHash;
    const currentQuestions = survey.currentPage.questions;
    console.log('Survey', survey);
    console.log('Change', rawResponse);

    const firstChoice = currentQuestions.find((q: any) => {
      if (q.getType() === 'checkbox') return q;
      return null;
    });

    if (firstChoice) {
      if (rawResponse[firstChoice.valueName][0] !== SurveyFixedValueT.NONETEXT) {
        let idx = 0;
        if (rawResponse[firstChoice.valueName][0] === SurveyFixedValueT.OTHERTEXT) {
          idx++;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = firstChoice.localizableStrings.otherText.renderedText;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = SurveyFixedValueT.OTHERVALUE;
        }
        const choice = firstChoice.choices.find((e: any) => e.value === rawResponse[firstChoice.valueName][idx]);
        if (choice) {
          rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = choice.text;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = choice.value;
        }
      } else {
        rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = firstChoice.localizableStrings.noneText.renderedText;
        rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = SurveyFixedValueT.NONEVALUE;
      }
      const optionsSelected = rawResponse[firstChoice.valueName].map((value: string) => {
        if (value === SurveyFixedValueT.OTHERTEXT) {
          rawResponse[firstChoice.valueName + CxmFixedValueT.OTHER] = rawResponse[firstChoice.valueName + SurveyFixedValueT.COMMENT]
          delete rawResponse[firstChoice.valueName + SurveyFixedValueT.COMMENT];
          return SurveyFixedValueT.OTHERVALUE;
        }
        else if (value === SurveyFixedValueT.NONETEXT) return SurveyFixedValueT.NONEVALUE;
        return value;
      });
      rawResponse[firstChoice.valueName] = optionsSelected;
      rawResponse[firstChoice.valueName + CxmFixedValueT.SELECTEDREASONSNUMBER] = optionsSelected.length;
    }

    const updatedResponse = { ...response, ...rawResponse };
    setResponse(updatedResponse);
    setRawResponse(rawResponse);
    await saveResponse(updatedResponse, rawResponse, selectedLocale);
  }

  async function toThankyou (survey: any) {
    await onValidateQuestion(survey);
    return props?.history?.replace('/thank-you', {
      ...surveyConfig.complete,
      preferredLanguage: selectedLocale
    })
  }

  useEffect(() => {
    loadSurveyConfig(id);
  }, []);

  if (loading || !surveyConfig.surveyDef) return null;

  return (
    <>
      <PageHeader screenTitle="Evaluación" />
      <MainLayout>
        <SurveyJS.Survey
          locale={selectedLocale}
          json={surveyConfig.surveyDef}
          data={surveyConfig.data}
          css={surveyClasses}
          onUpdateQuestionCssClasses={setCssClasses}
          onProcessTextValue={processLocalizedStrings}
          onComplete={toThankyou}
          onPartialSend={onValidateQuestion}
          currentPageNo={page}
        />
      </MainLayout>
      <footer>
        <a
          href={surveyConfig.privacyPolicy?.[selectedLocale]}
          className="privacy-policy-link"
          target="_blank"
          rel="noreferrer"
        />
    </footer>
    </>
  )
}
