import { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { refreshCognitoSession } from '../services/auth.service';
import { getData } from '../services/local-storage.service';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_USER_TOKEN_KEY } from '../utils/constants';
import AuthScreen from './auth';
import EvaluationsScreen from './evaluations';
import { StartScreen, TermsScreen } from './start';
import { Survey, Message, SurveyIndex } from './survey';
import {VinculationScreen , NewSchoolScreen} from './schools';
import InformationScreen from './user/information.screen';
import { ExperienceScreen, ExperiencesListScreen } from './experience';

function App(): JSX.Element | null {
  const history = useHistory();
  const location = useLocation();

  async function tryRefresh () {
    try {
      await refreshCognitoSession();
    } catch (err) {
      console.log('No se pudo actualizar sesión.');
      history.replace('/auth/login');
    }
  }

  useEffect(() => {
    async function validateAuth () {
      const refreshToken = getData(AUTH_REFRESH_TOKEN_KEY);
      if (refreshToken) {
        try {
          await refreshCognitoSession();
          const token = getData(AUTH_USER_TOKEN_KEY);
          if (token) {
            history.replace('/start');
          } else {
            location.pathname === '/auth/confirm-password' 
            ? history.replace('/auth/confirm-password'+ location.search) 
            : location.pathname === '/auth/verify' 
              ? history.replace('/auth/verify' + location.search) 
              : history.replace('/auth/login') 
          }
        } catch (err) {
          history.replace('/auth/login');
        }
      } else {
        location.pathname === '/auth/confirm-password' 
            ? history.replace('/auth/confirm-password'+ location.search) 
            : location.pathname === '/auth/verify' 
              ? history.replace('/auth/verify' + location.search) 
              : history.replace('/auth/login')  
      }
    }

    let interval: any;
    interval = setInterval(tryRefresh, 10 * 60 * 1000); // 10 minutos
    validateAuth();
    
    return function cleanup () {
      clearInterval(interval);
    }
    
  }, [history]);
  
  return (
    <Switch>
      <Route path="/auth" component={AuthScreen} />
      <Route path="/start" component={StartScreen} />
      <Route path="/information" component={InformationScreen} />
      <Route path="/experience" component={ExperiencesListScreen} />
      <Route path="/create-experience" component={ExperienceScreen} />
      <Route path="/evaluations" component={EvaluationsScreen} />
      <Route path="/vinculation" component={VinculationScreen} />
      <Route path="/new-school" component={NewSchoolScreen} />
      <Route path="/terms" component={TermsScreen} />
      <Route path="/oops" component={Message} />
      <Route path="/thank-you" component={Message} />
      <Route path="/test-mode" component={SurveyIndex} />
      <Route path="/:id" component={Survey} />
      <Route path="/test/:id" component={Survey} />
    </Switch>
  );
}

export default App;