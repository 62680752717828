import { API_URL, AUTH_USER_TOKEN_KEY, USER_KEY } from "../utils/constants";
import { Experiences, ServerResponse } from "../utils/models";
import { logout } from "./auth.service";
import { getData } from "./local-storage.service";

async function createExperience (experience: any): Promise<boolean> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const user = getData(USER_KEY, true);
  if (!user) logout();
  const formData = new FormData()
  Object.keys(experience).forEach((key) => {
    if (key ==='projectImages'){
      experience.projectImages.forEach((file: File) => {
        formData.append('projectImages', file);
      });
    }
    else if (key ==='productionImages'){
      experience.productionImages.forEach((file: File) => {
        formData.append('productionImages', file);
      });
    }
    else formData.append(key, experience[key]);
  });
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData
  };
  const fetchResponse: Response = await fetch(`${API_URL}/experience`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function getMyExperiences (): Promise<Experiences> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${API_URL}/experience/my-experiences`, fetchOpts);
  const response: ServerResponse<Experiences> = await fetchResponse.json();
  return response.data;
}


export {
  createExperience,
  getMyExperiences
}