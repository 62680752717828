import React, { FC, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { resetPassword } from "../../../services/auth.service";
import { TextInput } from "../../../common/components/text-input";
import { validate } from "../../../utils/validation";
import { Modal } from "../../../common/components/modal";

type RecoverPassFormProps = {};

const RecoverPassForm: FC<RecoverPassFormProps> = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [errors, setErrors] = useState(null);
  const location: any = useLocation();
  const history = useHistory();

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value.trim();
    setEmail(newValue);
  }

  async function resetPass(e: React.FormEvent) {
    e.preventDefault();
    try {
      validate("email", email);
      setErrors(null);
      setLoading(true);
      const result = await resetPassword(email);
      if (result) {
        console.log(result)
        setLoading(false);
        switch (result) {
          case 1:
            setTextModal( `El usuario ${email} no se encuentra registrado en el sistema`);
            break;
          case 2:
            setTextModal(`Su usuario aun no tiene una contraseña establecida, hemos mandado un correo con una contraseña temporal a la cuenta ${email}, para que pueda acceder al sistema y establecer una nueva contraseña personalizada`);
            break;
          case 3:
            setTextModal(
              `¡Listo! Se ha enviado un correo a ${email}, con las instrucciones para recuperar tu contraseña`
            );
            break;
          case 4:
            setTextModal( `No se pudo enviar el correo de recuperación debido a que el usuario ${email} no se encuentra verificado en el sistema, por favor revisa tu correo en busca del mensaje "Verifica tu cuenta Nutrento"`);
            break;
          case 5:
            setTextModal( `Se ha producido un error al enviar tu petición, por favor verifica tu conexión a internet e intenta de nuevo`);
            break;
          default:
            setTextModal("No se pudo enviar el correo de recuperación");
            break;
        }
        setLoadingModal2(true);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("err", err);
      if (err?.message.includes('Failed to fetch')){
        const e : any ='Se ha producido un error al enviar tu petición, por favor, verifica tu conexión a internet e intenta de nuevo'
        setErrors(e);
      }
      else if (err?.message) {
        setErrors(err?.message);
      } else {
        setTextModal("No se pudo enviar el correo de recuperación");
        setLoadingModal2(true);
      }
    }
  }

  function clickModal() {
    if (
      textModal ===
      `¡Listo! Se ha enviado un correo a ${email}, con las instrucciones para recuperar tu contraseña`
    ) {
      history.replace(`/auth/confirm-password?mail=${email}`);
    } else if (
      textModal === `Su usuario aun no tiene una contraseña establecida, hemos mandado un correo con una contraseña temporal a la cuenta ${email}, para que pueda acceder al sistema y establecer una nueva contraseña personalizada`
    ) {
      history.replace(`/auth/login`);
    } else {
      setLoadingModal2(false);
    }
  }

  return (
    <>
      <Modal
        isLoading={loading}
        showLoadingAnimation={true}
        text="Procesando..."
      />
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
      <form
        style={{ width: "100%", marginBottom: "12px" }}
        noValidate
        onSubmit={resetPass}
      >
        <p className="auth-guide">{location.state?.guideMessage}</p>
        <TextInput
          label="Correo"
          fieldName="email"
          value={email}
          onChange={onValueChange}
        />
        <input
          type="submit"
          value="Recuperar contraseña"
          className="form-submit-button w-button"
        />
        <p style={{ margin: "8px 0", color: "red" }}>{errors}</p>
      </form>
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link" replace>
          Regresar a inicio de sesión
        </Link>
      </div>
    </>
  );
};

export default RecoverPassForm;
export { RecoverPassForm };
