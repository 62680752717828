import React, { FC } from 'react';

interface AuthLayoutProps {
  title: string;
  description: string;
};

const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  description,
  children
}) => {
  return (
    <div className="auth-wrapper">
      <img src="/brand/brand-logo.svg" alt="" className="auth-brand-image" />
      <div className="auth-content flex-spacer">
        <h5 className="auth-heading">{title}</h5>
        <p className="auth-description">{description}</p>
        { children }
      </div>
    </div>
  );
}

export default AuthLayout;
export {
  AuthLayout
};
