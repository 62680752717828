export enum SupportedLanguages {
  default = 'default',
  es = 'es'
}

export enum StatusAnswers {
  LEAVE = 'leave',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  STARTED = 'started',
  EXCEEDED = 'exceeded'
}

export type ServerResponse<T> = {
  message: string;
  data: T
}


export type LocalizedText = {
  [key in SupportedLanguages]: string;
};