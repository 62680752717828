import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getMySchools } from '../../services/schools.services';
import { setSchool, selectSchools, setAvailable } from '../../store/slices/schools';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { translateError } from '../../utils/handle-error';
import { PageHeader } from '../../common/components/main.layout';
import SchoolCard from './widgets/school.card';
import SchoolsEmpty from './widgets/schools-empty.card';
import { getData, saveData } from '../../services/local-storage.service';
import { RESPONSES_KEY, SCHOOLS_KEY, SURVEYS_KEY, USER_KEY } from '../../utils/constants';
import { syncResponses } from '../../services/sync.service';
import ResponseService from '../../services/response';
import { AsyncForEach } from '../../utils/functions';
import { School } from '../../utils/models';
import { selectCompleteLaterSlice, selectOnlineSlice } from '../../store/slices/online';
import { getMyUser } from '../../services/user.service';
import { AlertMessage } from '../../common/components/alert-message'

const StartScreen = () => {
  const dispatch = useAppDispatch();
  const mySchools = useAppSelector(selectSchools);
  const [loading, setLoading] = useState(true);
  const [syncPending, setSync] = useState(false);
  const isOnline = useAppSelector(selectOnlineSlice);
  const completeLater = useAppSelector(selectCompleteLaterSlice);
  const [completeRegister, setComplete] = useState(true);
  const history = useHistory();
  const mainActionStyles =
    { backgroundColor: !isOnline ? 'gray' : '#6D31AC', cursor: !isOnline ? 'auto': 'pointer' }

  function chooseSchool (_id: string) {
    dispatch(setSchool(_id));
    history.push(`/${_id}`);
  }

  function gotoEvaluations (_id: string) {
    dispatch(setSchool(_id));
    history.push('/evaluations');
  }

  function goTo(path: string){
    history.push(path);
  }

  async function sync (showAlert: boolean) {
    try {
      const response = await syncResponses();
      if (response){
        setSync(false);
        if (showAlert)
          alert('Se han sincronizado correctamente tus respuestas pendientes');
      }
    } catch (err) {
      if (err instanceof Error) {
        if(err.message === 'Network error' || 'No hay conexión a internet.'){
          alert("No tienes conexión a internet, tus respuestas se guardaron offline, cuando tengas conexión oprime el botón sincronizar respuestas pendientes en la pagina principal");
        }
      }
      else {
        const error = translateError(err);
        alert(error);
      }
    }
  }

  useEffect(() => {
    async function loadUser(){
      const user = await getMyUser();
      if (user){
        saveData(USER_KEY, user, true);
        if (user.acceptTerms) {
          if(user.information)
            setComplete(true);
          else{
            setComplete(false);
            if (!completeLater) history.push('/information')
          }
          const responses: any = getData(RESPONSES_KEY, true);
          if(responses && responses[user._id]) {
            setSync(true);
            await sync(false)
          }
        } else {
          history.push('/terms')
        }
        
      }
    }
    async function loadMySchools() {
      try {
        const schoolList = await getMySchools();
        saveData(SCHOOLS_KEY, schoolList, true);
        if (!schoolList) return;
        dispatch(setAvailable(schoolList));
        setLoading(false);
        let surveys: any = {}
        await AsyncForEach(schoolList, async (school: School) => {
          if(school._id){
            let remoteSurveyConfig;
            try {
              remoteSurveyConfig = await ResponseService.loadSurveyDef(school._id);
            } catch (error: any) {
              remoteSurveyConfig = {cause: error.cause};
            }
            surveys[school._id] = remoteSurveyConfig;
          }
        });
        saveData(SURVEYS_KEY, surveys, true);
      } catch (err) {
        if (err instanceof Error) {
          if(err.message === 'Network error' || 'No hay conexión a internet.'){
            let schools: Array<any> = getData(SCHOOLS_KEY, true);
            dispatch(setAvailable(schools));
            setLoading(false);
          }
        }
        else{
          const error = translateError(err);
          alert(error);
        }
      }
    }
    loadUser();
    loadMySchools();
  }, []);

  return (
    <>
      <PageHeader screenTitle="Nutrento" />
      {!loading && (
        <div className="screen-container">
          <AlertMessage
              title=""
              containerStyle={{ marginBottom: '1rem' }}
              webLink={{ display: 'observatorio_nutricionales@uaeh.edu.mx', route:'mailto:observatorio_nutricionales@uaeh.edu.mx?subject=Solicitud reporte de escuela'}}
              description={`
              Eres director o supervisor y te interesaría evaluar y obtener un reporte de tus escuelas, en el equipo NUTRENTO podemos ayudarte. Escríbenos
              `}
            />
          {syncPending && (
            <AlertMessage
              title=""
              containerStyle={{ marginBottom: '1rem' }}
              description={`
              Tienes evaluaciones en tu dispositivo que no logramos sincronizar por falta de conexión, 
              por favor presiona el botón Sincronizar evaluaciones para que podamos registrar tu evaluación y generar el resultado.
            `}
            />
          )}
          { (!completeRegister && completeLater) && (
            <input
              type="button"
              style={{ ...mainActionStyles, marginBottom: '0.5rem' }}
              value={'Completar perfil docente'}
              className="primary-button"
              disabled={!isOnline}
              onClick={() => goTo('/information')}
            />
          )}
          <div className="main-actions">
            <input
              type="button"
              style={{ ...mainActionStyles, marginRight: '12px'}}
              value="Vincular escuela"
              className="primary-button"
              disabled={!isOnline}
              onClick={() => goTo('/vinculation')}
            />
            <input
              type="button"
              style={mainActionStyles}
              value="Mis Experiencias"
              className="primary-button"
              disabled={!isOnline}
              onClick={() => goTo('/experience')}
            />
            {syncPending ? (
              <input
                type="button"
                value={'Sincronizar evaluaciones'}
                style={mainActionStyles}
                className="sync-button"
                disabled={!isOnline}
                onClick={() => sync(true)}
              />
            ) : null}
          </div>
          { mySchools.length === 0 && (
            <SchoolsEmpty />
          ) }
          { mySchools.length > 0 && mySchools.map(st => (
            <SchoolCard key={st._id} {...st} onClick={chooseSchool} gotoEvaluations={gotoEvaluations} isOnline={isOnline}/>
          ))}
        </div>
      )}
    </>
  );
};

export default StartScreen;
