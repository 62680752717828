import React, { FC, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { confirmPassword } from "../../../services/auth.service";
import { TextInput } from "../../../common/components/text-input";
import { validate, validateEmpty } from "../../../utils/validation";
import PasswordChecklist from "react-password-checklist";
import { Modal } from "../../../common/components/modal";

type ConfirmPassFormProps = {};

const ConfirmPassForm: FC<ConfirmPassFormProps> = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [errors, setErrors] = useState(null);
  const [hasParams, setParams] = useState(false);
  const [spaces, setSpaces] = useState(false);
  const location: any = useLocation();
  const history = useHistory();
  const search = location.search;
  const data:any = {};
  
  if (search.length > 0) {
    const params = search.split(/[?&]/);
    params.forEach((p:any) => {
      if (!p.length) return;
      const [key, value] = p.split('=');
      data[key] = decodeURI(value);
    })
  }

  useEffect(() => {
    if (data.mail) {
      setEmail(data.mail)
      setParams(true)
    }
  }, [data.mail]);

  const fn: any = {
    email: setEmail,
    code: setCode,
    password: setPassword,
  };

  function validateSpaces(value: string){
    const res = /\s/.test(value);
    if (res) setSpaces(true);
    else setSpaces(false);
  }

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const fieldName: string = e.target.name;
    let newValue: string;
    if (fieldName === 'password') {
      newValue = e.target.value;
      validateSpaces(newValue)
    }
    else newValue = e.target.value.trim();
    fn[fieldName](newValue);
  }

  async function resetPass(e: React.FormEvent) {
    e.preventDefault();
    try {
      validateEmpty(code, "Código");
      validate("email", email);
      validate("password", password);
      setErrors(null);
      setLoading(true);
      const result = await confirmPassword(email.trim(), code.trim(), password);
      if (result) {
        setLoading(false);
        setTextModal(
          "¡Listo! Has cambiado tu contraseña correctamente. Por favor, inicia sesión con tu correo y nueva contraseña."
        );
        setLoadingModal2(true);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("err", err);
      if (err?.message) {
        if (err?.message.includes("Invalid code") || err?.message.includes("Invalid verification code")) {
          const error: any = "Tu código es invalido, por favor verificalo";
          setErrors(error);
        }
        else if (err?.message.includes("Password not long enough")) {
          const error: any = "Error: La contraseña no es lo suficientemente larga debe tener al menos 8 caracteres";
          setErrors(error);
        } 
        else if (err?.message.includes("Password must have uppercase characters")) {
          const error: any = "Error: La contraseña debe tener al menos una letra mayúscula";
          setErrors(error);
        } 
        else if (err?.message.includes("Password must have lowercase characters")) {
          const error: any = "Error: La contraseña debe tener al menos una letra minúscula";
          setErrors(error);
        } 
        else if (err?.message.includes("Password must have numeric characters")) {
          const error: any = "Error: La contraseña debe tener al menos un número";
          setErrors(error);
        } 
        else if (err?.message.includes("Password must have symbol characters")) {
          const error: any = "Error: La contraseña debe tener al menos un caracter especial. Los caracteres especiales son símbolos como !, @, #, $, %, etc.";
          setErrors(error);
        } 
        else if (err?.message.includes("Attempt limit exceeded")) {
          const error: any = "Se superó el límite de intentos. Inténtelo de nuevo después de un tiempo.";
          setErrors(error);
        } 
        else if (err?.message.includes('Network error')){
          const e : any ='Se ha producido un error al enviar tu petición, por favor verifica tu conexión a internet e intenta de nuevo'
          setErrors(e);
        }
        else {
          setErrors(err?.message);
        }
      } else {
        setTextModal("Tu contraseña no pudo ser cambiada, verifica tus datos");
        setLoadingModal2(true);
      }
    }
  }

  function clickModal() {
    if (
      textModal ===
      "¡Listo! Has cambiado tu contraseña correctamente. Por favor, inicia sesión con tu correo y nueva contraseña."
    ) {
      history.push("/auth/login");
    } else {
      setLoadingModal2(false);
    }
  }

  return (
    <>
      <Modal
        isLoading={loading}
        showLoadingAnimation={true}
        text="Procesando..."
      />
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
      <form
        style={{ width: "100%", marginBottom: "12px" }}
        noValidate
        onSubmit={resetPass}
      >
        <p className="auth-guide">{location.state?.guideMessage}</p>
        {hasParams 
          ? <p className="auth-description">Se realizará el cambio de contraseña para el correo {email}</p>
          : <TextInput
              label="Email"
              fieldName="email"
              autoComplete="username"
              value={email}
              onChange={onValueChange}
            />
          }
        <TextInput
          label="Código"
          fieldName="code"
          type="number"
          value={code}
          onChange={onValueChange}
        />
        <TextInput
          label="Nueva contraseña"
          fieldName="password"
          type="password"
          value={password}
          onChange={onValueChange}
        />
        <PasswordChecklist
          className="password-check-list"
          iconSize={8}
          rules={["capital", "specialChar", "minLength", "lowercase", "number"]}
          minLength={8}
          value={password}
          messages={{
            minLength: "8 caracteres.",
            specialChar: "Al menos un caracter especial.",
            number: "Al menos un número",
            capital: "Al menos una letra mayúscula.",
            lowercase: "Al menos una letra minúscula.",
          }}
        />
        <ul className="password-check-list" style={{ padding: 0, marginBottom: "14px"}}><li className="sc-gTRrQi checklist-li">
          { spaces ?
            <svg className="sc-ewnqHT checklist-svg checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 512 512"><path fill="#FF0033" d="M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z"></path></svg>
            : <svg className="sc-ewnqHT checklist-svg checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 512 512"><path fill="#4BCA81" d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path></svg>
          }
          <span style={{opacity: spaces ? 0.5 : 1}}>No debe contener espacios en blanco.</span></li>
        </ul>
        <input
          type="submit"
          value="Actualizar contraseña"
          className="form-submit-button w-button"
        />
        <p style={{ margin: "8px 0", color: "red" }}>{errors}</p>
      </form>
    </>
  );
};

export default ConfirmPassForm;
export { ConfirmPassForm };
