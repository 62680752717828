import { getData } from '../services/local-storage.service';
import { API_URL, AUTH_USER_TOKEN_KEY } from '../utils/constants';
import { ServerResponse, Recomendations } from '../utils/models'

async function getRecomendations (scores: any): Promise<Recomendations> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({scores})
  };
  const fetchResponse: Response = await fetch(`${API_URL}/recomendation/by-scores`, fetchOpts);
  const response: ServerResponse<Recomendations> = await fetchResponse.json();
  return response.data;
}

export {
  getRecomendations,
}