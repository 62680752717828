import React, { FC } from 'react';
import { Recomendation } from '../../../utils/models';

interface RecomendationCardProps extends Recomendation {

}

const RecomendationCard:FC<RecomendationCardProps> = ({
  title,
  description,
}) => {

  return (
    <div className='container-recomendations'>
      <h5 className='title-recomendations'>{title}</h5>
      <div className='recomendations' dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
}

export default RecomendationCard;