import React from 'react';
import { MainLayout, PageHeader } from '../../common/components/main.layout';
import { registeredSurveys } from '../../surveys';

export function SurveyIndex () {
  return (
    <>
      <PageHeader />
      <MainLayout>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <h2>Listado de encuestas</h2>
          <p style={{ textAlign: 'center' }}><small>Solo para fines demostrativos, esta sección no es visible durante el levantamiento.</small></p>
          {
            registeredSurveys.map(s => {
              let params = ''
              if (s.params?.length) {
                s.params.forEach((p, i) => {
                  const separator = i === 0 ? '?' : '&';
                  params += `${separator}${p.key}=${encodeURI(p.value)}`
                })
              }
              return (
                <a
                  key={s.id}
                  href={`/test/${s.id}${params}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '16px', marginBottom: '1em', textAlign: 'center' }}
                >
                  {s.label}
                </a>
              )
            })
          }
        </div>
      </MainLayout>
    </>
  )
}