import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { deleteUser } from '../../../services/user.service';
import { TextInput } from '../../../common/components/text-input';
import { validate } from '../../../utils/validation';
import { Modal } from '../../../common/components/modal';
type UnsusbcribeProps = {

};

const UnsusbscribeForm: FC<UnsusbcribeProps> = (

) => {

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const history = useHistory();

  const fn: any = {
    email: setEmail,
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value.trim();
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      validate('email', email);
      setErrors(null);
      const deleteResult = await deleteUser(email);
      console.log(deleteResult);
      if (deleteResult.errorCode) {
        setErrors(deleteResult.message.es);
        setLoading(false);
      }
      if (deleteResult.message) {
        setLoading(false);
        setTextModal('¡Listo! Tu cuenta se ha eliminado exitosamente de Nutrento.')
        setLoadingModal2(true)
      }
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      if (err?.message.includes('Failed to fetch')){
        const e : any ='Se ha producido un error al enviar tu petición, por favor verifica tu conexión a internet e intenta de nuevo'
        setErrors(e);
      }
      else {
        setErrors(err?.message);
      }
      setLoading(false);
    }
  }

  function clickModal () {
    history.replace("/auth/login");
  }

  return (
    <>
     <Modal 
        isLoading={loadingModal2} 
        showLoadingAnimation={false} 
        text={textModal} 
        onClick={() => clickModal()}
      />
      <form
        style={{ width: '100%' }}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Email"
          fieldName="email"
          autoComplete="username"
          value={email}
          onChange={onValueChange}
        />
        <input
          disabled={loading}
          type="submit"
          value={loading ? 'Procesando...' : 'Eliminar cuenta'}
          className="form-submit-button w-button"
        />
        <p style={{margin: '8px 0', color: 'red'}}>
          {errors}
        </p>
      </form>
      <div className="flex-spacer show-md" />
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link" replace>
          Regresar a inicio de sesión
        </Link>
      </div>
    </>
  );
}

export default UnsusbscribeForm;
export {
  UnsusbscribeForm,
}
