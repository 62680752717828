import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface OnlineState {
  online: boolean;
  completeLater: boolean;
}

// Define the initial state using that type
const initialState: OnlineState = {
  online: true,
  completeLater: false,
};

export const onlineSlice = createSlice({
  name: 'online',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOnline: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload;
    },
    setCompleteLater: (state, action: PayloadAction<boolean>) => {
      state.completeLater = action.payload;
    }
  },
})

export const { setOnline, setCompleteLater } = onlineSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectOnlineSlice = (state: RootState) => state.online.online;
export const selectCompleteLaterSlice = (state: RootState) => state.online.completeLater;

export default onlineSlice.reducer;