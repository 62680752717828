import { API_URL, AUTH_USER_TOKEN_KEY, RESPONSES_KEY, USER_KEY } from '../utils/constants';
import { logout } from './auth.service';
import { getData, saveData } from './local-storage.service';

interface SyncDTO {
  answers: Array<any>
}

export interface SyncResponse {
  errorCode?: string;
  message: {
    es: string;
  };
}

async function syncResponses (): Promise<boolean | void> {
  const token = getData(AUTH_USER_TOKEN_KEY);
  const answers = getData(RESPONSES_KEY, true);
  const user = getData(USER_KEY, true);
  if (!answers) return;
  if (!user) logout();
  if(!answers[user._id]) return 
  else{
    const body: SyncDTO = { answers: answers[user._id] };
    const fetchOpts: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    };
    let URL = `${API_URL}/answer/sync-answers`;

    const fetchResponse: Response = await fetch(URL, fetchOpts);
    const response: SyncResponse = await fetchResponse.json();
    if (response.errorCode) throw new Error(response.message.es);
    else {
      delete answers[user._id];
      saveData(RESPONSES_KEY, answers, true);
    }
    return true;
  }
}

export {
  syncResponses,
}