import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../common/components/main.layout';
import { TextInput, TextAreaInput } from '../../common/components/text-input';
import { validateEmpty } from '../../utils/validation';
import {
  degrees,
  genders,
  maritalStatusCatalog,
  p1_nss_answers,
  p2_nss_answers,
  p3_nss_answers,
  p4_nss_answers,
  p5_nss_answers,
  p6_nss_answers,
  educationalLevels,
  lastDegree,
  shedulesUser,
  contracts,
  grades
} from './catalogs';
import Select from 'react-dropdown-select';
import { countries, scopes } from '../schools/catalogs';
import { Information } from '../../utils/models';
import { updateUser } from '../../services/user.service';
import { setCompleteLater } from '../../store/slices/online';
import { useAppDispatch } from '../../store/hooks';
import { AlertMessage } from '../../common/components/alert-message'
import { Modal } from '../../common/components/modal';

const InformationScreen = () => {
  const init: any[] = [];
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [age, setAge] = useState('');
  const [gender, setGender] = useState(init);
  const [country, setCountry] = useState(init);
  const [region, setRegion] = useState(init);
  const [regions, setRegions] = useState(init);
  const [scope, setScope] = useState(init);
  const [maritalstatus, setMaritalstatus] = useState(init);
  const [p1_nse, setP1_nse] = useState(init);
  const [p2_nse, setP2_nse] = useState(init);
  const [p3_nse, setP3_nse] = useState(init);
  const [p4_nse, setP4_nse] = useState(init);
  const [p5_nse, setP5_nse] = useState(init);
  const [p6_nse, setP6_nse] = useState(init);
  const [degree, setDegree] = useState(init);
  const [otherDegree, setOtherDegree] = useState('');
  const [showOther, setOther] = useState(false);
  const [educationalLevel, setEducationalLevel] = useState(init);
  const [courses, setCourses] = useState(0);
  const [showNames, setNames] = useState(false);
  const [coursesNames, setCoursesNames] = useState('');
  const [lastDegreeComplete, setLastDegreeComplete] = useState(init);
  const [teachingYears, setTeachingYears] = useState('');
  const [contractType, setContractType] = useState(init);
  const [shedule, setShedule] = useState(init);
  const [showMorning, setMorning] = useState(false);
  const [showMorningGrades, setShowMorningGrades] = useState(false);
  const [showEvening, setEvening] = useState(false);
  const [showEveningGrades, setShowEveningGrades] = useState(false);
  const [morningGrade, setMorningGrade] = useState(init);
  const [eveningGrade, setEveningGrade] = useState(init);
  const [morningGrades, setMorningGrades] = useState('');
  const [eveningGrades, setEveningGrades] = useState('');
  const [studentsNumber, setStudentsNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [errors, setErrors] = useState(null);

  const fn: any = {
    age: setAge,
    gender: setGender,
    country: setCountry,
    region: setRegion,
    scope: setScope,
    maritalstatus: setMaritalstatus,
    p1_nse: setP1_nse,
    p2_nse: setP2_nse,
    p3_nse: setP3_nse,
    p4_nse: setP4_nse,
    p5_nse: setP5_nse,
    p6_nse: setP6_nse,
    degree: setDegree,
    otherDegree: setOtherDegree,
    educationalLevel: setEducationalLevel,
    courses: setCourses,
    coursesNames: setCoursesNames,
    lastDegreeComplete: setLastDegreeComplete,
    teachingYears: setTeachingYears,
    contractType: setContractType,
    shedule: setShedule,
    morning: setMorningGrade,
    morningGrades: setMorningGrades,
    evening: setEveningGrade,
    eveningGrades: setEveningGrades,
    studentsNumber: setStudentsNumber
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    if(fieldName === 'courses'){
      if(parseInt(newValue) > 0) setNames(true)
      else {
        setNames(false)
        setCoursesNames('')
      }
    }
    fn[fieldName](newValue);
  }

  function onTextAreaChange (e: React.ChangeEvent<HTMLTextAreaElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function onDropdownChange(fieldName:string, newValue:any){
    if (fieldName === 'country'){
        setRegion([]);
        if(newValue.length > 0)
          setRegions(newValue[0].regions);
        else
          setRegions([]);
    }
    if(fieldName === 'degree'){
      if (newValue[0].distinctId === 'otra') setOther(true);
      else {
        setOther(false);
        setOtherDegree('');
      }
    }
    if(fieldName === 'shedule'){
      switch (newValue[0].distinctId) {
        case 'matutino':
          setMorning(true);
          setEvening(false);
          break;
        case 'vespertino':
          setEvening(true);
          setMorning(false);
          break;
        case 'ambos':
          setMorning(true);
          setEvening(true);
          break;
        default:
          setMorning(false);
          setEvening(false);
          break;
      }
    }
    if(fieldName === 'morning'){
      if(newValue[0].distinctId === '98')
        setShowMorningGrades(true);
      else 
        setShowMorningGrades(false);
    }
    if(fieldName === 'evening'){
      if(newValue[0].distinctId === '98')
        setShowEveningGrades(true);
      else 
        setShowEveningGrades(false);
    }
    fn[fieldName](newValue);
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      const genderValue = gender[0]?.distinctId;
      const countryValue = country[0]?.distinctId;
      const regionValue = region[0]?.distinctId;
      const scopeValue = scope[0]?.distinctId;
      const maritalstatusValue = maritalstatus[0]?.distinctId;
      const p1_nseValue = p1_nse[0]?.distinctId;
      const p2_nseValue = p2_nse[0]?.distinctId;
      const p3_nseValue = p3_nse[0]?.distinctId;
      const p4_nseValue = p4_nse[0]?.distinctId;
      const p5_nseValue = p5_nse[0]?.distinctId;
      const p6_nseValue = p6_nse[0]?.distinctId;
      const degreeValue = degree[0]?.distinctId;
      const educationalLevelValue = educationalLevel[0]?.distinctId;
      const lastDegreeCompleteValue = lastDegreeComplete[0]?.distinctId;
      const contractTypeValue = contractType[0]?.distinctId;
      const sheduleValue = shedule[0]?.distinctId;
      const morningGradeValue = morningGrade[0]?.distinctId;
      const eveningGradeValue = eveningGrade[0]?.distinctId;
      setLoading(true);
      setErrors(null);
      validateEmpty(age);
      validateEmpty(genderValue);
      validateEmpty(countryValue);
      validateEmpty(regionValue);
      validateEmpty(scopeValue);
      validateEmpty(maritalstatusValue);
      validateEmpty(p1_nseValue);
      validateEmpty(p2_nseValue);
      validateEmpty(p3_nseValue);
      validateEmpty(p4_nseValue);
      validateEmpty(p5_nseValue);
      validateEmpty(p6_nseValue);
      validateEmpty(degreeValue);
      if(showOther) validateEmpty(otherDegree);
      validateEmpty(educationalLevelValue);
      if(showNames) validateEmpty(coursesNames);
      validateEmpty(lastDegreeCompleteValue);
      validateEmpty(teachingYears);
      validateEmpty(contractTypeValue);
      validateEmpty(sheduleValue);
      if(showMorning) validateEmpty(morningGradeValue);
      if (morningGradeValue === '98') validateEmpty(morningGrades);
      if(showEvening) validateEmpty(eveningGradeValue);
      if (eveningGradeValue === '98') validateEmpty(eveningGrades);
      validateEmpty(studentsNumber);

      const information:Information = {
        age: parseInt(age),
        gender: genderValue,
        country: countryValue,
        region: regionValue,
        scope: scopeValue,
        maritalstatus: maritalstatusValue,
        p1Score: p1_nseValue,
        p1Display: p1_nse[0].display.es,
        p2Score: p2_nseValue,
        p2Display: p2_nse[0].display.es,
        p3Score: p3_nseValue,
        p3Display: p3_nse[0].display.es,
        p4Score: p4_nseValue,
        p4Display: p4_nse[0].display.es,
        p5Score: p5_nseValue,
        p5Display: p5_nse[0].display.es,
        p6Score: p6_nseValue,
        p6Display: p6_nse[0].display.es,
        nse: p1_nseValue+p2_nseValue+p3_nseValue+p4_nseValue+p5_nseValue+p6_nseValue,
        degree: degreeValue,
        educationalLevel: educationalLevelValue,
        courses,
        lastDegreeComplete: lastDegreeCompleteValue === 'true' ? true : false,
        teachingYears: parseInt(teachingYears),
        contractType: contractTypeValue,
        shedule: sheduleValue,
        studentsNumber: parseInt(studentsNumber)
      }
      if(showOther) information['otherDegree'] = otherDegree;
      if(showNames) information['coursesNames'] = coursesNames;
      if(showMorning) information['morningGrade'] = morningGradeValue;
      if (morningGradeValue === '98') information['morningGrades'] = morningGrades;
      if(showEvening) information['eveningGrade'] = eveningGradeValue;
      if (eveningGradeValue === '98') information['eveningGrades'] = eveningGrades;
      const res = await updateUser(information)
      if (res){
        setTextModal('Gracias por completar tu registro');
      } else{
        setTextModal('No se pudo completar tu registro, revisa tu conexión')
      }
      setLoadingModal2(true)
      setLoading(false);
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      setErrors(err?.message);
      setLoading(false);
    }
  }

  function completeLater () {
    dispatch(setCompleteLater(true));
    history.push('/start');
  }

  function clickModal () {
    if (textModal === 'No se pudo completar tu registro, revisa tu conexión') {
      setLoadingModal2(false)
    } else{
      history.push("/start");
    }
  }

  return (
    <>
      <PageHeader screenTitle="Perfil docente" />
      <Modal isLoading={loading} showLoadingAnimation={true} text='Procesando...'/>
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
      <div className="screen-container">
        <AlertMessage
          title="Completa tu perfil"
          description="Solicitamos tu ayuda para llenar la siguiente información, que nos ayudará a mejorar el análisis de tus evaluaciones."
        />
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextInput
            label="Edad en años"
            fieldName="age"
            value={age}
            type='number'
            onChange={onValueChange}
          />
          <label className="input-label">Sexo de nacimiento</label>
          <Select
            className='select-dropdown'
            options={genders}
            values={gender}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('gender', value)}
          />
          <label className="input-label">Pais</label>
          <Select
            className='select-dropdown'
            options={countries}
            values={country}
            valueField='distinctId'
            labelField='name'
            searchBy='name'
            placeholder=''
            onChange={(value) => onDropdownChange('country', value)}
          />
          <label className="input-label">Estado o región en la que radica</label>
          <Select
            className='select-dropdown'
            options={regions}
            values={region}
            valueField='distinctId'
            labelField='name'
            searchBy='name'
            placeholder=''
            disabled= {regions.length > 0 ? false : true}
            onChange={(value) => onDropdownChange('region', value)}
          />
          <label className="input-label">Ámbito en el que radica</label>
          <Select
            className='select-dropdown'
            options={scopes}
            values={scope}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('scope', value)}
          />
          <label className="input-label">Estado civil</label>
          <Select
            className='select-dropdown'
            options={maritalStatusCatalog}
            values={maritalstatus}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('maritalstatus', value)}
          />
          <label className="input-label">Pensando en el jefe o jefa de hogar, ¿cuál fue el último año de estudios que aprobó en la escuela?</label>
          <Select
            className='select-dropdown'
            options={p1_nss_answers}
            values={p1_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p1_nse', value)}
          />
          <label className="input-label">¿Cuántos baños completos con regadera y W.C. (excusado) hay en su vivienda</label>
          <Select
            className='select-dropdown'
            options={p2_nss_answers}
            values={p2_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p2_nse', value)}
          />
          <label className="input-label">¿Cuántos automóviles o camionetas tienen en su hogar, incluyendo camionetas cerradas, o con cabina o caja?</label>
          <Select
            className='select-dropdown'
            options={p3_nss_answers}
            values={p3_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p3_nse', value)}
          />
          <label className="input-label">Sin tomar en cuenta la conexión móvil que pudiera tener desde algún celular ¿Este hogar cuenta con internet?</label>
          <Select
            className='select-dropdown'
            options={p4_nss_answers}
            values={p4_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p4_nse', value)}
          />
          <label className="input-label">De todas las personas de más de 14 años que viven en el hogar, ¿cuántas trabajaron en el último mes?</label>
          <Select
            className='select-dropdown'
            options={p5_nss_answers}
            values={p5_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p5_nse', value)}
          />
          <label className="input-label">En su vivienda, ¿cuántos cuartos se usan para dormir, sin contar pasillos ni baños?</label>
          <Select
            className='select-dropdown'
            options={p6_nss_answers}
            values={p6_nse}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('p6_nse', value)}
          />
          <label className="input-label">Título de egreso con el cual ejerce su servicio docente</label>
          <Select
            className='select-dropdown'
            options={degrees}
            values={degree}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('degree', value)}
          />
          { showOther &&
            <TextInput
              label="Otra (mencione cuál)"
              fieldName="otherDegree"
              type="text"
              value={otherDegree}
              onChange={onValueChange}
            />
          }
          <label className="input-label">Nivel de estudios terminado</label>
          <Select
            className='select-dropdown'
            options={educationalLevels}
            values={educationalLevel}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('educationalLevel', value)}
          />
          <TextInput
            label="Número de cursos de alimentación, nutrición y estilos de vida saludable terminados"
            fieldName="courses"
            value={courses}
            type='number'
            min='0'
            onChange={onValueChange}
          />
          {showNames &&
            <TextAreaInput
              label="Especifique cuáles"
              fieldName="coursesNames"
              value={coursesNames}
              onChange={onTextAreaChange}
            />
          }
          <label className="input-label">Se encuentra titulado de su último grado de estudio</label>
          <Select
            className='select-dropdown'
            options={lastDegree}
            values={lastDegreeComplete}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('lastDegreeComplete', value)}
          />
          <TextInput
            label="Años de ejercicio docente frente a grupo"
            fieldName="teachingYears"
            value={teachingYears}
            type='number'
            min='0'
            onChange={onValueChange}
          />
          <label className="input-label">Tipo de contratación del docente</label>
          <Select
            className='select-dropdown'
            options={contracts}
            values={contractType}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('contractType', value)}
          />
          <label className="input-label">Turno en el que imparte</label>
          <Select
            className='select-dropdown'
            options={shedulesUser}
            values={shedule}
            valueField='distinctId'
            labelField='display.es'
            searchBy='display.es'
            placeholder=''
            onChange={(value) => onDropdownChange('shedule', value)}
          />
           {showMorning &&  <>
              <label className="input-label">Grado que imparte (turno matutino)</label>
              <Select
                className='select-dropdown'
                options={grades}
                values={morningGrade}
                valueField='distinctId'
                labelField='display.es'
                searchBy='display.es'
                placeholder=''
                onChange={(value) => onDropdownChange('morning', value)}
              />
            </>
          }
          { showMorningGrades &&
            <TextInput
              label="Especifique los grados en el que imparte (turno matutino): 1, 2, 3, 4, 5, 6"
              fieldName="morningGrades"
              type="text"
              value={morningGrades}
              onChange={onValueChange}
            />
          }
           {showEvening && <>
              <label className="input-label">Grado que imparte (turno vespertino)</label>
              <Select
                className='select-dropdown'
                options={grades}
                values={eveningGrade}
                valueField='distinctId'
                labelField='display.es'
                searchBy='display.es'
                placeholder=''
                onChange={(value) => onDropdownChange('evening', value)}
              />
              </>
          }
          { showEveningGrades &&
            <TextInput
              label="Especifique los grados en el que imparte (turno vespertino): 1, 2, 3, 4, 5, 6"
              fieldName="eveningGrades"
              type="text"
              value={eveningGrades}
              onChange={onValueChange}
            />
          }
          <TextInput
            label="Número de alumnos que atiende en su grupo"
            fieldName="studentsNumber"
            value={studentsNumber}
            type='number'
            min='0'
            onChange={onValueChange}
          />

          <input
            disabled={loading}
            type="submit"
            value={loading ? 'Procesando...' : 'Completar perfil'}
            className="form-submit-button w-button"
          />
          <p style={{margin: '8px 0', color: 'red'}}>
            {errors}
          </p>
        </form>
        <input
            type="button"
            value={'Continuar sin completar perfil'}
            className="form-submit-button w-button"
            onClick={() => completeLater()}
          />
        <br />
      </div>
    </>
  );
};

export default InformationScreen;
