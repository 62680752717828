import { FC, Key } from 'react';
import {Route, RouteProps, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import { AuthLayout } from '../../common/components/auth.layout';
import { Help, LoginForm, NewPassForm, RecoverPassForm, RegisterForm } from './widgets';
import UnsusbscribeForm from './widgets/unsubscribe';
import ConfirmPassForm from './widgets/confirm-password.form';
import VerifyForm from './widgets/verify.form';

type AuthScreenProps = {

};

type RouteConfig = RouteProps & {
  title?: string
  description?: string
}

const authRoutes: RouteConfig[] = [
  {
    path: '/login',
    component: LoginForm,
    title: 'Bienvenido a Nutrento',
    description: 'Inicia sesión con tu cuenta Nutrento para evaluar ambientes alimentarios y conocer los resultados de tus ambientes evaluados.'
  },
  {
    path: '/recover-password',
    component: RecoverPassForm,
    title: 'Recupera tu contraseña',
    description: 'Ingresa tu correo electrónico registrado en Nutrento y te enviaremos las instrucciones para crear una nueva contraseña.'
  },
  {
    path: '/confirm-password',
    component: ConfirmPassForm,
    title: 'Crea tu nueva contraseña',
    description: 'Ingresa el código de verificación que te mandamos por correo y tu email para poder establecer una nueva contraseña, asegúrate de cumplir con los aspectos de una contraseña segura.'
  },
  {
    path: '/verify',
    component: VerifyForm,
    title: 'Verifica tu cuenta',
    description: 'Ingresa el código de verificación que te mandamos por correo y tu email para poder verificar tu cuenta e ingresar a Nutrento'
  },
  {
    path: '/new-password',
    component: NewPassForm,
    title: 'Ingresa tu nueva contraseña',
    description: 'Escribe tu nueva contraseña, asegúrate de cumplir con los aspectos de una contraseña segura.'
  },
  {
    path: '/register',
    component: RegisterForm,
    title: 'Regístrate en Nutrento',
    description:
      'Al crear tu cuenta Nutrento, te unes a la red de docentes preocupados por mejorar los ambientes alimentarios de Latinoamérica. Tu registro es necesario para asegurar la calidad de los datos recabados.'
  },
  {
    path: '/unsubscribe',
    component: UnsusbscribeForm,
    title: 'Eliminar cuenta de Nutrento',
    description: 'Al eliminar tu cuenta de nutrento ya no podras ingresar a la aplicación, para poder volver a acceder tendrás que registrarte nuevamente'
  },
  {
    path: '/help',
    component: Help,
    title: 'Ayuda',
    description: 'Te compartimos los siguientes recursos para ayudarte a resolver tus dudas acerca de App Nutrento'
  },
]

const AuthScreen: FC<AuthScreenProps> = (

) => {
  let { path: basePath } = useRouteMatch()
  let location = useLocation()
  const currentRouteConfig = authRoutes.find(r => `${basePath}${r.path}` === location.pathname)
  const pageTitle = currentRouteConfig?.title || 'Bienvenido a Nutrento'
  const pageDescription = currentRouteConfig?.description || ''

  return (
    <AuthLayout
      title={ pageTitle }
      description={ pageDescription }
    >
      <Switch>
        {authRoutes.map(routeConfig => (
          <Route key={routeConfig.path as Key} {...routeConfig} path={`${basePath}${routeConfig.path}`} />
        ))}
      </Switch>
    </AuthLayout>
  );
}

export default AuthScreen;
export {
  AuthScreen,
};
