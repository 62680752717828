const API_PREFIX = 'api';
const LOCAL_STORAGE_PREFIX = 'nut_';
const AUTH_USERNAME_KEY = `${LOCAL_STORAGE_PREFIX}auth_username`;
const AUTH_USER_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_user_token`;
const AUTH_REFRESH_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_refresh_token`;
const RESPONSES_KEY = `${LOCAL_STORAGE_PREFIX}responses`;
const SCHOOLS_KEY = `${LOCAL_STORAGE_PREFIX}schools`;
const SURVEYS_KEY = `${LOCAL_STORAGE_PREFIX}surveys`;
const USER_KEY = `${LOCAL_STORAGE_PREFIX}user`;
const API_URL = `https://api.nutrento.org`;
//const API_URL = `http://localhost:3000`;
const SERVER_URL = `https://nutrento.org`;
//const SERVER_URL = `http://localhost:3001`;
const SURVEYID = '645a860e7ea4d4314690bce4';

export {
  API_URL,
  SERVER_URL,
  API_PREFIX,
  AUTH_USERNAME_KEY,
  AUTH_USER_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  RESPONSES_KEY,
  SCHOOLS_KEY,
  SURVEYS_KEY,
  USER_KEY,
  SURVEYID
}
